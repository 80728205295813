import {
  Header,
  NavLink,
  NavMenu,
  NavBtnLink,
  LogoForDesktop,
  LogoForMobile,
  Container,
  Nav,
  CloseMenu,
  MenuIcon,
  ExternalLink,
} from "./NavbarElements";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";

import IcClose from "../../images/ic-close.png";
import IcMenu from "../../images/ic-menu.svg";

import BrandLogo from "assets/brand-black.svg";
import BrandLogoWhite from "assets/brand-white.svg";
import Socials from "components/Socials";


const Navbar = () => {
  const [showMenu, toggleMenu] = useToggle(false);

  return (
    <Header>
      <Container>
        <span>
          <Link to="/">
            <LogoForDesktop src={BrandLogo} alt="logo" />
            <LogoForMobile src={BrandLogoWhite} alt="logo" />
          </Link>
        </span>
        <Nav>
          {showMenu ? (
            <CloseMenu onClick={toggleMenu}>
              <img src={IcClose} alt="Close" />
            </CloseMenu>
          ) : (
            <MenuIcon src={IcMenu} onClick={toggleMenu} alt="Menu" />
          )}
          <NavMenu show={showMenu}>
            <NavLink to="/docs">Docs</NavLink>
            <ExternalLink
              rel="noreferrer"
              target="_blank"
              href="https://medium.com/@bunker_finance"
            >
              Blog
            </ExternalLink>
            <NavLink
              to="/careers"
              style={{ marginRight: 80 }}
            >
              Careers
            </NavLink>

            <Socials />

            <NavBtnLink to="/dashboard"> Launch App </NavBtnLink>
          </NavMenu>
        </Nav>
      </Container>
    </Header>
  );
};

export default Navbar;
