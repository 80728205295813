import SectionTitleWrapper from "components/SectionTitleWrapper";

import { useState } from "react";
import GridTableController from "components/GridTableController";
import { GridView } from "./GridView";
import { TableView } from "./TableView";

export default function Collateral({ myassetsData }) {
  const [view, setView] = useState("grid");

  const Component = view === "grid" ? GridView : TableView;

  return (
    <div className="row">
      <div className="col-lg-12">
        <SectionTitleWrapper>
          <h4>Collateral</h4>
          <div>
            <GridTableController view={view} setView={setView} />
          </div>
        </SectionTitleWrapper>

        <Component myassetsData={myassetsData} />
      </div>
    </div>
  );
}
