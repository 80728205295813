import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useCopy } from "./index";

function Topic() {
  const { contentBySectionAndTopic: content } = useCopy();

  const params = useParams();
  const topic = content[params.sectionSlug][params.topicSlug];

  if (!topic) return <>"Not Found"</>;

  return (
    <>
      <Title>{topic?.title}</Title>
      <Content>{topic?.content || "Not Found"}</Content>
    </>
  );
}

const Title = styled.h1`
  font-family: "Encode Sans Semi Expanded";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #18181b;
  margin-bottom: 8px;
`;

const Content = styled.div`
  font-family: "Encode Sans Semi Expanded";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export default Topic;
