import styled from "styled-components";
import { Link } from "react-router-dom";
import { useCopy } from "../index";

function Topics() {
  const { rawData: data, baseRoute } = useCopy();

  return (
    <SectionList>
      {data.map((section) => (
        <li key={section.slug}>
          <h2>{section.title}</h2>
          <ul>
            {section.topics.map((topic) => (
              <li key={topic.slug}>
                <Link to={`/${baseRoute}/${section.slug}/${topic.slug}`}>
                  {topic.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </SectionList>
  );
}

const SectionList = styled.ul`
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  padding: 0;
  row-gap: 48px;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #18181b;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #18181b;
  }
`;

export default Topics;
