import { Link } from "react-router-dom";
import styled from "styled-components";

export const DashboardNavMobile = () => {
  return (
    <nav>
      <LinksList>
        <li>
          <h4>
            <Link to="/dashboard" className="activetab">
              Dashboard
            </Link>
          </h4>
        </li>
        <li>
          <h4>
            <Link to="/inventory">Inventory</Link>
          </h4>
        </li>
      </LinksList>
    </nav>
  );
};

const LinksList = styled.ul`
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 930.9px) {
    display: flex;
  }

  li {
    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: var(--color-secondary-text);
      text-decoration: none;
    }
    & .activetab {
      color: var(--color-text);
      position: relative;
      &::after {
        content: "";
        width: 18px;
        height: 2px;
        background: var(--color-primary);
        position: absolute;
        bottom: 0;
        top: 49px;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
      }
    }
  }
`;
