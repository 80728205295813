import _ from "lodash";

import EitImg from "assets/ic-eth.svg";

import { NftListThree } from "components/NftList";

import NftListItem from "components/NftListItem";
import { EmptyAssetsComponent } from "components/EmptyAssetsComponent";

export const GridView = ({ myassetsData }) => {

  if(myassetsData?.length === 0 ){
    return <EmptyAssetsComponent />
  }

  return (
    <NftListThree>
      {_.map(myassetsData, ({ id, name, url, value, deposited, approved }) => (
        <NftListItem
          imgSrc={url}
          title={name}
          id={id}
          value={
            <div className="flex-center">
              <div className="flex-center-inner">
                <h3>
                  <img src={EitImg} alt="ETH sign" /> {value}
                </h3>
              </div>
            </div>
          }
          deposited={deposited}
          approved={approved}
        />
      ))}
    </NftListThree>
  );
};
