import { StyledAppBar, NavLink, Layout } from "..";
import Logo from "assets/brand-black.svg";
import { useForceLight } from "utils/useDarkMode";

import Container from "../components/Container";
import { Title } from "../LandingContent";
import Accordion from "views/LandingPage/components/Accordion/Accordion";
import Faq from "views/LandingPage/components/Content/components/Faq";
import styled from "styled-components";

export default function FAQs() {
  useForceLight();
  return (
    <Layout>
      <StyledAppBar color="transparent" elevation={0} position="static">
        <NavLink to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
      </StyledAppBar>

      <Container>
        <Title>Frequent Asked Questions (FAQ)</Title>

        <CustomFaq as="div">
          <div className="accordian-wrap">
            <Accordion />
          </div>
        </CustomFaq>
      </Container>
    </Layout>
  );
}

const CustomFaq = styled(Faq)`
  padding-top: 40px;

  background-color: transparent !important;
  background: transparent !important;

  div,
  .accordian-wrap,
  .accordian-wrap div {
    background-color: transparent !important;
    background: transparent !important;
  }
`;
