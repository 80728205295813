import EitImg from "assets/ic-eth.svg";
import { useToggle } from "react-use";
import UnwrapCollateralModal from "components/modal/UnwrapCollateralModal";
import { title } from "process";

interface Props {
  id: string;
  name: string;
  url: string;
  value: string;
  valueUsdc: string;
  deposited: boolean;
  approved: boolean | undefined;
  deposit: (id: string) => void;
  requestApproval: (id: string) => void;
  redeem: (id: string) => void;
}

export default function AssetListItem({
  id,
  name,
  url,
  value,
  valueUsdc,
  deposited,
  approved,
  deposit,
  requestApproval,
  redeem,
}: Props) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <li key={id}>
      <div className="imgbox">
        <img src={url} />
      </div>
      <div className="imgtxt">
        <h6>{name}</h6>
        <h1>#{id}</h1>
        <div className="hide-hover">
          <h5>Value</h5>
          <div className="flex-center">
            <div className="flex-center-inner">
              <h3>
                <img src={EitImg} /> {value}
              </h3>
            </div>
          </div>
        </div>

        {!deposited && !approved && (
          <button
            className="btn btn-unwrap"
            onClick={async () => {
              requestApproval(id);
            }}
          >
            {!approved ? "Approve" : "Authorized"}
          </button>
        )}
        {!deposited && (
          <button
            className="btn btn-unwrap"
            disabled={!approved}
            onClick={async () => {
              deposit(id);
            }}
          >
            Deposit
          </button>
        )}
        {deposited && (
          <>
            <button
              className="btn btn-unwrap"
              onClick={async () => {
                toggle();
                // redeem(id); // THIS REDEEM MUST GO INSIDE THE MODAL
              }}
            >
              Unwrap
            </button>
            <UnwrapCollateralModal isOpen={isOpen} handleClose={toggle} nftData={{
              name: name,
              id: id,
              deposited: deposited,
              approved:approved,
            }}/>
          </>
        )}
      </div>
    </li>
  );
}
