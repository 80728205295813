import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 80px;
  padding: 0 100px;
  box-sizing: border-box;
  background: var(--color-app-bar-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 99;
  top: 0;
  color: var(--color-text);

  @media (max-width: 767px) {
    padding: 0 8px;
  }

  .left-section img {
    width: 115px;
    @media (max-width: 350px) {
      max-width: 105px;
    }
  }

  .right-section {
    text-align: right;
  }
  .filled-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-white);
    border: 0px solid var(--color-primary);
    background-color: var(--color-primary);
    padding: 0px 20px;
    white-space: nowrap;
    border-radius: 6px;
    min-height: 40px;
  }
  .connect-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text);
    border: 1px solid var(--color-solitude);
    background-color: var(--color-background);
    padding: 0px 20px;
    white-space: nowrap;
    border-radius: 6px;
    min-height: 40px;
    .metamaskimg {
      width: 20px;
      margin-left: 10px;
    }

    @media (max-width: 400px) {
      padding: 0 5px;
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .menulist {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      a {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: var(--color-secondary-text);
        padding: 0 50px;
        text-decoration: none;
      }
      & .activetab {
        color: var(--color-text);
        position: relative;
        &::after {
          content: "";
          width: 18px;
          height: 2px;
          background: var(--color-primary);
          position: absolute;
          bottom: 0;
          top: 49px;
          left: 50%;
          right: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @media (max-width: 930px) {
      display: none;
    }
  }
`;

export const GreenLabel = styled.h3`
  margin: 0 0 16px 0;

  font-weight: 700;
  font-size: 13px;
  color: #14b8a6;
  line-height: 40px;
`;
