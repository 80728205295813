import { formatEther, formatUnits } from '@ethersproject/units';
import { useBunkerState } from 'contexts/BunkerStateContextProvider';
import { useWeb3Context } from 'contexts/Web3ContextProvider';
import { BigNumber } from 'ethers';
import { BorrowDataRow } from 'interfaces/interfaces';
import React, { useCallback, useMemo, useState } from 'react'
import { max, min } from 'utils/commons';
import { MaxUint256, NegativeOne } from "@ethersproject/constants";

export const useBorrowCTokenData = () => {
    const bunkerState = useBunkerState();
    const { provider, contracts } = useWeb3Context();

    const borrowData: BorrowDataRow[] = useMemo(() => {
        if (!bunkerState.value) return [];
        const { cNftValue, liquidity, collateralFactor } = bunkerState.value;
        const liquidationThreshold = cNftValue
          .mul(collateralFactor)
          .div(1e9)
          .div(1e9);

        const borrowValue = liquidationThreshold.sub(liquidity);
        const borrowLimit = cNftValue
          .mul(collateralFactor)
          .mul(4) // 80%
          .div(5)
          .div(1e9)
          .div(1e9);
        const limitWei = max(borrowLimit.sub(borrowValue), BigNumber.from(0));

        const limitWei2 = bunkerState.value?.liquidity;
        console.log("borrow limits", limitWei2, limitWei);
        const limitEth = min(
          limitWei,
          bunkerState.value.cEtherTotalSupply.sub(
            bunkerState.value.cEtherTotalBorrow
          )
        );
        const limitUsdc = min(
          limitWei.mul(1e6).div(bunkerState.value.cUSDCPrice),
          bunkerState.value.cUSDCTotalSupply.sub(bunkerState.value.cUSDCTotalBorrow)
        );

        const availableSupplyEth = bunkerState.value.cEtherTotalSupply.sub(
          bunkerState.value.cEtherTotalBorrow
        );
        const availableSupplyUSDC = bunkerState.value.cUSDCTotalSupply.sub(
          bunkerState.value.cUSDCTotalBorrow
        );

        return [
          {
            name: "ETH",
            totalBorrowDollars:
              "$" +
              (
                bunkerState.value.cEtherTotalBorrow
                  .mul(100)
                  .div(bunkerState.value.cUSDCPrice)
                  .toNumber() / 100
              ).toFixed(2),
            totalBorrow: formatEther(bunkerState.value.cEtherTotalBorrow),
            apr: bunkerState.value.cEtherBorrowApr,
            owedBalanceDollars:
              "$" +
              (
                bunkerState.value.cEtherOwed
                  .mul(100)
                  .div(bunkerState.value.cUSDCPrice)
                  .toNumber() / 100
              ).toFixed(2),
            owedBalance: formatEther(bunkerState.value.cEtherOwed),
            owedBalanceRaw: bunkerState.value.cEtherOwed,
            borrowLimit: formatEther(limitEth),
            borrowLimitRaw: limitEth,
            borrowLimitDollars:
              "$" +
              (
                limitEth.mul(100).div(bunkerState.value.cUSDCPrice).toNumber() / 100
              ).toFixed(2),
            availableSupply: Number(formatEther(availableSupplyEth)).toFixed(4) + " ETH",
            availableSupplyDollars:
              "$" +
              availableSupplyEth
                .mul(100)
                .div(bunkerState.value.cUSDCPrice)
                .toNumber() /
                100,
          },
          {
            name: "USDC",
            totalBorrowDollars:
              "$" +
              (bunkerState.value.cUSDCTotalBorrow.toNumber() / 1e6).toFixed(2),
            totalBorrow: formatUnits(bunkerState.value.cUSDCTotalBorrow, 6),
            apr: bunkerState.value.cUSDCBorrowApr,
            owedBalanceDollars:
              "$" + (bunkerState.value.cUsdcOwed.toNumber() / 1e6).toFixed(2),
            owedBalance: formatUnits(bunkerState.value.cUsdcOwed, 6),
            owedBalanceRaw: bunkerState.value.cUsdcOwed,
            borrowLimit: formatUnits(limitUsdc, 6),
            borrowLimitRaw: limitUsdc,
            borrowLimitDollars: "$" + Number(formatUnits(limitUsdc, 6)).toFixed(2),
            availableSupply: Number(formatUnits(availableSupplyUSDC, 6)).toFixed(2) + " USDC",
            availableSupplyDollars: "$" + formatUnits(availableSupplyUSDC, 6),
          },
        ];
      }, [bunkerState.value]);

    const [modalState, setModalState] =
        useState<["ETH" | "USDC", string, BigNumber]>();

    const handleSubmit = useCallback(
        async (value: BigNumber, isMax: boolean) => {
        if (!modalState || !contracts || !provider) return;
        console.log('Input value for txn: => ', value.toString());
        const signer = provider.getSigner();
        let txn;
        if (modalState[0] === "ETH") {
            if (modalState[1] === "Repay") {
              // txn = await contracts.CEther.connect(signer).repayBorrow({ value });
              txn = await contracts.Maximillion.connect(signer)
                .repayBehalfExplicit(signer.getAddress(), contracts.CEther.address, { value });
            } else {
              txn = await contracts.CEther.connect(signer).borrow(value);
            }
        } else if (modalState[0] === "USDC") {
            if (modalState[1] === "Repay") {
              if (isMax) value = MaxUint256; //BigNumber.from(-1); //formatUnits(-1, 6);
              console.log('Repaying value :', value, value.toString());
              txn = await contracts.CUSDC.connect(signer).repayBorrow(value);
              // txn = await contracts.Maximillion.connect(signer)
              //   .repayBehalfExplicit(signer.getAddress(), contracts.CUSDC.address, { value });
            } else {
              txn = await contracts.CUSDC.connect(signer).borrow(value);
            }
        }
        console.log(txn);
        bunkerState.refresh();
        console.log("done submitting");
        },
            [bunkerState, contracts, modalState, provider]
    );
    const handleRequestApproval = useCallback(async () => {
        if (!modalState || !contracts || !provider) return;
        const signer = provider.getSigner();
        const txn = contracts.USDC.connect(signer).approve(
        contracts.CUSDC.address,
        MaxUint256
        );
        bunkerState.refresh();
    }, [bunkerState, contracts, modalState, provider]);

    return [borrowData, modalState, setModalState, handleSubmit, handleRequestApproval] as const;
}
