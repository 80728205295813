import EitImg from "assets/ic-eth.svg";

import EitImgRound from "assets/ic-eth-round.svg";
import DollorImg from "assets/ic-dollor.svg";
import NftPunkOne from "assets/punk-one.jpeg";
import _ from "lodash";

import { AppHeader } from "components/AppHeader/AppHeader";
import { Link, useParams } from "react-router-dom";
import BorrowTableRow from "components/BorrowTableRow";
import {
  DashboardContainer,
  DetailsListWithPadding,
} from "components/DashboardStyledComponents";
import LoanWrapper from "components/LoanWrapper";

import { ColoredHeading, TableWrapper, TabList } from "../styledComponents";
import Sidebar from "components/Sidebar";
import { DashboardNavMobile } from "components/DashboardNav";
import RightContainer from "components/RightContainer";

import useNftFetch from "hooks/useNftFetch";
import { useEffect, useState } from "react";
import { useBunkerState } from "contexts/BunkerStateContextProvider";

import { NftPoolItem } from "interfaces/interfaces";
import useBorrowerData from "hooks/useBorrowerData";
import { useWeb3Context } from "contexts/Web3ContextProvider";
import { useOpenseaData } from "hooks/useOpenseaData";
import { useNftContext } from "contexts/NftContextProvider";
import useLenderData from "hooks/useLenderData";
import { BigNumber } from "ethers";
import { getFixedNumber, max, min } from "utils/commons";
import { MaxUint256 } from "@ethersproject/constants";
import { useBorrowCTokenData } from "hooks/useBorrowCTokenData";
import useUserNftData from "hooks/useUserNftData";
import Collateral from "./Collateral";

export const Borrow = () => {
  const { selectedPool } = useParams();
  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const bunkerState = useBunkerState();
  const nftState = useNftContext();
  console.log("nftState:", nftState);
  console.log("bunkerState:", bunkerState);

  const [
    borrowBalanceDollars,
    borrowBalanceEth,
    collateralBalanceDollars,
    collateralBalanceEth,
    borrowLimitDollars,
    borrowLimitEth,
    liquidationThresholdDollars,
    liquidationThresholdEth,
    apr,
    borrowBalance,
    borrowLimit,
    cNftValue,
  ] = useBorrowerData();

  const [supplyBalanceEth, supplyBalanceDollars, supplyAPY, dailyInterest] =
    useLenderData();

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(1),
    supplyBalance: Number(1),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };

  const [
    borrowData,
    modalState,
    setModalState,
    handleSubmit,
    handleRequestApproval,
  ] = useBorrowCTokenData();

  //my all assets
  const [myassetsData, nftPrice, requestApproval, deposit, redeem] =
    useUserNftData();

  const [getOpenseaData] = useOpenseaData();
  console.log("Opensea data in inventory details:", getOpenseaData);

  if (!myassetsData) {
    return <div>Loading your data...</div>;
  }

  return (
    <>
      <AppHeader activeTab={"dashboard"} />

      <DashboardNavMobile />

      <DashboardContainer>
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex flex-wrap">
              <Sidebar>
                <div className="nft-details">
                  <div className="imgbox">
                    <img src={cryptoPunkItem.nftImage} />
                  </div>
                  <div className="imgtxt">
                    <h2>{cryptoPunkItem.nftName}</h2>
                    <div className="flex-center-inner">
                      <h6>Total Value Locked</h6>
                      <h4>
                        {/* <img src={EitImg} />  */}
                        {getTVL()}
                      </h4>
                    </div>
                  </div>
                </div>
              </Sidebar>

              <RightContainer>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <ColoredHeading>I want to</ColoredHeading>
                      <TabList>
                        <li>
                          <Link
                            to={`/${selectedPool}/borrow`}
                            className="activetab"
                          >
                            Borrow
                          </Link>
                        </li>
                        <li>
                          <Link to={`/${selectedPool}/lend`}>Lend</Link>
                        </li>
                      </TabList>

                      <DetailsListWithPadding>
                        <li>
                          <h6>Supply Balance</h6>
                          <h1>
                            <img src={EitImg} />{" "}
                            {getFixedNumber(
                              supplyBalanceEth.replace("ETH", "")
                            )}
                          </h1>
                        </li>
                        <li>
                          <h6>Daily Interest </h6>
                          <h1>
                            <img src={EitImg} /> {getFixedNumber(dailyInterest)}
                          </h1>
                        </li>
                        <li>
                          <h6>Effective Supply APY</h6>
                          <h1>{supplyAPY}</h1>
                        </li>
                        <li>
                          <h6>Borrow Limit</h6>
                          <h1>
                            <img src={EitImg} />{" "}
                            {getFixedNumber(borrowLimitEth)}
                          </h1>
                        </li>
                      </DetailsListWithPadding>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <LoanWrapper className="mb-4">
                        <ul className="loan-listing">
                          <li>
                            <h4>Loan Balance</h4>
                            <h1>
                              <img src={EitImg} />{" "}
                              {getFixedNumber(borrowBalanceEth)}
                            </h1>
                          </li>
                          <li>
                            <h4>Liquidation Threshold</h4>
                            <h1>
                              <img src={EitImg} />{" "}
                              {getFixedNumber(liquidationThresholdEth)}
                            </h1>
                          </li>
                          <li>
                            <h4>Collateral Balance</h4>
                            <h1>
                              <img src={EitImg} />{" "}
                              {getFixedNumber(collateralBalanceEth)}
                            </h1>
                          </li>
                          <li>
                            <h4>Borrow APR</h4>
                            <h1>
                              <img src={EitImg} /> {apr}
                            </h1>
                          </li>
                        </ul>
                        <div className="flex-slider-wrap">
                          <div className="flex-slider">
                            <div className="flex-left">
                              Loan Utilization{" "}
                              <span>
                                {Number(borrowLimitEth) === 0
                                  ? 100
                                  : (
                                      (Number(borrowBalanceEth) /
                                        Number(borrowLimitEth)) *
                                      100
                                    ).toFixed(2)}
                                %
                              </span>
                            </div>
                            <div className="flex-middle">
                              <input
                                id="slider1"
                                type="range"
                                className="rangeslider"
                                min="0"
                                max="125"
                                disabled
                              />
                              <span
                                style={
                                  {
                                    left: "75%", //USER PERCENTAGE
                                    "--triangle-color":
                                      0.75 < 0.8
                                        ? "green"
                                        : 0.75 < 1.2
                                        ? "yellow"
                                        : "red",
                                  } as React.CSSProperties
                                }
                                className="progress-you"
                              >
                                You
                              </span>
                              <span className="progress-one">75%</span>
                              <span className="progress-two">100%</span>
                              <span className="progress-three">125%</span>
                            </div>
                            <div className="flex-right">232,548</div>
                          </div>
                        </div>
                      </LoanWrapper>
                    </div>
                  </div>

                  <div className="row">
                    <TableWrapper className="col-lg-12 mt-0">
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>Pool Assets</th>
                              <th>Pool Liquidity</th>
                              <th>APR</th>
                              <th>Amount Owed</th>
                              <th>Amount Supplied</th>
                              <th>Borrow Limit</th>
                              <th style={{ textAlign: "right" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {borrowData.map(
                              ({
                                name,
                                availableSupply,
                                availableSupplyDollars,
                                apr,
                                owedBalanceDollars,
                                owedBalance,
                                borrowLimit,
                                borrowLimitRaw,
                                borrowLimitDollars,
                                owedBalanceRaw,
                              }) => (
                                <BorrowTableRow
                                  asset={
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          name === "ETH"
                                            ? EitImgRound
                                            : DollorImg
                                        }
                                      />{" "}
                                      <h6 className="heading-sm">{name}</h6>
                                    </div>
                                  }
                                  poolUtilization={availableSupplyDollars}
                                  apy={apr + "%"}
                                  supplied={owedBalanceDollars}
                                  amountSupplied={availableSupply}
                                  withdrawLimit={borrowLimitDollars}
                                  modalData={{
                                    apy: apr,
                                    name: name,
                                    borrowBalance: owedBalance,
                                    borrowLimit: borrowLimitEth,
                                    borrowLimitDollar: borrowLimitDollars,
                                    borrowLimitRaw: borrowLimitRaw,
                                    owedBalanceRaw: owedBalanceRaw,
                                  }}
                                />
                              )
                            )}
                            {/* <BorrowTableRow
                              asset={
                                <div className="d-flex align-items-center">
                                  <img src={DollorImg} />{" "}
                                  <h5 className="heading-sm">USDC</h5>
                                </div>
                              }
                              poolUtilization="0.00%"
                              apy="0.00%"
                              supplied="0.00%"
                              amountSupplied="0.00"
                              withdrawLimit="0.00"
                            /> */}
                          </tbody>
                        </table>
                      </div>
                    </TableWrapper>
                  </div>

                  <Collateral myassetsData={myassetsData} />
                </div>
              </RightContainer>
            </div>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
