import React from "react";

import { IconButton } from "@mui/material";
import { FaList } from "react-icons/fa";
import { RiLayoutGridFill } from "react-icons/ri";

export default function GridTableController({ view, setView }) {
  const isTableActive = view === "table";

  return (
    <>
      <IconButton
        className={isTableActive ? "active" : ""}
        onClick={() => setView("table")}
      >
        <FaList />
      </IconButton>
      <IconButton
        className={isTableActive ? "" : "active"}
        onClick={() => setView("grid")}
      >
        <RiLayoutGridFill />
      </IconButton>
    </>
  );
}
