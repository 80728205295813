import { createTheme, Button, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#14B8A6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFF",
    },
    error: {
      main: "rgba(239, 68, 68, 1)",
    },
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          padding: '12px 34px',
          fontSize: "1rem",
          "&.Mui-disabled": {
            "color": "#71717A",
          }
        },
      }
    }
  }
});

export default function CustomButton(props) {
  return (
    <ThemeProvider theme={theme}>
      <Button color="primary" variant="contained" {...props} />
    </ThemeProvider>
  );
}
