import { FaGithub, FaTelegram, FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import styled from "styled-components";

export default function Socials() {
  return (
    <>
      <ExternalLink
        rel="noreferrer"
        target="_blank"
        href="https://twitter.com/bunker_finance"
        title="Twitter"
      >
        <BsTwitter />
      </ExternalLink>
      <ExternalLink
        rel="noreferrer"
        target="_blank"
        href="https://t.me/+fxDfq8mS-Wc1NTEx"
        title="Telegram"
      >
        <FaTelegram />
      </ExternalLink>
      <ExternalLink
        rel="noreferrer"
        target="_blank"
        href="https://discord.com/invite/hv2dZFD563"
        title="Discord"
      >
        <FaDiscord />
      </ExternalLink>
      <ExternalLink
        rel="noreferrer"
        target="_blank"
        href="https://github.com/bunkerfinance"
        title="Github"
      >
        <FaGithub />
      </ExternalLink>
    </>
  );
}

export const ExternalLink = styled.a`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 20px;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

  @media (max-width: 980px) {
    display: block;
    margin: 0 20px;
    padding: 20px 20px;
    color: #18181b;

    &:hover {
      text-decoration: none;
      color: #666;
    }

    ::after {
      content: attr(title);
      margin-left: 10px;
    }
  }
`;
