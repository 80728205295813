import React from "react";
import BrandLogo from "../../assets/brand-black.svg";
import BrandLogoWhite from "../../assets/brand-white.svg";

import SettingImg from "../../assets/setting-img.svg";
import MetamaskImg from "../../assets/metamask.svg";
import { Link, useNavigate } from "react-router-dom";
import { useWeb3Context } from "contexts/Web3ContextProvider";
import { formatAddress } from "utils/commons";
import DarkModeSwitch from "components/DarkMode";
import { Header } from "./components";

export const AppHeader: React.FC<{ activeTab: string }> = ({ activeTab }) => {
  const { provider, userId, connect } = useWeb3Context();

  const navigate = useNavigate();

  const providerIsPresent = !!provider;

  React.useEffect(() => {
    if (!providerIsPresent) {
      const confirmation = window.confirm(
        "You should connect your wallet before going on."
      );

      if (confirmation) connect();
      else navigate("/");
    }
  }, [providerIsPresent, connect, navigate]);

  return (
    <Header className="header-bg">
      <div className="left-section">
        <Link to={"/"}>
          <img src={BrandLogoWhite} alt="logo" className="logo-white" />
          <img src={BrandLogo} alt="logo" className="logo-black" />
        </Link>
      </div>
      <div className="middle-section">
        <ul className="menulist">
          <li>
            <h4>
              <Link
                to="/dashboard"
                className={activeTab === "dashboard" ? "activetab" : ""}
              >
                Dashboard
              </Link>
            </h4>
          </li>
          <li>
            <h4>
              <Link
                to="/inventory"
                className={activeTab === "inventory" ? "activetab" : ""}
              >
                Inventory
              </Link>
            </h4>
          </li>
        </ul>
      </div>
      <div className="right-section">
        <span className="dark-mode-container">
          <DarkModeSwitch />
        </span>
        <span>
          {/* <button className='filled-btn'>Conncet Wallet</button> */}
          {/* <button className='connect-btn'>233x...a8qs <img src={MetamaskImg} className="metamaskimg" /></button> */}
          {/* {provider && <>Connected as {formatAddress(userId)}</>} */}
          {provider && (
            <button className="connect-btn">
              {formatAddress(userId)}
              <img
                src={MetamaskImg}
                className="metamaskimg"
                alt="metamask wallet"
              />
            </button>
          )}
          {!provider && (
            <button onClick={connect} className="filled-btn">
              Connect Wallet
            </button>
          )}
        </span>
        <span className="hidden">
          <img src={SettingImg} />
        </span>
      </div>
    </Header>
  );
};
