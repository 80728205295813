import styled from "styled-components";

export const DetailsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    width: 24%;
    background: var(--color-lavender-blue);
    padding: 30px 20px;
    box-sizing: border-box;
    @media (max-width: 980px) {
      width: 48%;
      margin: 1%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 1% 0;
    }
    &:nth-child(2) {
      background: var(--color-solitude-two);
    }
    &:nth-child(3) {
      background: var(--color-peach);
    }
    &:nth-child(4) {
      background: var(--color-contrast);
      h6,
      h1 {
        color: var(--color-text-opposite);
      }
    }
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-black-russian);
    }
    h1 {
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      color: var(--color-black-russian);
      margin: 0px;
    }
  }
`;

export const DetailsListWithPadding = styled(DetailsList)`
  li {
    padding: 20px 20px !important;
  }
`;

export const DashboardContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 40px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-title);
    margin: 0 0 16px 0;
  }

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-title);
    margin: 0 0 16px 0;
  }
`;
