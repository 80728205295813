import { formatEther, formatUnits } from '@ethersproject/units';
import { useBunkerState } from 'contexts/BunkerStateContextProvider';
import { useWeb3Context } from 'contexts/Web3ContextProvider';
import { LendDataRow } from 'interfaces/interfaces';
import React, { useCallback, useMemo, useState } from 'react'
import { min } from 'utils/commons';
import { MaxUint256 } from "@ethersproject/constants";
import { BigNumber } from 'ethers';


export const useLendCTokenData = () => {
    const { provider, contracts, userId } = useWeb3Context();
    const bunkerState = useBunkerState();
    const lendData: LendDataRow[] = useMemo(() => {
        if (!bunkerState.value) return [];
        const limitEth = min(
          bunkerState.value.cEtherBalance,
          bunkerState.value.cEtherTotalSupply.sub(
            bunkerState.value.cEtherTotalBorrow
          )
        );
        const limitUsdc = min(
          bunkerState.value.cUsdcBalance,
          bunkerState.value.cUSDCTotalSupply.sub(bunkerState.value.cUSDCTotalBorrow)
        );

        return [
          {
            name: "ETH",
            utilization:
              bunkerState.value.cEtherTotalBorrow
                .mul(100)
                .div(bunkerState.value.cEtherTotalSupply.add(1))
                .toNumber() + "%",
            apy: bunkerState.value.cEtherSupplyApy,
            balanceDollars:
              "$" +
              (
                bunkerState.value.cEtherBalance
                  .mul(100)
                  .div(bunkerState.value.cUSDCPrice)
                  .toNumber() / 100
              ).toFixed(2),
            balance: formatEther(bunkerState.value.cEtherBalance),
            withdrawLimit: formatEther(limitEth),
            withdrawLimitDollars:
              "$" +
              (
                limitEth.mul(100).div(bunkerState.value.cUSDCPrice).toNumber() / 100
              ).toFixed(2),
            withdrawLimitRaw: limitEth,
            supplyLimitRaw: bunkerState.value.etherBalance,
          },
          {
            name: "USDC",
            utilization:
              bunkerState.value.cUSDCTotalBorrow
                .mul(100)
                .div(bunkerState.value.cUSDCTotalSupply.add(1))
                .toNumber() + "%",
            apy: bunkerState.value.cUSDCSupplyApy,
            balanceDollars:
              "$" + (bunkerState.value.cUsdcBalance.toNumber() / 1e6).toFixed(2),
            balance: formatUnits(bunkerState.value.cUsdcBalance, 6),
            withdrawLimit: formatUnits(limitUsdc, 6),
            withdrawLimitRaw: limitUsdc,
            withdrawLimitDollars: "$" + formatUnits(limitUsdc, 6),
            supplyLimitRaw: bunkerState.value.USDCBalance,
          },
        ];
      }, [bunkerState.value]);

      const ethLimit = () => {
        if (!bunkerState.value) return null;
        const limitEth = min(
          bunkerState.value.cEtherBalance,
          bunkerState.value.cEtherTotalSupply.sub(
            bunkerState.value.cEtherTotalBorrow
          )
        );
        return Number(limitEth);
      }

      const usdcLimit = () => {
        if (!bunkerState.value) return null;
        const limitUsdc = min(
          bunkerState.value.cUsdcBalance,
          bunkerState.value.cUSDCTotalSupply.sub(bunkerState.value.cUSDCTotalBorrow)
        );
        return Number(limitUsdc);
      }

      const totalLimit = () => {
        if (!bunkerState.value) return null;
        const limitEth = min(
            bunkerState.value.cEtherBalance,
            bunkerState.value.cEtherTotalSupply.sub(
              bunkerState.value.cEtherTotalBorrow
            )
          );
        const limitUsdc = min(
          bunkerState.value.cUsdcBalance,
          bunkerState.value.cUSDCTotalSupply.sub(bunkerState.value.cUSDCTotalBorrow)
        );
        const tLimitInUsdc = Number(formatUnits(limitUsdc, 6)) +  Number((
            limitEth.mul(100).div(bunkerState.value.cUSDCPrice).toNumber() / 100
          ).toFixed(2) ) ;

        return Number(
            formatEther((
                bunkerState.value.cUSDCPrice)
                .mul(Math.round(Number(tLimitInUsdc))))
            ).toFixed(4);
      }

      const [modalState, setModalState] =
        useState<["ETH" | "USDC", string, BigNumber]>();

      const handleSubmit = useCallback(
        async (value) => {
          if (!modalState || !contracts || !provider) return;
          console.log(value);
          const signer = provider.getSigner();
          let txn;
          if (modalState[0] === "ETH") {
            if (modalState[1] === "Supply") {
              txn = await contracts.CEther.connect(signer).mint({ value });
            } else {
              txn = await contracts.CEther.connect(signer).redeemUnderlying(value);
            }
          } else if (modalState[0] === "USDC") {
            if (modalState[1] === "Supply") {
              txn = await contracts.CUSDC.connect(signer).mint(value);
            } else {
              txn = await contracts.CUSDC.connect(signer).redeemUnderlying(value);
            }
          }
          console.log(txn);
          bunkerState.refresh();
          console.log("done submitting");
        },
        [bunkerState, contracts, modalState, provider]
      );
      
      const handleRequestApproval = useCallback(async () => {
        if (!modalState || !contracts || !provider) return;
        const signer = provider.getSigner();
        const txn = contracts.USDC.connect(signer).approve(
          contracts.CUSDC.address,
          MaxUint256
        );
        bunkerState.refresh();
      }, [bunkerState, contracts, modalState, provider]);

      console.log('----> lendData :', lendData);
      return [ lendData, ethLimit(), usdcLimit(), totalLimit(), handleSubmit, handleRequestApproval, modalState, setModalState] as const;
}
