import React from "react"
import { Link } from "react-router-dom";

import EitImg from "assets/ic-eth.svg";
import QualityImg from "assets/quantity.svg";
import { GreenLabel } from "components/AppHeader/components";

import NftList from "../../../components/NftList";
import { kebabCase } from "lodash";

export const GridView = ({
  nftPoolItems,
  poolSelectHandler,
  cryptoPunkItem,
  priceInEth,
  nftPriceInUSDC,
  totalNFTs,
}) => {
  return (
    <NftList>
      <li onClick={() => poolSelectHandler("punk")}>
        <div className="imgbox">
          <img src={cryptoPunkItem.nftImage} alt="punk" />
        </div>
        <div className="imgtxt">
          <h4>{cryptoPunkItem.nftName}</h4>
          <div className="flex-center">
            <div className="flex-center-inner">
              <h6>Average Price</h6>
              <h1>
                <img src={EitImg} /> {priceInEth}{" "}
                <span>($ {nftPriceInUSDC})</span>
              </h1>
            </div>
            <div className="flex-center-inner flex-end">
              <h6>Quantity</h6>
              <h1>
                <img src={QualityImg} /> x{totalNFTs}
              </h1>
            </div>
          </div>
        </div>
      </li>

      {nftPoolItems.map((nftPoolItem, key) => {
        const Wrapper = nftPoolItem.isActive ? Link : NoLink;
        return (
          <li key={key} style={nftPoolItem.isActive ? {} : { pointerEvents: "none"}}>
            <Wrapper to={`/${kebabCase(nftPoolItem.nftName)}/inventory-details`}>
              <div
                className={
                  nftPoolItem.isActive ? "imgbox" : "imgbox fade-image"
                }
              >
                <img src={nftPoolItem.nftImage} alt="nft" />
              </div>
              <div className="imgtxt">
                <h4>{nftPoolItem.nftName}</h4>

                {nftPoolItem.isActive ? (
                  <div className="flex-center">
                    <div className="flex-center-inner">
                      <h6>Average Price</h6>
                      <h1>
                        <img src={EitImg} /> {nftPoolItem.avgPrice}{" "}
                        <span>(0)</span>
                      </h1>
                    </div>
                    <div className="flex-center-inner flex-end">
                      <h6>Quantity</h6>
                      <h1>
                        <img src={QualityImg} /> x {nftPoolItem.quantity}
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="flex-center">
                    <div className="flex-center-inner">
                      <GreenLabel>Coming Soon...</GreenLabel>
                    </div>
                  </div>
                )}
              </div>
            </Wrapper>
          </li>
        );
      })}
    </NftList>
  );
};

const NoLink = React.Fragment;
