import styled from "styled-components";

const Sidebar = styled.div`
  width: 250px;
  @media (max-width: 980px) {
    width: 100%;
    margin: 40px 0 0 0;
  }
  .nft-details {
    background: var(--color-background);
    width: 100%;
    border: 1px solid var(--color-secondary-border);
    padding: 12px;
    .imgbox {
      position: relative;
      padding-top: 110%;
      margin-bottom: 18px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .imgtxt {
      padding: 0 10px;
      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: var(--color-title);
        margin: 16px 0px;
      }
      .flex-center-inner {
        width: 100%;
        h6 {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--color-text-info);
          margin: 3px 0px;
        }
        h4 {
          color: var(--color-title);
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          margin: 3px 0px;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            margin-right: 7px;
          }
        }
      }
    }
  }
`;

export default Sidebar;
