import { StyledAppBar, NavLink, Layout } from "..";
import Logo from "assets/brand-black.svg";
import { useForceLight } from "utils/useDarkMode";

import Container from "../components/Container";
import { Title, Description } from "../LandingContent";
import styled from "styled-components";

export default function TermsOfService() {
  useForceLight();
  return (
    <Layout>
      <StyledAppBar color="transparent" elevation={0} position="static">
        <NavLink to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
      </StyledAppBar>

      <Container>
        <Title>Terms of Service</Title>
        <Description>
          Please make sure you have read and understood our User Agreement prior
          to using the Bunker Protocol.
        </Description>

        <SubTitle>User Agreement</SubTitle>
        <Text>
          Please note that this is a Beta version of bunker.finance. The
          platform, its software and all content found on it are provided on an
          "as is" and "as available" basis
        </Text>
        <Text>
          While we have made reasonable efforts to ensure the security and
          functionality of bunker.finance including forking much of the codebase
          from existing audited projects, many well audited projects have been
          hacked and exploited leading to users' funds being drained.
          Bunker.finance may update its codebase which may be unaudited.
        </Text>
        <Text>
          We strongly advise caution to anyone who chooses to use
          bunker.finance.
        </Text>
        <Text>
          PLEASE DO NOT RISK ANY FUNDS YOU CANNOT AFFORD TO LOSE. Bunker.finance
          will not be liable to reimburse you for any hacks, exploits,
          transaction failures or lost funds.
        </Text>
      </Container>
    </Layout>
  );
}

const SubTitle = styled.h2`
  margin-top: 86px;
  font-family: "Encode Sans Semi Expanded";
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #18181b;
`;

const Text = styled.p`
  font-family: "Encode Sans Semi Expanded";
  font-weight: 400;
  font-size: calc(14 / 16) rem;
  line-height: 1.5;
  color: #18181b;

  margin-top: 12px;
`;
