import _ from "lodash";

import { TableWrapper } from "views/Dashboard/styledComponents";
import UnwrapCollateralModal from "components/modal/UnwrapCollateralModal";
import { useToggle } from "react-use";
import EitImg from "assets/ic-eth.svg";
import { EmptyAssetsComponent } from "components/EmptyAssetsComponent";

export const TableView = ({ myassetsData }) => {
  const [isOpen, toggle] = useToggle(false);

  if(myassetsData?.length === 0 ){
    return <EmptyAssetsComponent />
  }

  return (
    <TableWrapper>
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Asset</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.map(myassetsData, ({ id, name, url, value,  deposited, approved  }) => (
              <tr key={id}>
                <td>
                  <img
                    src={url}
                    alt={`${name} logo`}
                    style={{ paddingBottom: 6 }}
                  />
                  <span>
                    {name}#{id}
                  </span>
                </td>
                <td>
                  <img src={EitImg} alt="ETH Symbol" /> <span>{value}</span>
                </td>
                <td>
                  <button className="btn btn-unwrap btn-green" onClick={toggle}>
                    {
                      deposited ? "Unwrap" : "Deposit"
                    }
                  </button>
                  <UnwrapCollateralModal isOpen={isOpen} handleClose={toggle} nftData={{
                    name: name,
                    id: id,
                    deposited: deposited,
                    approved:approved,
                  }}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TableWrapper>
  );
};
