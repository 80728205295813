import Navbar from "./components/Navbar/Navbar";
import Content from "./components/Content/Content";
import Footer from "./components/Footer/Footer";
import { useForceLight } from "utils/useDarkMode";
import styled from "styled-components";
import Banner from "components/Banner";

export const LandingPage = () => {
  useForceLight();

  return (
    <LandingPageContainer>
      <Banner />
      <Navbar />
      <Content />
      <Footer />
    </LandingPageContainer>
  );
};

const LandingPageContainer = styled.div`
  @media (min-width: 1200px) {
    .container {
      width: 1400px !important;
    }
  }
`;
