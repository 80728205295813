import styled from "styled-components";

const NftList = styled.ul`
  list-style: none;
  margin: 0 -1%;
  padding: 0 0 44px 0;
  display: flex;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    box-sizing: border-box;
    background: var(--color-background);
    width: 23%;
    margin: 1%;
    height: 100%;
    z-index: 1;

    .no-details {
      border: var(--border-width) solid var(--color-border);
      padding: 6px 6px 10px 6px;
      box-sizing: border-box;
    }

    &:hover {
      z-index: 2;
      background: var(--color-background);
      box-shadow: 0px 25px 50px var(--color-shadow);
      transform: scale(1.07);
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      .imgbox {
        img {
          height: 90%;
        }
        padding-top: 64%;
        margin: 0;
      }

      .no-details {
        border: var(--border-width) solid var(--color-border-contrast);
        border-bottom: none;
      }

      .full-details {
        display: block;
      }
    }
    @media (max-width: 980px) {
      width: 48%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 12px 0;
    }
    .imgbox {
      position: relative;
      padding-top: 90%;
      margin-bottom: 18px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .fade-image {
      opacity: 50%;
    }
    .imgtxt {
      padding: 0 16px;
      width: 100%;
      box-sizing: border-box;
      color: var(--color-text);
      > h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 10px 0;
      }
      > .flex-center {
        display: flex;
        justify-content: space-between;
        .flex-center-inner {
          display: flex;
          flex-wrap: wrap;
          h6 {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: var(--color-text-label);
            margin: 3px 0px;
            width: 100%;
          }
          h1 {
            color: var(--color-text-info);
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            margin: 3px 0px;
            width: 100%;
            span {
              font-size: 14px;
            }
          }
        }
        & .flex-end {
          justify-content: flex-end;
          h6,
          h1 {
            display: block;
            width: 100%;
            text-align: right;
          }
        }
      }
    }
    .full-details {
      display: none;
      background: var(--color-background);
      margin-top: 10px;
      padding: 0px 22px 10px 22px;
      border: var(--border-width) solid var(--color-border-contrast);
      border-top: none;
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      transform: translateY(99%);
      box-shadow: 0px 25px 30px var(--color-shadow);

      .dashed-separator {
        border-top: 1px dashed var(--color-text-info);
        height: 1px;
        width: 100%;
        background: var(--color-background);
        display: block;
        margin-bottom: 12px;
      }

      .dflex-between {
        display: flex;
        justify-content: space-between;
        h6 {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--color-text-info);
          margin: 3px 0px;
        }
        h5 {
          color: var(--color-text);
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin: 3px 0px;
        }
      }
    }
    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
`;

export const NftListThree = styled.ul`
  list-style: none;
  margin: 0 -1%;
  padding: 0 0 44px 0;
  display: flex;
  flex-wrap: wrap;
  li {
    background: var(--color-background);
    box-sizing: border-box;
    padding: 6px 6px 30px 6px;
    width: 31.333%;
    margin: 1%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    @media (max-width: 767px) {
      width: 100%;
      margin: 1% 0;
    }
    &:hover {
      background: var(--color-background);
      box-shadow: 0px 25px 50px var(--color-shadow);
      .btn-unwrap {
        display: block;
        padding: 12px 16px;
        background: var(--color-background);
        border: 1px solid var(--color-primary);
        box-sizing: border-box;
        box-shadow: 0px 1px 2px var(--color-secondary-shadow);
        border-radius: 2px;
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-primary);
        margin-top: 9px;
      }
      .hide-hover {
        display: none;
      }
    }
    .btn-unwrap {
      display: none;
    }
    .imgbox {
      position: relative;
      padding-top: 90%;
      margin-bottom: 18px;
      width: 100%;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .imgtxt {
      padding: 0 16px;
      width: 100%;
      box-sizing: border-box;
      > h6 {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-text-info) !important;
        margin: 0 0 10px 0;
      }
      > h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--color-title);
        margin: 7px 0px;
      }
      .hide-hover {
        > h5 {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--color-text-info) !important;
          margin: 0 0 5px 0;
        }
        > .flex-center {
          .flex-center-inner {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            > h3 {
              color: var(--color-title);
              font-weight: 400;
              font-size: 18px;
              line-height: 28px;
              margin: 0px;
            }
          }
        }
      }
    }
    a {
      text-decoration: none;
      cursor: pointer;
      color: inherit;
    }
  }
`;

export const NftListThreeItemsWithBorder = styled(NftListThree)`
  li {
    border: 1px solid var(--color-border);

    &::hover {
      border: 1px solid var(--color-border-contrast);
    }
  }
`;

export default NftList;
