import { Divider } from "@mui/material";
import styled from "styled-components";

import Container from "./components/Container";
import Button from "./components/Button";
import Topics from "./components/Topics";

import "./index.css";
import { first } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useCopy } from "./index";

export default function LandingContent() {
  const location = useLocation();

  const { rawData: data, title, description } = useCopy();

  const firstSection = first(data);
  const firstTopic = first(firstSection.topics);
  const firstPath = `${location.pathname}/${firstSection.slug}/${firstTopic.slug}`;

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>

        <Link to={firstPath} style={{ textDecoration: "none" }}>
          <Button>Get Started</Button>
        </Link>
      </Container>

      <Hr variant="fullWidth" />
      <Container>
        <Topics />
      </Container>
    </>
  );
}

export const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  width: clamp(40%, 800px, 90vw - 32px);
`;

const Hr = styled(Divider)`
  background-color: rgba(228, 228, 231, 1);
  margin-top: 40px;
  margin-bottom: 48px;
  align-self: center;

  @media (min-width: 767px) {
    width: 85%;
  }
`;
