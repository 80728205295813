import EitImg from "../../assets/ic-eth.svg";
import NftPunkOne from "../../assets/punk-one.jpeg";
import ListImg from "../../assets/ic-list.svg";
import GridImg from "../../assets/ic-grid.svg";
import { AppHeader } from "components/AppHeader/AppHeader";

import { useBunkerState } from "contexts/BunkerStateContextProvider";
// import { useWeb3Context } from "contexts/Web3ContextProvider";
// import { useToggle } from "react-use";
import { formatEther, formatUnits } from "@ethersproject/units";
import { useMemo } from "react";

import { Decimal } from "decimal.js";
// import { DasboardInventoryComponent } from "components/DashboardInventoryComponent/DasboardInventoryComponent";
import { DashboardPoolComponent } from "components/DashboardPoolComponent/DashboardPoolComponent";
// import { NftContextProvider } from "contexts/NftContextProvider";
import {
  DashboardContainer,
  DetailsList,
} from "../../components/DashboardStyledComponents";
import SectionTitleWrapper from "../../components/SectionTitleWrapper";
// import { TableWrapper } from "./styledComponents";
import { DashboardNavMobile } from "components/DashboardNav";
import { getFixedNumber4, weiToDollars } from "utils/commons";
import { useNavigate } from "react-router-dom";
import { TableWrapper } from "./styledComponents";
import { DasboardInventoryComponent } from "components/DashboardInventoryComponent/DasboardInventoryComponent";
import useNftFetch from "hooks/useNftFetch";
import useBorrowerData from "hooks/useBorrowerData";
import useLenderData from "hooks/useLenderData";

interface NftPoolItem {
  nftImage: string;
  nftName: string;
  borrowBalance: number;
  supplyBalance: number;
  valueLocked: string;
  nftDeposited: number | "??";
  supply: string;
  borrow: string;
  isActive: boolean;
}

export const Dashboard = () => {
  // const { provider, contracts, userId } = useWeb3Context();
  const bunkerState = useBunkerState();
  // const [open, toggleOpen] = useToggle(false);

  // const nftState = useNftContext();
  // console.log('nftState:', nftState);
  console.log("bunkerState:", bunkerState);

  // const formatter = Intl.NumberFormat("en", { notation: "compact" });

  // const state = bunkerState;

  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const [
    borrowBalanceDollars,
    borrowBalanceEth,
    collateralBalanceDollars,
    collateralBalanceEth,
    borrowLimitDollars,
    borrowLimitEth,
    liquidationThresholdDollars,
    liquidationThresholdEth,
    apr,
    borrowBalance,
    borrowLimit,
  ] = useBorrowerData();

  const [supplyBalanceEth, supplyBalanceDollars, supplyAPY] = useLenderData();

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(borrowBalanceEth),
    supplyBalance: Number(supplyBalanceEth),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };

  //--------------- INVENTORY ---------------------//

  return (
    // TODO: this nees to be revisited, whats effect of moving nftContext moving to app level.
    // <NftContextProvider>
    <div>
      <AppHeader activeTab={"dashboard"} />

      <DashboardNavMobile />

      <DashboardContainer>
        <div className="row">
          <div className="col-lg-12">
            <h2>Dashboard</h2>
            <DetailsList>
              <li>
                <h6>Supply Balance</h6>
                <h1>
                  <img src={EitImg} />
                  {getFixedNumber4(supplyBalanceEth)}
                </h1>
              </li>
              <li>
                <h6>Borrow Balance</h6>
                <h1>
                  <img src={EitImg} />
                  {getFixedNumber4(borrowBalanceEth)}
                </h1>
              </li>
              <li>
                <h6>Collateral Balance</h6>
                <h1>
                  <img src={EitImg} /> {getFixedNumber4(collateralBalanceEth)}
                </h1>
              </li>
              <li>
                <h6>Borrow Limit</h6>
                <h1>
                  <img src={EitImg} /> {getFixedNumber4(borrowLimitEth)}
                </h1>
              </li>
            </DetailsList>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <DashboardPoolComponent />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <SectionTitleWrapper>
              <h4>Inventory</h4>
              <div>
                <span>
                  <img src={ListImg} />
                </span>
                <span className="ml-3">
                  <img src={GridImg} />
                </span>
              </div>
            </SectionTitleWrapper>
          </div>
        </div>

        <div className="row">
          <TableWrapper className="col-lg-12">
            <DasboardInventoryComponent />
          </TableWrapper>
        </div>
      </DashboardContainer>
    </div>
  );
};
