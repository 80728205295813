import React, { useMemo } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Modal, {
  FailureMessage,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  SuccessMessage,
} from "components/modal";

import IcClose from "assets/ic-close.svg";
import EitImg from "assets/ic-eth.svg";

import IcArrow from "assets/ic-arrow.svg";
import DollorImg from "assets/ic-dollor.svg";
import { useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { useLendCTokenData } from "hooks/useLendCTokenData";
import { useToggle } from "react-use";
import { utils } from "ethers";
import SuccessImg from "assets/success-right.svg";
import { formatUnits } from "@ethersproject/units";

const TABS = ["Supply", "Withdraw"];

export default function SupplyWithdrawModal({
  isOpen,
  handleClose,
  modalData,
}) {
  const {
    submitState,
    setSubmitState,
    max,
    toggleMax,
    inputData,
    setInputData,
    inputDataDollar,
    newTotalAmountSupplied,
    newTotalWithdrawLimit,
    setModalState,
    amountParsed,
    maxAmount,
    needsApproval,
    limit,
    decimals,
    handleSubmit,
    handleRequestApproval,
    submitLabel, 
    setSubmitLabel,
    newTotalAmountSupplied_withdraw,
    newTotalWithdrawLimit_withdraw
  } = useSupplyWithdrawWeb3(modalData);

  const isSubmitting = submitState === "submitting";
  const isSuccess = submitState === "success";
  const isFailure = submitState === "failure";

  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    if (TABS[selectedTab] === "Supply") {
      setModalState([modalData.name, "Supply", modalData.supplyLimitRaw])
    }
    else{
      setModalState([modalData.name, "Withdraw", modalData.withdrawLimitRaw])
    }
  }, [selectedTab])
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Supply/Withdraw"
      ariaHideApp={false}
    >
      <ModalHeader>
        <h4>
          {TABS[selectedTab]} {modalData.name}
        </h4>
        <button className="closebtn" onClick={handleClose}>
          <img src={IcClose} alt="token icon" />
        </button>
      </ModalHeader>
      <ModalBody>
        {!isSuccess ? (
          <>
            <div className="inputarea">
              <span className="label-green" onClick={toggleMax}>
                Max
              </span>
              <span className="input-wrap">
                {modalData.name === "ETH" ? (
                  <img src={EitImg} alt="eth icon" />
                ) : (
                  <img src={DollorImg} alt="dollar icon" />
                )}
                {/* <input type="text" placeholder="0" className="input-style" /> */}
                <input
                  type="text"
                  placeholder={inputData ? inputData : 0}
                  className="input-style"
                  onChange={(e) => setInputData(e.target.value)}
                  value={max ? maxAmount : inputData}
                  // disabled={!borrowLimitUsedInit }
                />
              </span>
            </div>
            <div className="text-center">
              <h6 className="answer-output">$ {inputDataDollar ? inputDataDollar : 0}</h6>
            </div>

            <div className="tabs-wrap">
              <Tabs
                selectedIndex={selectedTab}
                onSelect={(selectedIndex) => setSelectedTab(selectedIndex)}
              >
                <TabList>
                  <Tab disabled={isSubmitting}>Supply</Tab>
                  <Tab disabled={isSubmitting}>Withdraw</Tab>
                </TabList>

                <TabPanel>
                  <SupplyTab
                    modalData={modalData}
                    newTotalWithdrawLimit={newTotalWithdrawLimit}
                    inputData={inputData}
                    newTotalAmountSupplied={newTotalAmountSupplied}
                    isSubmitting={isSubmitting}
                    setModalState={setModalState}
                  />
                </TabPanel>

                <TabPanel>
                  <WithdrawTab
                    modalData={modalData}
                    newTotalWithdrawLimit={newTotalAmountSupplied_withdraw}
                    inputData={inputData}
                    newTotalAmountSupplied={newTotalAmountSupplied_withdraw}
                    isSubmitting={isSubmitting}
                    setModalState={setModalState}
                  />
                </TabPanel>

                {isFailure && <FailureMessage>The action failed</FailureMessage>}
              </Tabs>
            </div>
          </>
        ) : (
          <div className="text-center">
            <img src={SuccessImg} />
            <SuccessMessage>The {TABS[selectedTab]} was successful!</SuccessMessage>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {!submitState &&
          amountParsed &&
          !needsApproval(amountParsed) &&
          limit &&
          amountParsed.gt(limit) && (
            <span>
              Max value is {formatUnits(limit, decimals).substring(0, 8)}
            </span>
        )}

        {!submitState && amountParsed && needsApproval(amountParsed) && (
            <ModalButton onClick={handleRequestApproval}>
              Approve {modalData.name}
            </ModalButton>
        )}

        <ModalButton 
          disabled={
            !!submitState ||
            !amountParsed ||
            needsApproval(amountParsed) ||
            (limit && amountParsed.gt(limit))
          }
          onClick={async () => {
            setSubmitState("Submitting...");
            try {
              await handleSubmit(amountParsed, max);
              setSubmitState("Success!");
              setTimeout(() => {
                // handleModalClose?.({}, "escapeKeyDown");
              }, 1000);
            } catch (e) {
              console.log(e);
              setSubmitState("Failure!");
            }
          }}
        >
          {submitState || submitLabel}
        </ModalButton>


      </ModalFooter>
    </Modal>
  );
}

function useSupplyWithdrawWeb3(modalData) {
  const [inputData, setInputData] = useState();
  const [inputDataDollar, setInputDataDollar] = useState(0);
  const [newTotalAmountSupplied, setNewTotalAmountSupplied] = useState(0);
  const [newTotalWithdrawLimit, setNewTotalWithdrawLimit] = useState(0);
  const [newTotalAmountSupplied_withdraw, setNewTotalAmountSupplied_withdraw] = useState(0);
  const [newTotalWithdrawLimit_withdraw, setNewTotalWithdrawLimit_withdraw] = useState(0);
  const [max, toggleMax] = useToggle(false);
  const [submitState, setSubmitState] = useState("");
  
  const bunkerState = useBunkerState();
  const ethPriceInUsdc = Number(
    BigNumber.from(1e9)
      .mul(BigNumber.from(1e9))
      .div(bunkerState.value.cUSDCPrice)
  );
  const [
    lendData,
    ethLimit,
    usdcLimit,
    totalLimit,
    handleSubmit,
    handleRequestApproval,
    modalState,
    setModalState,
  ] = useLendCTokenData();


  useEffect(() => {
    if (modalData.name === "ETH") {
      setInputDataDollar(Number(inputData) * ethPriceInUsdc);
      setNewTotalAmountSupplied(
        Number(inputData) + Number(modalData.amountSupplied)
      );
      setNewTotalAmountSupplied_withdraw(
        Number(modalData.amountSupplied) - Number(inputData) >= 0 ?
          Number(modalData.amountSupplied) - Number(inputData) : 0
      )

      setNewTotalWithdrawLimit(
        Number(modalData.amountSupplied) + Number(inputData)
      );
      setNewTotalWithdrawLimit_withdraw(
        Number(modalData.amountSupplied)  - Number(inputData) > 0 ?
          Number(modalData.amountSupplied)  - Number(inputData) : 0
      );

    } else {
      //'USDC'
      setInputDataDollar(Number(inputData));
      setNewTotalAmountSupplied(
        Number(inputData) + Number(modalData.amountSupplied)
      );
      setNewTotalAmountSupplied_withdraw(
        Number(modalData.amountSupplied) - Number(inputData) >= 0 ?
          Number(modalData.amountSupplied) - Number(inputData) : 0
      );

      setNewTotalWithdrawLimit(
        Number(modalData.amountSupplied) + Number(inputData)
      );
      setNewTotalWithdrawLimit_withdraw(
        Number(modalData.amountSupplied)  - Number(inputData) > 0 ?
          Number(modalData.amountSupplied)  - Number(inputData) : 0

      );
    }
  }, [inputData]);

  const [symbol, setSymbol] = useState('ETH');
  const [submitLabel, setSubmitLabel] = useState("");
  const [decimals, setDecimals] = useState(18);
  const [limit, setLimit] = useState(0);


  useEffect(() => {
    setModalState([modalData.name, "Supply", modalData.supplyLimitRaw]);
  },[]);

  const needsApproval = (value) => (
    modalState?.[0] === "USDC" && value.gt(bunkerState.value.USDCallowance)
  )

    //on tab selection
    useEffect(() => {
      setSymbol(modalState?.[0] ?? "ETH");
      setSubmitLabel(modalState?.[1] ?? "");
      setDecimals(modalState?.[0] === "ETH" ? 18 : 6);
      setLimit(modalState?.[2]);
  
      return () => {
        setSubmitLabel('');
      }
    }, [modalState])
    
    
    const amountParsed = useMemo(() => {
      if (max) return limit;
      try {
        return utils.parseUnits(inputData, decimals);
      } catch (e) {
        return null;
      }
    }, [inputData, decimals, limit, max]);
    
    const maxAmount = useMemo(() => {
      return utils.formatUnits(limit || 0, decimals);
    }, [limit, decimals]);

    
  return {
    modalState,
    submitState,
    setSubmitState,
    max,
    toggleMax,
    inputData,
    setInputData,
    inputDataDollar,
    setInputDataDollar,
    newTotalAmountSupplied,
    newTotalWithdrawLimit,
    setModalState,
    amountParsed,
    maxAmount,
    needsApproval,
    limit,
    decimals,
    handleSubmit,
    handleRequestApproval,
    submitLabel, 
    setSubmitLabel,
    newTotalAmountSupplied_withdraw,
    newTotalWithdrawLimit_withdraw
  };
}

function SupplyTab({
  modalData,
  newTotalWithdrawLimit,
  inputData,
  newTotalAmountSupplied,
  isSubmitting,
  setModalState,
}) {
  return (
    <>
      <h4 className="table-head">SUPPLY RATE</h4>
      <div className="table-responsive">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td>
                <div className="d-flex align-items-center">
                  {modalData.name === "ETH" ? (
                    <img src={EitImg} alt="eth icon" />
                  ) : (
                    <img src={DollorImg} alt="dollar icon" />
                  )}
                  <h6 className="heading-sm mb-0 ml-2">{modalData.name} APY</h6>
                </div>
              </td>
              <td style={{ textAlign: "right" }}>{modalData.apy} %</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 className="table-head">SUPPLY BALANCE</h4>
      <div className="table-responsive">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td>Amount Supplied</td>
              <td>
                <span className="font12">{modalData.name}</span>{" "}
                <span className="font16">
                  {" "}
                  {Number(modalData.amountSupplied).toFixed(4)}{" "}
                </span>
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-end">
                  <img src={IcArrow} className="mr-2" />{" "}
                  <span className="font12">{modalData.name}</span>{" "}
                  <span className="font16">
                    {" "}
                    {Number(newTotalAmountSupplied ? newTotalAmountSupplied : 0).toFixed(4)}{" "}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Withdraw Limit</td>
              <td>
                <span className="font12">{modalData.name}</span>{" "}
                <span className="font16">
                  {" "}
                  {Number(modalData.withdrawLimit).toFixed(4)}{" "}
                </span>
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-end">
                  <img src={IcArrow} className="mr-2" />{" "}
                  <span className="font12">{modalData.name}</span>{" "}
                  <span className="font16">
                    {" "}
                    {Number(newTotalWithdrawLimit ? newTotalWithdrawLimit : 0).toFixed(4)}{" "}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function WithdrawTab({
  modalData,
  newTotalAmountSupplied,
  newTotalWithdrawLimit,
  setModalState,
  inputData,
  isSubmitting,
}) {
  return (
    <>
      <h4 className="table-head">SUPPLY RATE</h4>
      <div className="table-responsive">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td>
                <div className="d-flex align-items-center">
                  {modalData.name === "ETH" ? (
                    <img src={EitImg} alt="eth icon" />
                  ) : (
                    <img src={DollorImg} alt="dollar icon" />
                  )}
                  <h6 className="heading-sm mb-0 ml-2">{modalData.name} APY</h6>
                </div>
              </td>
              <td style={{ textAlign: "right" }}>{modalData.apy} %</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 className="table-head">SUPPLY BALANCE</h4>
      <div className="table-responsive">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td>Amount Supplied</td>
              <td>
                <span className="font12">{modalData.name}</span>{" "}
                <span className="font16">
                  {" "}
                  {Number(modalData.amountSupplied).toFixed(4)}{" "}
                </span>
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-end">
                  <img src={IcArrow} className="mr-2" />{" "}
                  <span className="font12">{modalData.name}</span>{" "}
                  <span className="font16">
                    {" "}
                    {Number(newTotalAmountSupplied ? newTotalAmountSupplied : 0).toFixed(4)}{" "}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Withdraw Limit</td>
              <td>
                <span className="font12">{modalData.name}</span>{" "}
                <span className="font16">
                  {" "}
                  {Number(modalData.withdrawLimit).toFixed(4)}{" "}
                </span>
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-end">
                  <img src={IcArrow} className="mr-2" />{" "}
                  <span className="font12">{modalData.name}</span>{" "}
                  <span className="font16">
                    {" "}
                    {Number(newTotalWithdrawLimit ? newTotalWithdrawLimit : 0).toFixed(4)}{" "}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
