import { useAsync } from 'react-use';
import { UserNftDataRow } from 'interfaces/interfaces';
import { useWeb3Context } from 'contexts/Web3ContextProvider';
import _ from "lodash";
import { openseaApiKey } from 'utils/secrets';

const getCollectionStats = async (slug: string) => {
    const options = {method: 'GET', headers: {Accept: 'application/json', 'X-API-KEY': openseaApiKey, Origin: '*'}};
    return fetch(`https://api.opensea.io/api/v1/collection/${slug}/stats`, options)
        .then(response => response.json())
        .then(response => {
            // collectionOpenseaPrice[slug] = response.stats.floor_price;
            // console.log('getCollectionStats', response)
            return response.stats.floor_price ?? 0
        })
        .catch(err => {
            console.error('ERROR: getCollectionStats', err)
            return 0;
        });
}

const getUniqueCollectionSlugs = (list: any[]) => {
    let tempList : string[] = [];
    list.forEach( item => {
        // console.log('getUniqueCollectionSlugs :', item, tempList.indexOf( item.slug), item.slug);
        if(tempList.indexOf(item.slug) === -1) tempList.push(item.slug);
    })
    // console.log('getUniqueCollectionSlugs::tempList :', tempList);
    return tempList;
}

export const useOpenseaData = () => {
      const { provider, contracts, userId, blockNumber } = useWeb3Context();

    //0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB
    const getOpenseaData = useAsync(async () => {
        const options = {
            method: 'GET',
            headers: {Accept: 'application/json', 'X-API-KEY': openseaApiKey}
        };
        const response = await fetch('https://api.opensea.io/api/v1/collection/cryptopunks/stats', options)
            .then(response => response.json())
            .then(response => {
                return response;
            })
            .catch(err => console.error(err));
        console.log('opensea api response :', response);
        return response;
      }, []);

    const userNftsFromOpensea = useAsync(async () => {
        if(!userId) return { status: false, data: []}

        const options = {
            method: 'GET',
            headers: {Accept: 'application/json', 'X-API-KEY': openseaApiKey}
          };
        
        //   cursor=LXBrPTI1NDA4MzQzMA==
        const response = await fetch(`https://api.opensea.io/api/v1/assets?order_direction=desc&limit=200&include_orders=false&owner=${userId}`, options)
        .then(response => response.json())
        .then(response => {
            // console.log('useOpenseaData: api raw data:', response)
            return {
                status: true,
                data: response.assets
            };
        })
        .catch(err => {
            console.error(err)
            return {
                status: false,
                data: err
            }
        });

        console.log('useOpenseaData: user nfts from opensea:', response.data);
        if(response.status){
            let tempList = response?.data?.map( (nft: any) => ({
                'id': nft.token_id,
                'contract_add': nft.asset_contract.address,
                'slug': nft.collection.slug,
                'name': nft.asset_contract.name,
                'url':  nft.image_url,
                'value': 0,
                'valueUsdc': 0,
                'deposited' : false,
                'approved' : false
            }))   
            
            //filter out punks
            let filteredList = tempList.filter( (nft : any) => nft.slug !== 'cryptopunks');
            tempList = filteredList;

            let collNames = getUniqueCollectionSlugs(tempList);

            let collValues = await Promise.all(collNames.map( (item : any) => (
                getCollectionStats(item)
            )))

            let tempListNew = tempList.map( (nft: any) => ({
                ...nft,
                'value': collValues[collNames.indexOf(nft.slug)],
                'valueUsdc': (Number(collValues[collNames.indexOf(nft.slug)]) * 2435).toFixed(2),
            }))   

            let sortedListByPrices = _.orderBy(tempListNew, ['value'], ['desc']);

            // console.log('useOpenseaData: sorted nfts with prices :', sortedListByPrices.length, sortedListByPrices);
            response.data = sortedListByPrices;
        }

        return response;
      }, [userId]);
    
      return [getOpenseaData, userNftsFromOpensea];
}
