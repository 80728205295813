import React from "react";

import styled from "styled-components";

import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { nftPoolItems } from "config/consts";

import NftPunkOne from "assets/punk-one.jpeg";

import { useNavigate } from "react-router-dom";
import { NftPoolItem } from "interfaces/interfaces";
import useNftFetch from "hooks/useNftFetch";
import SwitchImg from "assets/switch.svg";

import SectionTitleWrapper from "../../../components/SectionTitleWrapper";
import { GridView } from "./GridView";
import { TableView } from "./TableView";

import GridTableController from "components/GridTableController";

export const Collections = () => {
  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(1),
    supplyBalance: Number(1),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };

  const navigate = useNavigate();

  const poolSelectHandler = (poolName: string) => {
    console.log("pool selected..");
    navigate(`/${poolName}/inventory-details`);
  };

  const [view, setView] = React.useState<"table" | "grid">("grid");
  const Component = view === "grid" ? GridView : TableView;

  return (
    <div className="row">
      <div className="col-lg-12">
        <SectionTitleWrapper>
          <h4>Collections</h4>
          <div>
            <GridTableController view={view} setView={setView} />
          </div>
        </SectionTitleWrapper>

        <Component
          nftPoolItems={nftPoolItems}
          poolSelectHandler={poolSelectHandler}
          cryptoPunkItem={cryptoPunkItem}
          priceInEth={getNftPriceInEth()}
          nftPriceInUSDC={getNftPriceInUSDC()}
          totalNFTs={getTotalNFTs()}
        />
      </div>
    </div>
  );
};

const SwitchHeading = styled.span`
  // font-family: 'Epilogue';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text);
`;
