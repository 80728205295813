import { flatten, keys, kebabCase } from "lodash";

const rawData = [
  {
    slug: "engineering",
    title: "Engineering",
    topics: [
      {
        slug: kebabCase("Senior Full Stack Developer"),
        title: "Senior Full Stack Developer",
        content: (
          <>
            <p>
            Bunker.finance is the first NFT-backed lending protocol that uses a pooled model to instantly connect borrowers and lenders. Bunker is the easiest way to borrow against your NFTs!

            Built by DeFi veterans, Bunker.finance is VC-backed and looking to grow aggressively. We have a team of awesome humans from MIT, Yale, Google, Tesla, Microsoft, Nuro, Scale.ai, Google, Jane Street, Set Protocol, and Yield Protocol, and other great companies!
            </p>
            <ul>
              <li>Role: Senior Full Stack Developer</li>
              <li>Location: Miami or Remote</li>
              <li>Type of Employment: Full-time</li>
            </ul>
            <h4>Responsibilities</h4>
            <ul>
              <li>Develop the software and infrastructure required to fulfill our mission of bringing DeFi to NFTs</li>
              <li>Solve technical problems involving backend software engineering and user interface development</li>
              <li>Contribute to technical strategies, vision, and architecture</li>
              <li>Comfortable with our stack:
                <ul>
                  <li>Node.js</li>
                  <li>Ethers.js</li>                  
                  <li>React</li>
                  <li>TypeScript</li>
                  <li>GraphQL</li>
                </ul>
              </li>

            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>At least 2 years of experience developing production-grade full stack apps</li>
              <li>Advanced knowledge of TypeScript</li>
            </ul>
            <h4>Nice to have</h4>
            <ul>
              <li>Prior experience working on Ethereum DApps (or other EVM chains)</li>
              <li>Experience with Solidity</li>
              <li>Experience using DeFi lending protocols</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Competitive compensation</li>
              <li>Flexible working hours</li>
              <li>Health insurance</li>
            </ul>
          </>
        ),
      },

      {
        slug: kebabCase("Smart Contract Engineer"),
        title: "Smart Contract Engineer",
        content: (
          <>
            <p>
            Bunker.finance is the first NFT-backed lending protocol that uses a pooled model to instantly connect borrowers and lenders. Bunker is the easiest way to borrow against your NFTs!

            Built by DeFi veterans, Bunker.finance is VC-backed and looking to grow aggressively. We have a team of awesome humans from MIT, Yale, Google, Tesla, Microsoft, Nuro, Scale.ai, Google, Jane Street, Set Protocol, and Yield Protocol, and other great companies! 
            </p>
            <ul>
              <li>Role: Smart Contract Engineer</li>
              <li>Location: Miami or Remote</li>
              <li>Tyoe of Employment: Full-time</li>
            </ul>
            <h4>Responsibilities</h4>
            <ul>
              <li>Work as part of a team to develop new ways to unlock the value of NFTs within the DeFi ecosystem</li>
              <li>Design, develop, and test smart contracts for the next generation of the Bunker protocol</li>
              <li>Write well-documented, gas-efficient, clean Solidity code</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>2 years of experience writing Solidity smart contracts</li>
              <li>Experience deploying smart contracts on mainnet</li>
              <li>Self-driven, autonomous, personality with a desire to develop protocols that will have an impact</li>
            </ul>
            <h4>Nice to have:</h4>
            <ul>
              <li>Experience using or building on top of a DeFi lending protcol</li>
              <li>Experience with bonding curve mathematics</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Competitive compensation</li>
              <li>Flexible working hours</li>
              <li>Health insurance</li>
            </ul>
          </>
        ),
      },
    ],
  },

  {
    slug: "design",
    title: "Design",
    topics: [
      {
        slug: kebabCase("Senior UI/UX Designer"),
        title: "Senior UI/UX Designer",
        content: (
          <>
            <p>
            Bunker.finance is the first NFT-backed lending protocol that uses a pooled model to instantly connect borrowers and lenders. Bunker is the easiest way to borrow against your NFTs!

            Built by DeFi veterans, Bunker.finance is VC-backed and looking to grow aggressively. We have a team of awesome humans from MIT, Yale, Google, Tesla, Microsoft, Nuro, Scale.ai, Google, Jane Street, Set Protocol, and Yield Protocol, and other great companies!
            </p>
            <ul>
              <li>Role: Senior UI/UX Designer</li>
              <li>Location: Miami or Remote</li>
              <li>Tyoe of Employment: Full-time</li>
            </ul>
            <h4>Responsibilities</h4>
            <ul>
              <li>Conceive, manage, and drive UX design projects from problem definition to concept, to deliverables to implementation and dev QA.</li>
              <li>Determine the best UX solutions based on business roadmap and goals.</li>
              <li>Able to help to determine overall product design direction, identifying challenges and adapting as needed based on the context of the solution and customer.</li>
              <li>Build relationships and trust with team members, peers, and community members.</li>
              <li>Create and document new components, and contribute to the Bunker Design System when necessary.</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>Significant native mobile or web design experience</li>
              <li>Familiarity with Figma and Figma Design System</li>
              <li>A polished portfolio showcasing your work</li>
              <li>Exceptional design skills, creative problem-solving, a great sense of aesthetics, as well as user-centered design principles</li>
              <li>Proficiency in breaking down complex problems to find and develop simple and intuitive solutions</li>
              <li>Highly organized and detail oriented</li>
              <li>Works iteratively and thinks from the user's perspective</li>
              <li>Ability to clearly communicate their decisions and present their work to management and engineering</li>

            </ul>
            <h4>Nice to have:</h4>
            <ul>
              <li>Strong visual design skills</li>
              <li>Degree in UI/UX Design, Interaction Design, Human-Computer Interaction Design, Cognitive Science, Communication Design, Psychology, or Marketing</li>
              <li>Experience or interest in Blockchain, crypto, NFT's, or DeFi</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Competitive compensation</li>
              <li>Flexible working hours</li>
              <li></li>
            </ul>
          </>
        ),
      },
    ],
  },

  {
    slug: "admin",
    title: "Admin",
    topics: [
      {
        slug: "community-manager",
        title: "Community Manager",
        content: (
          <>
            <p>
            Bunker.finance is the first NFT-backed lending protocol that uses a pooled model to instantly connect borrowers and lenders. Bunker is the easiest way to borrow against your NFTs!

            Built by DeFi veterans, Bunker.finance is VC-backed and looking to grow aggressively. We have a team of awesome humans from MIT, Yale, Google, Tesla, Microsoft, Nuro, Scale.ai, Google, Jane Street, Set Protocol, and Yield Protocol, and other great companies!
            </p>
            <ul>
              <li>Role: Community Manager</li>
              <li>Location: Miami or Remote</li>
              <li>Tyoe of Employment: Full-time</li>
            </ul>
            <h4>Responsibilities</h4>
            <ul>
              <li>Engage regularly with customers and potential customers across channels to drive excitement about our mission and vision</li>
              <li>Shape and drive our community marketing strategy in collaboration with the Growth Manager. </li>
              <li>Manage our social media presence and strategy, as well as contribute to our brand voice and go-to-market campaigns. You will work closely with our Technical Writer and Design teams, as well as collaborate with leaders across the company, on content and campaigns.</li>
              <li>Partner with our Technical Writer and Design team to create compelling content across channels, including social media, Medium, website, and new ideas that you invent</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>The ideal candidate has a founder mindset, is highly creative, and has experience in the Web3 or startup space. You will be working with fund managers, founders, and investors and will be expected to understand the broader startup and venture ecosystem.</li>
              <li>An understanding of current trends, and passion for the latest cultural happenings in Web3 and NFTs</li>
              <li>Strong ownership mindset and emotional intelligence</li>
              <li>Ability to work autonomously and make decisions in a fast-changing environment</li>
            </ul>
            <h4>Nice to have:</h4>
            <ul>
              <li>Prior experience working as a Community Manager for a DeFi or NFT startup</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Competitive compensation</li>
              <li>Flexible working hours</li>
              <li>Health insurance</li>
            </ul>
          </>
        ),
      },
    ],
  },

  {
    slug: "marketing",
    title: "Marketing",
    topics: [
      {
        slug: kebabCase("Web3 Technical Writer"),
        title: "Web3 Technical Writer",
        content: (
          <>
            <p>
            Bunker.finance is the first NFT-backed lending protocol that uses a pooled model to instantly connect borrowers and lenders. Bunker is the easiest way to borrow against your NFTs!

            Built by DeFi veterans, Bunker.finance is VC-backed and looking to grow aggressively. We have a team of awesome humans from MIT, Yale, Google, Tesla, Microsoft, Nuro, Scale.ai, Google, Jane Street, Set Protocol, and Yield Protocol, and other great companies!
            </p>
            <ul>
              <li>Role: Web3 Technical Writer</li>
              <li>Location: Miami or Remote</li>
              <li>Type of Employment: Full-time</li>
            </ul>
            <h4>Responsibilities</h4>
            <ul>
              <li>Excellent writing, editing, and communication skills</li>
              <li>A knack for distilling information and crafting clear and engaging instructions</li>
              <li>Able to be strategic and detail oriented. You are detail-oriented, but also keep the bigger picture in mind, while working in an agile manner</li>
              <li>Independent thinker who figures out what’s needed and then gets to work!</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>Experience in professional technical writing</li>
              <li>Some degree of technical proficiency in code, blockchain, DeFi, and NFTs. You will be writing articles about these topics!</li>
            </ul>
            <h4>Nice to have:</h4>
            <ul>
              <li>You’ve been hanging around Web3 and NFT communities for a while and have existing relationships with devs or published content</li>
              <li>A Medium or social media presence</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Competitive compensation</li>
              <li>Flexible working hours</li>
              <li>Health insurance</li>
            </ul>
          </>
        ),
      },

      {
        slug: kebabCase("Growth Manager"),
        title: "Growth Manager",
        content: (
          <>
            <p>
            Bunker.finance is the first NFT-backed lending protocol that uses a pooled model to instantly connect borrowers and lenders. Bunker is the easiest way to borrow against your NFTs!

            Built by DeFi veterans, Bunker.finance is VC-backed and looking to grow aggressively. We have a team of awesome humans from MIT, Yale, Google, Tesla, Microsoft, Nuro, Scale.ai, Google, Jane Street, Set Protocol, and Yield Protocol, and other great companies!
            </p>
            <ul>
              <li>Role: Growth Manager</li>
              <li>Location: Miami or Remote</li>
              <li>Tyoe of Employment: Full-time</li>
            </ul>
            <h4>Responsibilities</h4>
            <ul>
              <li>Shape and drive our community marketing strategy. </li>
              <li>Manage Bunker’s social media strategy and presence, while organically growing our social following across all channels</li>
              <li>Stay on top of emerging trends in Web3 and NFTs and monitor digital presence of influential firms, companies, and individuals in our ecosystem</li>
              <li>Ideate, brainstorm, and execute on large-scale community-focused campaigns and events</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>At least 2 years experience in a growth role</li>
              <li>Proven work experience for a large, tech-focused growth campaigns</li>
              <li>Knowledge of key social media channels, a proven ability to use social platforms to amplify a brand, and a strong understanding of social media trends and analysis</li>
              <li>The ability to embrace ambiguity and experimentation in order to find the best channels to amplify the Bunker brand</li>
              <li>Excellent verbal and written communication skills</li>
              <li>Experience in launching community initiatives (e.g., building an online forum, leading an ambassador program).</li>
              <li>An understanding of current trends, and passion for the latest cultural happenings in Web3 and NFTs</li>
              <li>Self-starter and highly creative</li>
            </ul>
            <h4>Nice to have:</h4>
            <ul>
              <li>Work experience in crypto, DeFi, or NFT campaigns</li>
              <li>Network of social media influencers</li>
            </ul>
            <h5>Benefits</h5>
            <ul>
              <li>Competitive compensation</li>
              <li>Flexible working hours</li>
              <li>Health insurance</li>
            </ul>
          </>
        ),
      },
    ],
  },
];

const contentBySectionAndTopic = rawData.reduce((prev, section) => {
  prev[section.slug] = section.topics.reduce((p, topic) => {
    p[topic.slug] = { title: topic.title, content: topic.content };

    return p;
  }, {});

  return prev;
}, {});

const findNext = (section, topic) => {
  const links = flatten(
    keys(contentBySectionAndTopic).map((sectionSlug) =>
      keys(contentBySectionAndTopic[sectionSlug]).map(
        (topicSlug) => `${sectionSlug}/${topicSlug}`
      )
    )
  );

  const index = links.indexOf(`${section}/${topic}`);

  if (index < 0 || index + 1 >= links.length) return null;

  const nextPath = links[index + 1];

  return {
    path: nextPath,
    title:
      contentBySectionAndTopic[nextPath.split("/")[0]][nextPath.split("/")[1]]
        .title,
  };
};

export const copy = {
  title: "Careers",
  description:
    "Bunker.finance is hiring! If you believe you belong at Bunker but don't see a role below that fits your description, send us an email at careers@buker.finance",
  rawData,
  contentBySectionAndTopic,
  findNext,
  baseRoute: "careers",
};
