import { Link, useParams } from "react-router-dom";
import EitImg from "../../assets/ic-eth.svg";
import NftImgTwo from "../../assets/nft-two.png";
import EitImgRound from "../../assets/ic-eth-round.svg";

import DollorImg from "../../assets/ic-dollor.svg";

import { AppHeader } from "components/AppHeader/AppHeader";
import LendTableRow from "../../components/LendTableRow";
import {
  DashboardContainer,
  DetailsListWithPadding,
} from "../../components/DashboardStyledComponents";
import { ColoredHeading, TableWrapper, TabList } from "./styledComponents";
import { useEffect, useMemo, useState } from "react";
import useNftFetch from "hooks/useNftFetch";
import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { useNftContext } from "contexts/NftContextProvider";
import useLenderData from "hooks/useLenderData";
import { NftPoolItem, UserNftDataRow } from "interfaces/interfaces";
import { formatEther } from "@ethersproject/units";
import leftPad from "left-pad";
import useBorrowerData from "hooks/useBorrowerData";
import NftPunkOne from "assets/punk-one.jpeg";
import { useWeb3Context } from "contexts/Web3ContextProvider";
import { useLendCTokenData } from "hooks/useLendCTokenData";
import useUserNftData from "hooks/useUserNftData";
import Sidebar from "components/Sidebar";
import { DashboardNavMobile } from "components/DashboardNav";
import RightContainer from "components/RightContainer";

export const Lend = () => {
  const { selectedPool } = useParams();
  const { provider, contracts } = useWeb3Context();

  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const bunkerState = useBunkerState();
  const nftState = useNftContext();
  console.log("nftState:", nftState);
  console.log("bunkerState:", bunkerState);

  const [userNftData, nftValue] = useUserNftData();

  const [
    borrowBalanceDollars,
    borrowBalanceEth,
    collateralBalanceDollars,
    collateralBalanceEth,
    borrowLimitDollars,
    borrowLimitEth,
    liquidationThresholdDollars,
    liquidationThresholdEth,
    apr,
    borrowBalance,
    borrowLimit,
    cNftValue,
  ] = useBorrowerData();

  const [supplyBalanceEth, supplyBalanceDollars, supplyAPY, dailyInterest] =
    useLenderData();
  const [
    lendData,
    ethLimit,
    usdcLimit,
    totalLimit,
    handleSubmit,
    handleRequestApproval,
    modalState,
    setModalState,
  ] = useLendCTokenData();

  // console.log("------------", ethLimit, usdcLimit, totalLimit);

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(1),
    supplyBalance: Number(1),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };

  return (
    <>
      <AppHeader activeTab={"dashboard"} />

      <DashboardNavMobile />

      <DashboardContainer>
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex flex-wrap">
              <Sidebar>
                <div className="nft-details">
                  <div className="imgbox">
                    <img src={cryptoPunkItem.nftImage} />
                  </div>
                  <div className="imgtxt">
                    <h2>{cryptoPunkItem.nftName}</h2>
                    <div className="flex-center-inner">
                      <h6>Total Value Locked</h6>
                      <h4>
                        <img src={EitImg} />
                        {getTVL()}
                      </h4>
                    </div>
                  </div>
                </div>
              </Sidebar>

              <RightContainer>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <ColoredHeading>I want to</ColoredHeading>
                      <TabList>
                        <li>
                          <Link to={`/${selectedPool}/borrow`}>Borrow</Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedPool}/lend`}
                            className="activetab"
                          >
                            Lend
                          </Link>
                        </li>
                      </TabList>
                      <DetailsListWithPadding>
                        <li>
                          <h6>Supply Balance</h6>
                          <h1>
                            <img src={EitImg} />{" "}
                            {Number(
                              supplyBalanceEth.replace("ETH", "")
                            ).toFixed(2)}
                          </h1>
                        </li>
                        <li>
                          <h6>Daily Interest </h6>
                          <h1>
                            <img src={EitImg} /> {dailyInterest}
                          </h1>
                        </li>
                        <li>
                          <h6>Effective Supply APY</h6>
                          <h1>
                            <img src={EitImg} /> {supplyAPY}
                          </h1>
                        </li>
                        <li>
                          <h6>Withdrawal Limit</h6>
                          <h1>
                            <img src={EitImg} /> {Number(totalLimit)}
                          </h1>
                        </li>
                      </DetailsListWithPadding>
                    </div>
                  </div>

                  <div className="row">
                    <TableWrapper className="col-lg-12 mt-0">
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>Pool Assets</th>
                              <th>Pool Utilization</th>
                              <th>APY</th>
                              <th>Amount Supplied</th>
                              <th>Withdrawal Limit</th>
                              <th style={{ textAlign: "right" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {lendData &&
                              lendData.map(
                                (
                                  {
                                    name,
                                    utilization,
                                    apy,
                                    balanceDollars,
                                    balance,
                                    withdrawLimitDollars,
                                    withdrawLimit,
                                    withdrawLimitRaw,
                                    supplyLimitRaw,
                                  },
                                  key
                                ) => (
                                  <LendTableRow
                                    key={key}
                                    asset={
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={
                                            name === "ETH"
                                              ? EitImgRound
                                              : DollorImg
                                          }
                                        />{" "}
                                        <h5 className="heading-sm">{name}</h5>
                                      </div>
                                    }
                                    poolUtilization={utilization}
                                    apy={apy}
                                    amountSupplied={balanceDollars}
                                    withdrawLimit={withdrawLimitDollars}
                                    modalData={{
                                      apy: apy,
                                      name: name,
                                      amountSuppliedDollars: balanceDollars,
                                      amountSupplied: balance,
                                      withdrawLimitDollars:
                                        withdrawLimitDollars,
                                      withdrawLimit: withdrawLimit,
                                      withdrawLimitRaw: withdrawLimitRaw,
                                      supplyLimitRaw: supplyLimitRaw,
                                      // borrowLimitDollar: borrowLimitDollars,
                                      // borrowLimitRaw: borrowLimitRaw,
                                      // owedBalanceRaw: owedBalanceRaw,
                                    }}
                                  />
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </TableWrapper>
                  </div>
                </div>
              </RightContainer>
            </div>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
