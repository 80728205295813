import styled from "styled-components";
import { Container } from "@mui/material";

const StyledContainer = styled(Container)`
  padding-left: 80px;
  margin: 0;

  @media (max-width: 767px) {
    padding-left: 16px;
  }
`;

export default StyledContainer;
