import ReactModal from "react-modal";
import styled from "styled-components";

const StyledModal = styled(ReactModal)`
  width: 400px;
  max-width: 90%;
  background: var(--color-modal-background);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px;
`;

const Modal = ({ style = {}, ...props }) => (
  <StyledModal
    {...props}
    style={{
      overlay: { zIndex: 1000, backgroundColor: "rgba(0, 0, 0, 75%)" },
      ...style,
    }}
  />
);

export const ModalBody = styled.div`
  padding: 0;
  margin: 0;
  background: var(--color-modal-background);
  .center-para {
    width: 343px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--color-dark-blue);
    margin-bottom: 40px;
  }
  .inputarea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label-green {
      width: 52px;
      height: 32px;
      background: var(--color-tertiary-background);
      border-radius: 2px;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: var(--color-modal-highlight);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-wrap {
      width: calc(100% - 52px);
      display: flex;
      align-items: center;
    }
    .input-style {
      width: 200px;
      height: 56px;
      font-weight: 400;
      font-size: 48px;
      line-height: 56px;
      color: var(--color-text);
      margin: 0px 10px;
      background-color: var(--color-modal-background);
      text-align: center;
      border: 0;
      &:focus {
        outline: none;
      }
    }
  }
  .answer-output {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
    opacity: 0.5;
    margin: 20px 0 30px 0;
  }
  .tabs-wrap {
    .react-tabs__tab-list {
      border: 0;
      margin-bottom: 24px;
      .react-tabs__tab {
        width: 50%;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-info);
        padding-bottom: 10px;
        opacity: 0.7;
        &::after {
          display: none;
        }
      }
      .react-tabs__tab--selected {
        border: 0;
        border-bottom: 2px solid var(--color-turquoise-2);
        color: var(--color-text);
        opacity: 1;
        background-color: var(--color-modal-background);
      }
    }
  }
  td {
    font-size: 14px;
    padding: 0.75rem 0.25rem;
    color: var(--color-text-info);
  }
  .table-head {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-info);
    margin: 8px 3px;
    text-transform: uppercase;
  }
  .font12 {
    font-size: 12px;
  }
  .font16 {
    font-size: 16px;
    margin-left: 5px;
    color: var(--color-text);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 0 30px 0;
  border: 0;
  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--color-text);
    margin: 0;
    width: calc(100% - 12px);
    padding-top: 7px;
  }
  .closebtn {
    background: none;
    margin-left: auto;
    width: 12px;
    border: 0;
    padding: 0;
    text-align: right;
  }
`;

export const ModalFooter = styled.div`
  padding: 0;
  margin: 0;
  border: 0;
`;

export const ModalButton = styled.button`
  background: var(--color-primary);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 2px;
  width: 100%;
  padding: 12px 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-white);
  border: 0;
  margin: 0;

  &:disabled {
    background-color: var(--button-disabled-background);
    color: var(--button-disabled-text);
    font-weight: 700;
  }
`;

export const SuccessMessage = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-text-info);
  margin: 10px 0 40px 0;
`;

export const FailureMessage = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-error);
  margin-top: 10px;
`;

export default Modal;
