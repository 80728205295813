import React from "react";

import NftList from 'components/NftList';
import { PoolItemComponent } from 'components/PoolItemComponent/PoolItemComponent';

export const GridView = ({
  poolSelectHandler,
  nftPoolItems,
  cryptoPunkItem,
}) => {
  
  return (
    <NftList>
        <li onClick={() => poolSelectHandler("punk")}>
          <PoolItemComponent nftPoolItem={cryptoPunkItem} />
        </li>
        {nftPoolItems.map((nftPoolItem, key) => (
          <li key={key} style={nftPoolItem.isActive ? {} : { pointerEvents: "none"}}>
            <PoolItemComponent nftPoolItem={nftPoolItem} />
          </li>
        ))}
      </NftList>
  );
};

