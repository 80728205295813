import styled from "styled-components";

const LoanWrapper = styled.div`
  padding: 12px 12px 32px 12px;
  background: var(--color-background);

  ul {
    border: 1px solid var(--color-secondary-border);
    border-bottom: none;
  }

  .rangeimg {
    width: 100%;
  }

  .flex-slider-wrap {
    position: relative;
    .flex-slider {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      .flex-left {
        width: 140px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-text-info);
        padding-top: 5px;
        span {
          font-weight: 700;
          font-size: 14px;
          color: var(--color-text);
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .flex-middle {
        width: calc(100% - 220px);
        position: relative;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .flex-right {
        width: 80px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-text);
        text-align: right;
        padding-top: 5px;
        @media (max-width: 767px) {
          width: 100%;
          padding-top: 35px;
        }
      }
      input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 7px;
        border-radius: 30px;
        background: transparent;
        position: relative;
        background-image: linear-gradient(
          to right,
          var(--color-primary) 0%,
          var(--color-energy-yellow) ${(90 / 125) * 100 + "%"},
          var(--color-energy-yellow) ${(115 / 125) * 100 + "%"},
          var(--color-safety-origin) 99%
        );
      }
      input[type="range"]:focus {
        outline: 0;
      }
      input[type="range"]::-webkit-slider-thumb {
        display: none;
      }
      input[type="range"]::-moz-range-thumb {
        display: none;
      }
      input[type="range"]::-webkit-slider-runnable-track {
        background: transparent;
        width: 100%;
        height: 3em;
        padding: 0;
        border-radius: 2em;
      }
      input[type="range"]::-moz-range-track {
        background: transparent;
        width: 100%;
        height: 3em;
        padding: 0 1em;
        border-radius: 2em;
      }
    }
    .progress-you {
      position: absolute;
      bottom: -20px;
      font-size: 10px;
      color: var(--color-text-info);
      font-weight: 700;

      ::after {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid var(--triangle-color);
        border-left: 5px solid transparent;
        top: -10px;
        right: 0;
        transform: translateX(-50%);
      }
    }
    .progress-one {
      position: absolute;
      left: ${(75 / 125) * 100 + "%"};
      bottom: 15px;
      font-size: 10px;
      color: var(--color-text-info);
      font-weight: 700;
    }
    .progress-two {
      position: absolute;
      left: ${(100 / 125) * 100 + "%"};
      bottom: 15px;
      font-size: 10px;
      color: var(--color-text-info);
      font-weight: 700;
    }
    .progress-three {
      position: absolute;
      left: ${(125 / 125) * 100 + "%"};
      bottom: 15px;
      font-size: 10px;
      color: var(--color-text-info);
      font-weight: 700;
    }
  }

  .loan-listing {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 20px;
    li {
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
      > h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-info);
        margin: 9px 0px;
        @media (max-width: 767px) {
          text-align: center;
        }
      }
      h1 {
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        color: var(--color-title);
        margin: 0 0 30px 0;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          justify-content: center;
        }
        img {
          margin-right: 10px;
        }
      }
    }
  }
`;

export default LoanWrapper;
