import styled from "styled-components";

const Faq = styled.section`
  background: #f4f8fc;
  padding: 100px 0;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #18181b;
    margin: 0 0 40px 0;
    @media (max-width: 767px) {
      margin: 0 0 100px 0;
    }
  }
  p {
    text-align: left;
    width: 90%;
  }
  .accordian-wrap {
    margin: 0 auto;
    width: 700px;
    max-width: 80%;
    @media (max-width: 767px) {
      width: 100%;
    }
    h1 {
      width: 700px;
      max-width: 100%;
      padding: 30px 0;
      text-align: left;
      margin: 0;
      // box-shadow: inset 0px -1px 0px #E5E7EB;
      border-top: 1px solid #ccc;
      @media (max-width: 980px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    p {
      text-align: left;
    }
    .darkcolor {
      color: #18181b !important;
    }
  }
  .accordian-wrap div {
    width: 700px;
    max-width: 100%;
    background: #f4f8fc !important;
  }
`;

export default Faq;
