import _ from "lodash";

import { NftListThreeItemsWithBorder } from "components/NftList";
import AssetListItem from "../components/AssetListItem";
import EmptyState from "./EmptyState";

export const GridView = ({ data, deposit, redeem, requestApproval }) => {
  return (
    <NftListThreeItemsWithBorder>
      {_.map(
        data,
        ({ id, name, url, value, valueUsdc, deposited, approved }) => (
          <AssetListItem
            key={id}
            id={id}
            name={name}
            url={url}
            value={value}
            valueUsdc={valueUsdc}
            deposited={deposited}
            approved={approved}
            deposit={deposit}
            redeem={redeem}
            requestApproval={requestApproval}
          />
        )
      )}
      {!data && <div>Loading...</div>}
      {data && data.length === 0 && <EmptyState />}
    </NftListThreeItemsWithBorder>
  );
};
