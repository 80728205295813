import NftImgOne from "../assets/nft-one.png";
import NftImgTwo from "../assets/nft-two.png";
import NftImgThree from "../assets/nft-three.png";
import NftImgFour from "../assets/nft-four.png";
import NftPunkOne from "../assets/punk-one.jpeg";

export const nftPoolItems = [
  // {
  //   nftImage: NftImgOne,
  //   nftName: "Water Women",
  //   borrowBalance: 3.5,
  //   supplyBalance: 0,
  //   valueLocked: 23.128,
  //   nftDeposited: 18,
  //   supply: 17.29,
  //   borrow: 12.23
  // },
  {
    nftImage: NftImgTwo,
    nftName: "Mutant Ape Yacht Club",
    borrowBalance: 0,
    supplyBalance: 0,
    valueLocked: "0",
    nftDeposited: 0,
    supply: "0",
    borrow: "0",
    isActive: false,
    avgPrice: 0,
    quantity: 0,
  },
  {
    nftImage: NftImgThree,
    nftName: "MiLady",
    borrowBalance: 0,
    supplyBalance: 0,
    valueLocked: "0",
    nftDeposited: 0,
    supply: "0",
    borrow: "0",
    isActive: false,
    avgPrice: 0,
    quantity: 0,
  },
  {
    nftImage: NftImgFour,
    nftName: "Coolcats",
    borrowBalance: 0,
    supplyBalance: 0,
    valueLocked: "0",
    nftDeposited: 0,
    supply: "0",
    borrow: "0",
    isActive: false,
    avgPrice: 0,
    quantity: 0,
  },
];

export const inventoryAssets = [
  {
    assetName: "Crypto Punk",
    assetImage: NftPunkOne,
    oraclePrice: 2.0,
    itemsCount: 3,
    isDeposited: true,
  },
  {
    assetName: "Mutant Ape Yacht Club2",
    assetImage: NftImgTwo,
    oraclePrice: 2.0,
    itemsCount: 3,
    isDeposited: false,
  },
  {
    assetName: "Mutant Ape Yacht Club3",
    assetImage: NftImgTwo,
    oraclePrice: 2.0,
    itemsCount: 3,
    isDeposited: false,
  },
];
