import styled from "styled-components";

const LoanTerms = styled.section`
  padding: 100px 0;
  .loan-terms-flex {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 980px) {
      flex-direction: column-reverse;
    }
    .left-section {
      width: 50%;
      @media (max-width: 980px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .right-section {
      width: 50%;
      padding-left: 8%;
      @media (max-width: 980px) {
        width: 100%;
        padding-left: 0;
        padding-bottom: 60px;
      }
      h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #18181b;
        margin: 0 0 32px 0px;
        @media (max-width: 767px) {
          font-size: 24px;
          line-height: 36px;
          margin: 0 0 16px 0px;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #52525b;
        margin: 0 0 32px 0px;
        padding: 0;
        @media (max-width: 767px) {
          margin: 0 0 30px 0px;
        }
      }
      h6 {
        font-weight: 700;
        font-size: 14px;
        line-height: 200%;
        color: #ec4899;
        margin: 0 0 32px 0px;
      }
      .btn-flex {
        display: flex;
        @media (max-width: 767px) {
          justify-content: space-between;
        }
        .primary-btn {
          background: #01c5ba;
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
          border-radius: 4px;
          border: 0;
          min-width: 163px;
          min-height: 48px;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
          @media (max-width: 767px) {
            min-width: 140px;
            width: 48%;
          }
        }
        .secondary-btn {
          background: #07022b;
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
          border-radius: 4px;
          border: 0;
          min-width: 163px;
          min-height: 48px;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
          @media (max-width: 767px) {
            min-width: 140px;
            width: 48%;
          }
        }
      }
    }
  }
`;

export default LoanTerms;
