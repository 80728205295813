import React from 'react'
import { AppBar } from "@mui/material";
import styled from "styled-components";


import Logo from "assets/brand-black.svg";
import "./index.css";
import { Link, Outlet } from "react-router-dom";
import { useForceLight } from "utils/useDarkMode";

import Background from "assets/doc-background.png"


const CopyContext = React.createContext()
export const useCopy = () => React.useContext(CopyContext)

function Docs({copy}) {
  useForceLight();
  return (
    <CopyContext.Provider value={copy}>
      <Layout>
        <StyledAppBar color="transparent" elevation={0} position="static">
          <NavLink to="/">
            <img src={Logo} alt="logo" className="logo"/>
          </NavLink>
        </StyledAppBar>
        <Outlet />
      </Layout>
    </CopyContext.Provider>
  );
}

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-image: url(${Background});
  background-repeat: no-repeat;
`;

export const StyledAppBar = styled(AppBar)`
  padding: 32px 80px;

  @media (max-width: 767px) {
    padding: 25px 16px;
  }

  img.logo {
    width: 115px;
  }
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;

  cursor: pointer;

  &.active {
    color: #15cdfc;
  }
`;

export default Docs;
