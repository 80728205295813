import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { formatUnits } from "@ethersproject/units";
import Decimal from "decimal.js";

const useNftFetch = () => {
  const state = useBunkerState();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const getTotalNFTs = () => {
    if (!state.value) {
      return "??";
    }
    return state.value.cNFTTotalSupply.toNumber();
  };
  // totalCash + totalBorrow
  const getTotalSupply = () => {
    if (!state.value) {
      return "??";
    }
    const ethTotalSupplyUSD = state.value.cEtherTotalSupply.div(
      state.value.cUSDCPrice
    );
    const ethTotalSupplyUSDC = ethTotalSupplyUSD.mul("1000000");
    const usdcTotalSupplyUSDC = state.value.cUSDCTotalSupply;
    const totalCashUSDC = ethTotalSupplyUSDC.add(usdcTotalSupplyUSDC);
    const dec = new Decimal(formatUnits(totalCashUSDC, 6));

    return `$${formatter.format(dec.toNumber())}`;
  };

  const getTotalBorrow = () => {
    if (!state.value) {
      return "??";
    }
    const ethTotalBorrowUSD = state.value.cEtherTotalBorrow.div(
      state.value.cUSDCPrice
    );
    const ethTotalBorrowUSDC = ethTotalBorrowUSD.mul("1000000");
    const totalBorrowUSDC = ethTotalBorrowUSDC.add(
      state.value.cUSDCTotalBorrow
    );
    const dec = new Decimal(formatUnits(totalBorrowUSDC, 6));

    return `$${formatter.format(dec.toNumber())}`;
  };

  const getTVL = () => {
    if (!state.value) {
      return "??";
    }

    const ethTotalSupplyUSD = state.value.cEtherTotalSupply.div(
      state.value.cUSDCPrice
    );
    const ethTotalSupplyUSDC = ethTotalSupplyUSD.mul("1000000");
    const nftTotalSupplyUSDC = state.value.cNftPrice
      .mul(state.value.cNFTTotalSupply)
      .div(state.value.cUSDCPrice)
      .mul("1000000");

    const usdcTotalSupplyUSDC = state.value.cUSDCTotalSupply;
    const totalCashUSDC = ethTotalSupplyUSDC.add(usdcTotalSupplyUSDC);
    const totalValueLockedUSDC = totalCashUSDC.add(nftTotalSupplyUSDC);
    const dec = new Decimal(formatUnits(totalValueLockedUSDC, 6));
    return `$${formatter.format(dec.toNumber())}`;
  };

  const getNftPriceInUSDC = () => {
    if (!state.value) {
      return "??";
    }
    const nftTotalSupplyUSDC = Number(
      state.value.cNftPrice.div(state.value.cUSDCPrice)
    );
    return nftTotalSupplyUSDC;
  };

  const getNftPriceInEth = () => {
    if (!state.value) {
      return "??";
    }
    const nftTotalSupplyUSDC = Number(state.value.cNftPrice.div(1e9).div(1e9));
    return nftTotalSupplyUSDC;
  };

  return [
    getTotalNFTs, 
    getTotalSupply, 
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth
  ] as const;
};

export default useNftFetch;