import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import IcClose from "assets/ic-close.svg";
import EitImg from "assets/ic-eth.svg";
import EitImgRound from "assets/ic-eth-round.svg";
import DollorImg from "assets/ic-dollor.svg";

import IcArrow from "assets/ic-arrow.svg";
import Modal, { ModalBody, ModalButton, ModalFooter, ModalHeader } from "components/modal";
import { useBorrowCTokenData } from "hooks/useBorrowCTokenData";
import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { formatEther, formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import { utils } from "ethers";
import { getFixedNumber, getFixedNumber4 } from "utils/commons";

export function BorrowRepayModal({isOpen, handleClose, modalData}) {
  const bunkerState = useBunkerState();
  const [inputAmount, setInputAmount] = useState();
  const [borrowInputInDollar, setBorrowInputInDollar] = useState(0);
  const [borrowBalanceNew, setBorrowBalanceNew] = useState(0);
  const [borrowBalanceNew_repay, setBorrowBalanceNew_repay] = useState(0);
  const [selectedTab, setSelectedTab] = useState("Borrow");

  const [borrowLimitNew, setBorrowLimitNew] = useState(0);
  const [borrowLimitPercentageNew, setBorrowLimitPercentageNew] = useState(0);
  const [borrowLimitPercentageNew_repay, setBorrowLimitPercentageNew_repay] = useState(0);
  
  const [symbol, setSymbol] = useState('ETH');
  const [submitLabel, setSubmitLabel] = useState("");
  const [decimals, setDecimals] = useState(18);
  // const [needsApproval, setNeedsApproval] = useState(false);
  const [limit, setLimit] = useState(0);


  const [borrowData, modalState, setModalState, handleSubmit, handleRequestApproval] = useBorrowCTokenData();

  const ethPriceInUsdc = Number(BigNumber.from(1e9).mul(BigNumber.from(1e9)).div(bunkerState.value.cUSDCPrice))
  
  // 
  const borrowLimitInNative = modalData.name === 'ETH' ? 
      (Number(modalData.borrowLimitDollar.replace('$', ''))*100/ethPriceInUsdc)/100 
      : Number(modalData.borrowLimitDollar.replace('$', ''))
  
  const borrowBalanceInDollar = modalData.name === 'ETH' ?
      Number(modalData.borrowBalance)*ethPriceInUsdc : Number(modalData.borrowBalance)
  
  const borrowUsedPercentage = Number(modalData.borrowLimitDollar.replace('$', '')) === 0 ? 
      100 : borrowBalanceInDollar*100/Number(modalData.borrowLimitDollar.replace('$', ''));

  const borrowLimitUsedInit = Number(modalData.borrowLimitDollar.replace('$', ''))
  // let borrowLimitInNative = Number(modalData.borrowLimitDollar.replace('$', ''))*100/ethPriceInUsdc 

  const [max, toggleMax] = useToggle(false);
  const [submitState, setSubmitState] = useState("");

  useEffect(() => {
    setModalState([modalData.name, "Borrow", modalData.borrowLimitRaw]);
  },[]);
  
  useEffect(() => {

    if(!isNaN(inputAmount)){
      let newBorrowBal = Number(inputAmount) + Number(modalData.borrowBalance); //in native
      let newBorrowBal_repay = Number(modalData.borrowBalance) >= Number(inputAmount) ? 
        Number(modalData.borrowBalance) - Number(inputAmount) : 0 ; //in native

      let newBorrowLimitUsed = (borrowLimitInNative <= newBorrowBal) ? 100 : borrowLimitInNative*100/newBorrowBal;
      let newBorrowLimitUsed_repay = (borrowLimitInNative <= newBorrowBal_repay) ? 100 : newBorrowBal_repay*100/borrowLimitInNative;

      console.log('New Borrow limit :', borrowLimitInNative, newBorrowBal_repay);
      const borrowInputInDollarTemp = modalData.name === 'ETH' ?
        Number(inputAmount)*ethPriceInUsdc : Number(inputAmount)
      
      setBorrowBalanceNew(newBorrowBal);
      setBorrowBalanceNew_repay(newBorrowBal_repay);
      setBorrowLimitPercentageNew(newBorrowLimitUsed);
      setBorrowLimitPercentageNew_repay(newBorrowLimitUsed_repay);
      setBorrowInputInDollar(borrowInputInDollarTemp);
    }

  }, [inputAmount]);
  
  const needsApproval = (value) => (
    modalState?.[0] === "USDC" && value.gt(bunkerState.value.USDCallowance)
  )

  const amountParsed = useMemo(() => {
    if (max) return limit;
    try {
      return utils.parseUnits(inputAmount, decimals);
    } catch (e) {
      return null;
    }
  }, [inputAmount, decimals, limit, max]);
  
  const maxAmount = useMemo(() => {
    return utils.formatUnits(limit || 0, decimals);
  }, [limit, decimals]);

  //on tab selection
  useEffect(() => {
    setSymbol(modalState?.[0] ?? "ETH");
    setSubmitLabel(modalState?.[1] ?? "");
    setDecimals(modalState?.[0] === "ETH" ? 18 : 6);
    setLimit(modalState?.[2]);
    return () => {
      setSubmitLabel('');
    }
  }, [modalState])

  const onClose = () => setModalState(undefined);

  const handleModalClose = () => {
    setSubmitState(undefined);
    handleClose();
  }

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={handleModalClose}
        contentLabel="Borrow/Repay"
        modalData={modalData}
        ariaHideApp={false}
      >
        <ModalHeader>
          <h4>Borrow {modalData.name}</h4>
          <button className="closebtn" onClick={handleModalClose}><img src={IcClose} /></button>
        </ModalHeader>
        <ModalBody>
          <div className="inputarea">
            <span className="label-green" onClick={toggleMax}>Max</span>
            <span className="input-wrap">
            {
              modalData.name === "ETH"?
              <img src={EitImg} alt="eth icon"/>
              :
              <img src={DollorImg} alt="dollar icon"/>
              
            }
              <input type="text" placeholder={ borrowLimitInNative } 
                className="input-style" 
                onChange={(e) => setInputAmount(e.target.value)} 
                value={max ? maxAmount : inputAmount}
                disabled={max}
                // disabled={!borrowLimitUsedInit }
                />
            </span>
          </div>
          <div className="text-center">
            <h6 className="answer-output"> $ {borrowInputInDollar} </h6>
          </div>

          <div className="tabs-wrap">
            <Tabs>
              <TabList>
                <Tab onClick={() => {
                    setModalState([modalData.name, "Borrow", modalData.borrowLimitRaw]);
                    setSubmitState(undefined);
                }}>Borrow</Tab>
                <Tab onClick={() => {
                    setModalState([modalData.name, "Repay", modalData.owedBalanceRaw])
                    setSubmitState(undefined);
                }}>Repay</Tab>
              </TabList>

              <TabPanel>
                <h4 className="table-head">BORROW Rates</h4>
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                          {
                            modalData.name === "ETH"?
                            <img src={EitImg} alt="eth icon"/>
                            :
                            <img src={DollorImg} alt="dollar icon"/>
                            
                          }
                            <h6 className="heading-sm mb-0 ml-2">{modalData.name} APY</h6>
                          </div>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <span className="font16">{modalData.apy} %</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4 className="table-head">Borrow LIMIT</h4>
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>Borrow Balance</td>
                        <td>
                          <span className="font12">{modalData.name}</span>{" "}
                          <span className="font16"> { getFixedNumber(modalData.borrowBalance)} </span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <img src={IcArrow} className="mr-2" />{" "}
                            <span className="font12">{modalData.name}</span>{" "}
                            <span className="font16"> { getFixedNumber(borrowBalanceNew)}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Borrow Limit Used</td>
                        <td>
                          <span className="font16"> { getFixedNumber4(borrowUsedPercentage)} %</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <img src={IcArrow} className="mr-2" />{" "}
                            <span className="font16"> {getFixedNumber4(borrowLimitPercentageNew)} %</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>

              <TabPanel>
                <h4 className="table-head">BORROW Rates</h4>
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            {
                              modalData.name === "ETH" ?
                              <img src={EitImg} alt="eth icon"/>
                              :
                              <img src={DollorImg} alt="dollar icon"/>
                              
                            }
                            <h6 className="heading-sm mb-0 ml-2">{modalData.name} APY</h6>
                          </div>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <span className="font16">{modalData.apy} %</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4 className="table-head">Borrow LIMIT</h4>
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>Borrow Balance</td>
                        <td>
                          <span className="font12">{modalData.name}</span>{" "}
                          <span className="font16"> { getFixedNumber(modalData.borrowBalance) } </span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <img src={IcArrow} className="mr-2" />{" "}
                            <span className="font12">{modalData.name}</span>{" "}
                            <span className="font16"> { getFixedNumber(borrowBalanceNew_repay) }</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Borrow Limit Used</td>
                        <td>
                          <span className="font16"> { getFixedNumber4(borrowUsedPercentage) } %</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <img src={IcArrow} className="mr-2" />{" "}
                            <span className="font16"> { getFixedNumber4(borrowLimitPercentageNew_repay) } %</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 
                </div>
              </TabPanel>
                  
            </Tabs>
          </div>
        </ModalBody>
        <ModalFooter>
            {!submitState &&
              amountParsed &&
              !needsApproval(amountParsed) &&
              limit &&
              amountParsed.gt(limit) && (
                <span>
                  Max value is {formatUnits(limit, decimals).substring(0, 8)}
                </span>
            )}

            {!submitState && amountParsed && needsApproval(amountParsed) && (
                <ModalButton onClick={handleRequestApproval}>
                  Approve {modalData.name}
                </ModalButton>
            )}

            <ModalButton 
              disabled={
                !!submitState ||
                !amountParsed ||
                needsApproval(amountParsed) ||
                (limit && amountParsed.gt(limit))
              }
              onClick={async () => {
                setSubmitState("Submitting...");
                try {
                  await handleSubmit(amountParsed, max);
                  setSubmitState("Success!");
                  setTimeout(() => {
                    handleModalClose?.({}, "escapeKeyDown");
                  }, 1000);
                } catch (e) {
                  console.log(e);
                  setSubmitState("Failure!");
                }
              }}
            >
              {submitState || submitLabel}
            </ModalButton>

            {/* <div>{submitState}</div> */}
                
        </ModalFooter>
      </Modal>
  )
}
