import { useState } from "react";
import SupplyWithdrawModal from "components/modal/SupplyWithdrawModal";

export default function LendTableRow({
  asset,
  poolUtilization,
  apy,
  amountSupplied,
  withdrawLimit,
  modalData,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  
  return (
    <tr>
      <td>{asset}</td>
      <td>{poolUtilization}</td>
      <td>{apy}</td>
      <td>{amountSupplied}</td>
      <td>{withdrawLimit}</td>
      <td style={{ textAlign: "right" }}>
        <button className="btn-green" onClick={() => setModalIsOpen(true)}>Supply/Withdraw</button>
        {modalIsOpen && <SupplyWithdrawModal isOpen={modalIsOpen} handleClose={() => setModalIsOpen(false)} modalData={modalData}/>}
      </td>
    </tr>
  );
}
