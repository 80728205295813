import { formatEther } from '@ethersproject/units';
import React, { useCallback, useMemo } from 'react'
import leftPad from "left-pad";
import { useNftContext } from 'contexts/NftContextProvider';
import { useBunkerState } from 'contexts/BunkerStateContextProvider';
import { useWeb3Context } from 'contexts/Web3ContextProvider';
import { UserNftDataRow } from 'interfaces/interfaces';

const useUserNftData = () => {
    const { provider, contracts } = useWeb3Context();
    const bunkerState = useBunkerState();
    const nftState = useNftContext();
    // console.log('nftState:', nftState);
    // console.log('bunkerState:', bunkerState);

    const userNftData: UserNftDataRow[] | null = useMemo(() => {
        const formatter = Intl.NumberFormat("en", { notation: "compact" });
        if (!bunkerState.value || !nftState.value) return null;
        // console.log(nftState);
        const value =
          formatEther(bunkerState.value.cNftPrice).substring(0, 6) + " ETH";
        const valueUsdc =
          "$" +
          bunkerState.value.cNftPrice
            .mul(100)
            .div(bunkerState.value.cUSDCPrice)
            .toNumber() /
            100;
        const deposited: UserNftDataRow[] = nftState.value.deposited.map((nft) => ({
          ...nft,
          id: leftPad(nft.id, 3, "0"),
          value,
          valueUsdc,
          deposited: true,
        }));
        const owned: UserNftDataRow[] = nftState.value.owned.map((nft) => ({
          ...nft,
          id: leftPad(nft.id, 3, "0"),
          value,
          valueUsdc,
          deposited: false,
          approved: nft.forSale, // TODO
        }));
        return [...deposited, ...owned];
      }, [bunkerState.value, nftState]);

      const getNftPrice = () => {
        if (!bunkerState.value || !nftState.value) return [null, null];
        else {
            const valueInEth =
          formatEther(bunkerState.value.cNftPrice).substring(0, 6);
        const valueUsdc =
            bunkerState.value.cNftPrice
            .mul(100)
            .div(bunkerState.value.cUSDCPrice)
            .toNumber() /
            100;
        return [valueInEth, valueUsdc]
        }
      }

      const requestApproval = useCallback(
        async (id: string) => {
          if (!contracts || !provider) return;
          const signer = provider.getSigner();
          await contracts.CryptoPunksMarket.connect(
            signer
          ).offerPunkForSaleToAddress(id, 0, contracts.CPunk.address);
          nftState.refresh();
        },
        [contracts, nftState, provider]
      );

      const deposit = useCallback(
        async (id: string) => {
          if (!contracts || !provider) return;
          const signer = provider.getSigner();
          await contracts.CPunk.connect(signer).mint([id], [1]);
          nftState.refresh();
          bunkerState.refresh();
        },
        [contracts, nftState, provider]
      );

      const redeem = useCallback(
        async (id: string) => {
          if (!contracts || !provider) return;
          const signer = provider.getSigner();
          await contracts.CPunk.connect(signer).redeem([id], [1]);
          nftState.refresh();
          bunkerState.refresh();
        },
        [contracts, nftState, provider]
      );

      return [ userNftData, getNftPrice(), requestApproval, deposit, redeem ] as const;
}

export default useUserNftData
