import React from "react";
import Logo from "assets/brand-white.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <ListsContainer>
          <LogoWrapper>
            <img src={Logo} alt="logo" className="brandimg" />
          </LogoWrapper>

          <ul className="resources">
            <h2>Resources</h2>
            <li>
              <Link to="/docs">Docs</Link>
            </li>
            <li>
              <Link to="/terms">Terms</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/support">Support</Link>
            </li>
          </ul>
          <ul className="social">
            <h2>Social</h2>
            <li>
              <a
                target="_blank"
                href="https://discord.com/invite/hv2dZFD563"
                rel="noreferrer"
              >
                Discord
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/bunker_finance"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://medium.com/@bunker_finance"
                rel="noreferrer"
              >
                Medium
              </a>
            </li>

            <li>
              <a
                href="https://t.me/+fxDfq8mS-Wc1NTEx"
                rel="noreferrer"
                target="_blank"
              >
                Telegram
              </a>
            </li>

            <li>
              <a
                href="https://github.com/bunkerfinance"
                rel="noreferrer"
                target="_blank"
              >
                Github
              </a>
            </li>
          </ul>
        </ListsContainer>
        <ContactWrapper>
          <h2>Contact Us</h2>
          <a href="mailto:hello@bunker.finance">hello@bunker.finance</a>

          <ButtonLink href="mailto:hello@bunker.finance">Email us</ButtonLink>
        </ContactWrapper>
      </Container>
      <Copyright>
        Copyright © 2022 Bunker, Popcorn Technologies, Inc. All rights reserved.
      </Copyright>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background-color: #07022b;
  color: var(--color-white);

  ul {
    list-style: none;
  }

  a {
    color: inherit;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 20px 40px;
  flex-direction: column;
  align-items: center;

  @media (min-width: 960px) {
    padding: 70px 100px 130px;
    flex-direction: row;
    align-items: revert;
  }
`;

const ListsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 47px;
  column-gap: 80px;

  max-width: 250px;
  @media (min-width: 960px) {
    max-width: revert;
    column-gap: 120px;
  }

  ul {
    padding: 0;

    h2 {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.5;
    }

    li {
      margin-bottom: 8px;

      a {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }
`;

const LogoWrapper = styled.div`
  flex: 0 0 100%;

  img {
    width: 115px;
  }
`;

const ButtonLink = styled.a`
  font-family: "Encode Sans Semi Expanded";
  background: #01c5ba;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  border: 0;
  min-width: 160px;
  min-height: 50px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Copyright = styled.p`
  padding-bottom: 30px;
  text-align: center;
  margin-bottom: 0;
  font-size: 0.75rem;
`;

export default Footer;
