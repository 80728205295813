import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Header = styled.header`
  padding: 20px 100px;
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  z-index: 99;
  @media (max-width: 980px) {
    position: fixed;
    padding: 16px 0px;
    background: rgb(7 2 38);
    top: 30px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span img {
    width: 115px;
  }
`;

export const NavLink = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 80px;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

  @media (max-width: 1100px) {
    margin-left: 40px;
  }
  @media (max-width: 980px) {
    display: block;
    margin: 0 20px;
    padding: 20px 20px;
    color: #18181b;
    font-size: 20px;

    &:hover {
      text-decoration: none;
      color: #666;
    }
  }
`;

export const ExternalLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 80px;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  @media (max-width: 1100px) {
    margin-left: 40px;
  }
  @media (max-width: 980px) {
    display: block;
    margin: 0 20px;
    padding: 20px 20px;
    color: #18181b;
    font-size: 20px;

    &:hover {
      text-decoration: none;
      color: #666;
    }
  }
`;

const BaseNavMenu = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HiddenNavMenu = styled(BaseNavMenu)`
  @media (max-width: 980px) {
    display: block !important;
    width: 100%;
    position: fixed;
    top: 73px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    background: #fff;
    z-index: 9;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    padding-top: 40px;
  }
`;

const ShowNavMenu = styled(BaseNavMenu)`
  @media (max-width: 980px) {
    display: block !important;
    width: 100%;
    position: fixed;
    top: 73px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    background: #fff;
    z-index: 9;
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    padding-top: 40px;
  }
`;

export const NavMenu = ({ show = false, ...props }) => {
  const Component = show ? ShowNavMenu : HiddenNavMenu;

  return <Component {...props} />;
};

export const NavBtnLink = styled(NavLink)`
  background: #01c5ba;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  border: 0;
  min-width: 160px;
  min-height: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

  @media (max-width: 980px) {
    &:hover {
      text-decoration: none;
      color: #666;
    }
  }
`;

export const LogoForDesktop = styled.img`
  @media (max-width: 980px) {
    display: none;
  }
`;

export const LogoForMobile = styled.img`
  display: none;

  @media (max-width: 980px) {
    display: block;
  }
`;

export const Nav = styled.nav`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const CloseMenu = styled.span`
  img {
    width: 18px;
    margin-right: -10px;
  }
  cursor: pointer;
  color: #fff !important;
  @media (min-width: 980px) {
    display: none;
  }
`;
export const MenuIcon = styled.img`
  display: none;
  @media (max-width: 980px) {
    display: inline-block;
    cursor: pointer;
    color: #fff !important;
    position: absolute !important;
    top: -10px !important;
    right: 0px !important;
  }
`;
