import { useBunkerState } from 'contexts/BunkerStateContextProvider';
import React, { useMemo } from 'react'
import { BigNumber } from "@ethersproject/bignumber";
import { formatEther, formatUnits } from "@ethersproject/units";


const weiToDollars = (wei: BigNumber, cUSDCPrice: BigNumber): number => {
  return wei.mul(100).div(cUSDCPrice).toNumber() / 100;
};

const useBorrowerData = () => {
    const bunkerState = useBunkerState();
    const [
      borrowBalanceDollars,
      borrowBalanceEth,
      collateralBalanceDollars,
      collateralBalanceEth,
      borrowLimitDollars,
      borrowLimitEth,
      liquidationThresholdDollars,
      liquidationThresholdEth,
      apr,
      borrowBalance,
      borrowLimit,
      cNftValue,
    ]: [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      number,
      number,
      number,
    ] = useMemo(() => {
      if (!bunkerState.value)
        return [
          "...",
          "...",
          "...",
          "...",
          "...",
          "...",
          "...",
          "...",
          "...",
          0,
          0,
          0,
        ];
      const { cNftValue, liquidity, shortfall, cUSDCPrice, collateralFactor } =
        bunkerState.value;
      console.log('cNftValue, liquidity, shortfall:', cNftValue, liquidity, shortfall);
  
      const collateralizedAmountDollars = weiToDollars(cNftValue, cUSDCPrice);
      // console.log({ collateralFactor });
      // console.log({ collateralFactor }, cNftValue);
      const liquidationThreshold = cNftValue
        .mul(collateralFactor)
        .div(1e9)
        .div(1e9);
      const borrowLimit = cNftValue
        .mul(collateralFactor)
        .mul(4) // 80%
        .div(5)
        .div(1e9)
        .div(1e9);
      // console.log({ borrowLimit });
      // console.log({ liquidationThreshold });
      const borrowValue = liquidationThreshold.sub(liquidity);
      // console.log(weiToDollars(borrowLimit, cUSDCPrice));
      // console.log(weiToDollars(liquidationThreshold, cUSDCPrice));
      // console.log(weiToDollars(liquidity, cUSDCPrice));
      // console.log(weiToDollars(borrowValue, cUSDCPrice));
      // console.log(collateralizedAmountDollars);
  
      const borrowBalanceDollars = "$" + weiToDollars(borrowValue, cUSDCPrice);
      const borrowBalanceEth = formatEther(borrowValue);
      const collateralBalanceDollars = "$" + weiToDollars(cNftValue, cUSDCPrice);
      const collateralBalanceEth = formatEther(cNftValue);
      const borrowLimitDollars = "$" + weiToDollars(borrowLimit, cUSDCPrice);
      const borrowLimitEth = formatEther(borrowLimit);
      const liquidationThresholdDollars =
        "$" + weiToDollars(liquidationThreshold, cUSDCPrice);
      const liquidationThresholdEth = formatEther(liquidationThreshold);
  
      const { cEtherOwed, cUsdcOwed, cEtherBorrowAprRaw, cUSDCBorrowAprRaw } =
        bunkerState.value;
      const etherOwedDollars =
        cEtherOwed.mul(100).div(cUSDCPrice).toNumber() / 100;
      const usdcOwedDollars = cUsdcOwed.toNumber() / 1e6;
  
      const apr =
        (etherOwedDollars + usdcOwedDollars > 0
          ? (cEtherBorrowAprRaw * etherOwedDollars +
              cUSDCBorrowAprRaw * usdcOwedDollars) /
            (etherOwedDollars + usdcOwedDollars)
          : Math.min(cEtherBorrowAprRaw, cUSDCBorrowAprRaw)
        ).toFixed(2) + "%";
  
      return [
        borrowBalanceDollars,
        borrowBalanceEth,
        collateralBalanceDollars,
        collateralBalanceEth,
        borrowLimitDollars,
        borrowLimitEth,
        liquidationThresholdDollars,
        liquidationThresholdEth,
        apr,
        borrowValue.div(1e9).toNumber() / 1e9,
        borrowLimit.div(1e9).toNumber() / 1e9,
        cNftValue.div(1e9).toNumber() / 1e9,
      ];
    }, [bunkerState.value]);

    return [
      borrowBalanceDollars,
      borrowBalanceEth,
      collateralBalanceDollars,
      collateralBalanceEth,
      borrowLimitDollars,
      borrowLimitEth,
      liquidationThresholdDollars,
      liquidationThresholdEth,
      apr,
      borrowBalance,
      borrowLimit,
      cNftValue,
    ]
}

export default useBorrowerData