import React from "react";

import { TableWrapper } from "views/Dashboard/styledComponents";
import { GreenLabel } from "components/AppHeader/components";

import EitImg from "assets/ic-eth.svg";
import QualityImg from "assets/quantity.svg";

export const TableView = ({
  nftPoolItems,
  poolSelectHandler,
  cryptoPunkItem,
  priceInEth,
  nftPriceInUSDC,
  totalNFTs,
}) => {
  return (
    <TableWrapper>
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Collection</th>
              <th>Average Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => poolSelectHandler("punk")}
              >
                <div
                  className={
                    cryptoPunkItem.isActive ? "imgbox" : "imgbox fade-image"
                  }
                >
                  <img
                    src={cryptoPunkItem.nftImage}
                    alt={cryptoPunkItem.nftName}
                    style={{
                      width: 50,
                      height: 50,
                      objectFit: "cover",
                      marginRight: 10,
                      display: "inline-block",
                    }}
                  />
                </div>
                <h4 style={{ margin: 0 }}>{cryptoPunkItem.nftName}</h4>
              </td>
              <td>
                <img src={EitImg} /> {priceInEth}{" "}
                <span>($ {nftPriceInUSDC})</span>
              </td>
              <td>
                <img src={QualityImg} /> x{totalNFTs}
              </td>
            </tr>

            {nftPoolItems.map((nftPoolItem, key) => (
              <TableRow key={key} item={nftPoolItem} />
            ))}
          </tbody>
        </table>
      </div>
    </TableWrapper>
  );
};

const TableRow = ({ item, style, ...props }) => {
  const extraStyle = "onClick" in props ? { cursor: "pointer" } : { pointerEvents: "none"};

  return (
    <tr {...props} style={{ ...style, ...extraStyle }}>
      <td style={{ display: "flex", alignItems: "center" }}>
        <div className={item.isActive ? "imgbox" : "imgbox fade-image"}>
          <img
            src={item.nftImage}
            alt={item.nftName}
            style={{
              width: 50,
              height: 50,
              objectFit: "cover",
              marginRight: 10,
              display: "inline-block",
            }}
          />
        </div>

        <h4 style={{ margin: 0 }}>{item.nftName}</h4>
      </td>
      {item.isActive ? (
        <>
          <td>
            <img src={EitImg} /> {item.avgPrice} <span>(0)</span>
          </td>
          <td>
            <img src={QualityImg} /> x {item.quantity}
          </td>
        </>
      ) : (
        <>
          <td>
            <GreenLabel>Coming Soon...</GreenLabel>
          </td>
          <td></td>
        </>
      )}
    </tr>
  );
};
