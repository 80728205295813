import IcClose from "assets/ic-close.svg";
import IcArrow from "assets/ic-arrow.svg";
import Modal, {
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "components/modal";
import useBorrowerData from "hooks/useBorrowerData";
import useUserNftData from "hooks/useUserNftData";
import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { UserNftDataRow } from "interfaces/interfaces";
import { useEffect, useState } from "react";

interface NftData {
  name: string,
  id: string | number,
  deposited: boolean,
  approved: boolean | undefined,
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  nftData: NftData
}

export default function UnwrapCollateralModal({ isOpen, handleClose, nftData }: Props) {
  const bunkerState = useBunkerState();
  const [nftDataNew, setNftDataNew] = useState<NftData>(nftData);

  const [
    borrowBalanceDollars,
    borrowBalanceEth,
    collateralBalanceDollars,
    collateralBalanceEth,
    borrowLimitDollars,
    borrowLimitEth,
    liquidationThresholdDollars,
    liquidationThresholdEth,
    apr,
    borrowBalance,
    borrowLimit,
    cNftValue,
  ] = useBorrowerData();

  
  const [myassetsData, nftPrice, requestApproval, deposit, redeem] = useUserNftData();
  
  useEffect(() => {
    let nftDataObj = myassetsData?.filter( nft => nft.id === nftData.id);
    let nftObj = nftDataObj?.[0];
    if(nftObj) setNftDataNew({
      name: nftObj.name,
      id: nftObj.id,
      deposited: nftObj.deposited,
      approved: nftObj.approved
    });
    console.log('Updated NFT Data of selected NFT:', nftObj);

  }, [myassetsData, nftData])
  
  console.log('NFT Data of selected NFT:', nftDataNew);
  
  const handleModalClose = () => {
    // setSubmitState(undefined);
    handleClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Unwrap Collateral"
      ariaHideApp={false}
    >
      <ModalHeader>
        <h4>Unwrap Collateral</h4>
        <button className="closebtn" onClick={handleModalClose}>
          <img src={IcClose} />
        </button>
      </ModalHeader>
      <ModalBody>
        <p className="center-para">
          Unwrapping collateral will return the NFT to your wallet and remove it
          from the assets pool
        </p>

        <div className="table-responsive">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <h6 className="heading-sm mb-0 ml-2">Asset Pool</h6>
                  </div>
                </td>
                <td style={{ textAlign: "right" }}>
                  <span className="font16">{nftData.name}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <h6 className="heading-sm mb-0 ml-2">Borrow Limit</h6>
                  </div>
                </td>
                <td style={{ textAlign: "right" }}>
                  <div className="d-flex align-items-center justify-content-end">
                    <span className="font16"> {Number(borrowLimitEth).toFixed(4)} </span>{" "}
                    <img src={IcArrow} className="mr-4 ml-4" />{" "}
                    <span className="font12">ETH</span>{" "}
                    <span className="font16"> {Number(borrowLimitEth).toFixed(4)} </span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <h6 className="heading-sm mb-0 ml-2">Borrow Limit Used</h6>
                  </div>
                </td>
                <td style={{ textAlign: "right" }}>
                  <div className="d-flex align-items-center justify-content-end">
                    <span className="font16"> { borrowLimitEth>0 ? (Number(borrowBalanceEth)*100/Number(borrowLimitEth)).toFixed(4) : 0 } % </span>{" "}
                    <img src={IcArrow} className="mr-4 ml-4" />{" "}
                    <span className="font16"> {borrowLimitEth > 0 ? (Number(borrowBalanceEth)*100/Number(borrowLimitEth)).toFixed(4) : 0} %</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
          {/* <ModalButton>Unwrap</ModalButton> */}
            {!nftDataNew.deposited && !nftDataNew.approved && (
              <ModalButton
                disabled={nftDataNew.approved}
                onClick={async () => {
                  requestApproval(String(nftDataNew.id));
                }}
              >
                {!nftDataNew.approved ? "Approve" : "Authorized"}
              </ModalButton>
            )}
            {!nftDataNew.deposited && (
              <ModalButton
                disabled={!nftDataNew.approved}
                onClick={async () => {
                  await deposit(String(nftDataNew.id));
                  setTimeout(() => {
                    handleModalClose?.();
                  }, 2000);
                }}
              >
                Deposit
              </ModalButton>
            )}
            {nftDataNew.deposited && (
              <ModalButton
                onClick={async () => {
                  await redeem(String(nftDataNew.id));
                  setTimeout(() => {
                    handleModalClose?.();
                  }, 2000);
                }}
              >
                Unwrap
              </ModalButton>
            )}
      </ModalFooter>
    </Modal>
  );
}
