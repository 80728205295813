import { FormControl, Select } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useCopy } from "../index";

export default function SelectRoute() {
  const { rawData: data, baseRoute } = useCopy();

  const { sectionSlug, topicSlug } = useParams();

  const navigate = useNavigate();

  return (
    <FormControl sx={{ maxWidth: 300 }}>
      <Select
        native
        defaultValue={`${sectionSlug}/${topicSlug}`}
        id="select-doc-route"
        onChange={({ target: { value } }) => navigate(`/${baseRoute}/${value}`)}
      >
        {data.map((section) => (
          <optgroup label={section.title} key={section.slug}>
            {section.topics.map((topic) => (
              <option value={`${section.slug}/${topic.slug}`} key={topic.slug}>
                {topic.title}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
    </FormControl>
  );
}
