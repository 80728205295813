import { GreenLabel } from "components/AppHeader/components";
import { NftPoolItem } from "interfaces/interfaces";
import React from "react";
import { getFixedNumber4 } from "utils/commons";
import EitImg from "../../assets/ic-eth.svg";
import NftImgOne from "../../assets/nft-one.png";

export const PoolItemComponent: React.FC<{ nftPoolItem: NftPoolItem }> = ({
  nftPoolItem,
}) => {
  return (
    <>
      <div className="no-details">
        <div className={nftPoolItem.isActive ? "imgbox" : "imgbox fade-image"}>
          <img src={nftPoolItem.nftImage} />
        </div>
        <div className="imgtxt">
          <h4>{nftPoolItem.nftName}</h4>
          {nftPoolItem.isActive ? (
            <div className="flex-center">
              <div className="flex-center-inner">
                <h6>Borrow Balance</h6>
                <h1>
                  <img src={EitImg} /> {getFixedNumber4(nftPoolItem.borrowBalance)}
                </h1>
              </div>
              <div className="flex-center-inner flex-end">
                <h6>Supply Balance</h6>
                <h1>
                  <img src={EitImg} /> {nftPoolItem.supplyBalance}
                </h1>
              </div>
            </div>
          ) : (
            <div className="flex-center">
              <div className="flex-center-inner">
                <GreenLabel>Coming Soon...</GreenLabel>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="full-details">
        <span className="dashed-separator" />
        <div className="dflex-between">
          <h6>Value Locked</h6>
          <h5>{nftPoolItem.valueLocked}</h5>
        </div>
        <div className="dflex-between">
          <h6>NFTs Deposited</h6>
          <h5>{nftPoolItem.nftDeposited}</h5>
        </div>
        <div className="dflex-between">
          <h6>Supply</h6>
          <h5> {nftPoolItem.supply} </h5>
        </div>
        <div className="dflex-between">
          <h6>Borrow</h6>
          <h5> {nftPoolItem.borrow} </h5>
        </div>
      </div>
    </>
  );
};

export default PoolItemComponent;
