
import React from "react";
import PunkMonkey from "views/LandingPage/images/Illustration-hero.png";
import More from "views/LandingPage/images/More.svg";
import 'react-tabs/style/react-tabs.css';
import { HeroSection, Container, HeroLeftSide, HeroRightSide, HeroButton } from "./components";
import { Link } from 'react-router-dom';


export default function HeroSectionComponent() {
  return (
    <HeroSection>
        <Container>
          <HeroLeftSide>
            <img src={PunkMonkey} alt="punk-nft" />
            <img src={More} className="more" />
          </HeroLeftSide>
          <HeroRightSide>
            <h1>Get a loan using your <span>NFTs</span></h1>
            <p>Bunker is the easiest way to borrow against your&nbsp;NFTs</p>
            <Link to="/dashboard"><HeroButton>Launch App</HeroButton></Link>
          </HeroRightSide>
        </Container>
      </HeroSection>
  )

}
