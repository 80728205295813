import { AppHeader } from "components/AppHeader/AppHeader";
import { useBunkerState } from "contexts/BunkerStateContextProvider";

import NftPunkOne from "assets/punk-one.jpeg";

import { useNavigate } from "react-router-dom";
import { NftPoolItem } from "interfaces/interfaces";
import useNftFetch from "hooks/useNftFetch";
import {
  DashboardContainer,
  DetailsList,
} from "../../../components/DashboardStyledComponents";
import { DashboardNavMobile } from "components/DashboardNav";
import { Collections } from "./Collections";

export const Inventory = () => {
  const state = useBunkerState();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(1),
    supplyBalance: Number(1),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };

  return (
    <>
      <AppHeader activeTab={"inventory"} />

      <DashboardNavMobile />

      <DashboardContainer>
        <div className="row">
          <div className="col-lg-12">
            <h2>Overview</h2>
            <DetailsList>
              <li>
                <h6>Collections</h6>
                <h1>1</h1>
              </li>
              <li>
                <h6>Quantity</h6>
                <h1>{getTotalNFTs()}</h1>
              </li>
              <li>
                <h6>Total value</h6>
                {/* <h1><img src={EitImg} /> 51.25</h1> */}
                <h1>{getTVL()}</h1>
              </li>
              <li>
                <h6>Collateral</h6>
                <h1>{getTotalNFTs()}</h1>
              </li>
            </DetailsList>
          </div>
        </div>

        <Collections />
      </DashboardContainer>
    </>
  );
};
