import { flatten, keys } from "lodash";

const rawData = [
  {
    slug: "overview",
    title: "Overview",
    topics: [
      {
        slug: "wrapping-and-unwrapping-bNFTs",
        title: "Wrapping and Unwrapping bNFTs",
        content: (
          <>
            <p>
              NFTs deposited to Bunker are represented as bNFTs. To mint a bNFT,
              visit the pools page, and select the appropriate pool. You should
              see all of your NFTs belonging to the specified collection in the
              ‘Collateral’ section. Simply click on the ‘Wrap’ button to wrap
              your NFT as a bNFT.
            </p>
            <p>
              While a bNFT is being used as collateral for a loan, it cannot be
              transferred from your wallet until the loan is fully repaid. When
              the bNFT is not actively being used as collateral, it is freely
              transferable.
            </p>
          </>
        ),
      },

      {
        slug: "about-btokens",
        title: "About bTokens",
        content: (
          <>
            <p>
              When you deposit ETH or ERC-20 tokens into Bunker pools, you will
              receive bTokens. All bTokens start with a 1:1 peg to the
              underlying currency, and increase in value relative to the
              underlying as the lending pool accrues interest.
            </p>
            <p>
              For example, if you deposit 1 ETH into the Punks pool, you will
              intially receive 1 bETH-PUNK. After some interest is accrued and
              you redeem your 1 bETH-PUNK, you will receive {">"}1 ETH (eg. 1.03
              ETH).
            </p>
          </>
        ),
      },

      {
        slug: "how-to-borrow",
        title: "How to borrow",
        content: (
          <>
            <p>
              Visit the pools page and select the lending pool for the NFT
              collection you wish to borrow against. Mint the necessary bNFTs to
              use as collateral, and click on the ‘Borrow’ page and follow the
              prompts to take out a loan.
            </p>
          </>
        ),
      },

      {
        slug: "how-to-supply",
        title: "How to supply",
        content: (
          <>
            <p>
              Visit the pools page and select the lending pool for the NFT
              collection you wish to lend against. Select the currency you wish
              to supply, and follow the on-screen prompts to deposit your tokens
              and receive bTokens.
            </p>
          </>
        ),
      },
    ],
  },
  {
    slug: "liquidation",
    title: "Liquidation & Utilization",
    topics: [
      {
        slug: "how-liquidations-work",
        title: "How do liquidations work?",
        content: (
          <>
            <p>
            A liquidation occurs when the value of your borrowed assets (borrow balance) is greater than your borrowing capacity (account liquidity). This can happen when your collateral drops in price or when the borrowed asset rapidly rises in price.
            </p>
            <p>
            If this event occurs, liquidators will be competing to liquidate your position. Liquidations are necessary to eliminate risks to the protocol. In the process the loan is going to be repaid back by the liquidator. The liquidation process will continue until the value of your borrowing is back below your borrowing capacity (account liquidity).
            </p>
          </>
        ),
      },

      {
        slug: "loan-utilization",
        title: "How do I track my loan utilization?",
        content: (
          <>
            <p>
              You can view the loan utilization in the pool page for the collection you are using as collateral. Your utlization is displayed as a percentage. 100% is the maximum you can borrow, and the maximum percentage (125% for CryptoPunks) is the utilization at which your position will get liquidated. 
            </p>
            <p>
              Utilization calculation math goes here
            </p>
          </>
        ),
      },
    ],
  }
];

export const contentBySectionAndTopic = rawData.reduce((prev, section) => {
  prev[section.slug] = section.topics.reduce((p, topic) => {
    p[topic.slug] = { title: topic.title, content: topic.content };

    return p;
  }, {});

  return prev;
}, {});

export const findNext = (section, topic) => {
  const links = flatten(
    keys(contentBySectionAndTopic).map((sectionSlug) =>
      keys(contentBySectionAndTopic[sectionSlug]).map(
        (topicSlug) => `${sectionSlug}/${topicSlug}`
      )
    )
  );

  const index = links.indexOf(`${section}/${topic}`);

  if (index < 0 || index + 1 >= links.length) return null;

  const nextPath = links[index + 1];

  return {
    path: nextPath,
    title: contentBySectionAndTopic[nextPath.split("/")[0]][nextPath.split("/")[1]].title,
  };
};

export const copy = {
  title: "Bunker Documentation",
  description:
    "Bunker is a platform for NFT-backed loans. As a borrower, you can use any supported NFT as collateral for a loan of ETH or ERC-20 tokens. As a lender, you can deposit ETH or ERC-20 tokens into a lending pool for any supported NFT collection and immediately start earning interest.",
  rawData,
  contentBySectionAndTopic,
  findNext,
  baseRoute: "docs"
};


