import { useState } from "react";

import SectionTitleWrapper from "components/SectionTitleWrapper";
import GridTableController from "components/GridTableController";
import { GridView } from "./GridView";
import { TableView } from "./TableView";

export const Assets = ({
  data,
  deposit,
  redeem,
  requestApproval,
}) => {
  const [view, setView] = useState("grid");

  const Component = view === "grid" ? GridView : TableView;

  return (
    <div className="row">
      <div className="col-lg-12">
        <SectionTitleWrapper>
          <h4>Your Assets</h4>
          <div>
            <GridTableController view={view} setView={setView} />
          </div>
        </SectionTitleWrapper>

        <Component
          data={data}
          deposit={deposit}
          redeem={redeem}
          requestApproval={requestApproval}
        />
      </div>
    </div>
  );
};
