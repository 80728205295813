import styled from "styled-components";

const Protocol = styled.section`
  padding: 100px 0 0 0;

  padding-bottom: ${props => props.withPaddingBottom ? "100px" : "0"};

  @media (max-width:767px) {
      padding-top: ${props => props.withPaddingBottom ? "0" : "100px"};
      padding-bottom: ${props => props.withPaddingBottom ? "50px" : "0"};
  }

  h1 {
    width: 647px;
    max-width: 100%;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #18181b;
    position: relative;
    margin: 0 auto 130px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 45px;
      margin: 0 auto 80px;
    }
    &::before {
      content: "";
      width: 84px;
      height: 4px;
      background: #01c5ba;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -20px;
    }
  }
  .protocol-flex {
    display: flex;
    flex-wrap: wrap;
    .left-section {
      width: 50%;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 980px) {
        width: 100%;
      }
      h2 {
        width: 500px;
        max-width: 100%;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #18181b;
        margin: 0 0 32px 0px;
        text-align: left;
        @media (max-width: 767px) {
          font-size: 24px;
          line-height: 36px;
          width: 100%;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #52525b;
        margin: 0 0 32px 0px;
        padding: 0;
        text-align: left;
        width: 85%;
        @media (max-width: 767px) {
          margin: 0 0 30px 0px;
          line-height: 30px;
        }
      }
    }
    .right-section {
      width: 50%;
      @media (max-width: 980px) {
        width: 100%;
      }
      img {
        max-width: 100%;
      }
    }
  }
`;

export default Protocol;
