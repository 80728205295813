import { Web3Provider } from "@ethersproject/providers";
import _ from "lodash";
import RawHardhatExport from "./hardhat_export.json";
import RawHardhatProdExport from "./hardhat_prod_export.json";
import CryptoPunksMarketABI from "./CryptoPunksMarketABI.json";
import CryptoPunksImagesABI from "./CryptoPunksImagesABI.json";
import NFTPriceOracleABI from "./NFTPriceOracleABI.json";
import MaximillionABI from "./Maximillion.json";

import ERC20ABI from "./ERC20ABI.json";
import { Contract } from "@ethersproject/contracts";

const extraContracts = {
  CryptoPunksMarket: {
    address: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    abi: CryptoPunksMarketABI,
  },

  CryptoPunksImages: {
    address: "0x16F5A35647D6F03D5D3da7b35409D65ba03aF3B2",
    abi: CryptoPunksImagesABI,
  },
  USDC: {
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    abi: ERC20ABI,
  },
  NftPriceOracle: {
    address: "0x194D6937f338AE5040dc77399776d4F4d98F02AD",
    abi: NFTPriceOracleABI,
  },
  Maximillion: {
    address: "0xf859A1AD94BcF445A406B892eF0d3082f4174088",
    abi: MaximillionABI,
  }
} as const;

const HardhatExport = {
  ...RawHardhatExport,
  contracts: {
    ...RawHardhatExport.contracts,
    ...extraContracts,
  },
} as const;

const HardhatProdExport = {
  ...RawHardhatProdExport,
  contracts: {
    ...RawHardhatProdExport.contracts,
    ...extraContracts,
  },
} as const;
type IHardhatExport = typeof HardhatExport;
const hardhatExports: IHardhatExport[] = [
  HardhatExport,
  HardhatProdExport as IHardhatExport,
  // TODO HardhatRinkebyExport
];
export const chainIds = hardhatExports.map((h) => Number(h.chainId));

export type ContractName = keyof IHardhatExport["contracts"];
export const contractNames: ContractName[] = _.keys(
  HardhatExport.contracts // this better be in sync w/ remote!
) as any;

export const getContract = (
  contractName: ContractName,
  provider: Web3Provider
) => {
  const exportIdx = chainIds.indexOf(provider.network?.chainId);
  if (exportIdx === -1) throw new Error("Invalid provider");
  const contracts = hardhatExports[exportIdx].contracts;
  if (!contracts[contractName]) throw new Error("Invalid contract name");
  const res = new Contract(
    contracts[contractName].address,
    contracts[contractName].abi,
    provider
  );
  return res;
};

export const resolveContractName = (name?: string): ContractName => {
  return _.includes(contractNames, name)
    ? (name as ContractName)
    : contractNames[0];
};
