import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { useWeb3Context } from "contexts/Web3ContextProvider";
import { useToggle } from "react-use";
import { formatEther } from "@ethersproject/units";
import { useCallback, useMemo, useState } from "react";
import { useNftContext } from "contexts/NftContextProvider";
import leftPad from "left-pad";
import EitImg from "assets/ic-eth.svg";

import _ from "lodash";
import DepositAsCollateralModal from "components/modal/DepositAsCollateralModal";
import UnwrapCollateralModal from "components/modal/UnwrapCollateralModal";
import useUserNftData from "hooks/useUserNftData";
import { EmptyAssetsComponent } from "components/EmptyAssetsComponent";
import { UserNftDataRow } from "interfaces/interfaces";
import { useOpenseaData } from "hooks/useOpenseaData";
import { formatId } from "utils/commons";

export const DasboardInventoryComponent: React.FC = () => {
  const { provider, contracts } = useWeb3Context();
  const bunkerState = useBunkerState();
  const [open, toggleOpen] = useToggle(false);
  const nftState = useNftContext();
  console.log("nftState:", nftState);
  console.log("bunkerState:", bunkerState);

  const [getOpenseaData, userNftsFromOpensea] = useOpenseaData();

  // console.log('--------> nfts in dashboard:', userNftsFromOpensea?.value?.data, userNftsFromOpensea?.value?.data?.length);
  
  const [modalIsOpen, toggleModal] = useToggle(false);
  const [selectedNft, setSelectedNft] = useState<UserNftDataRow>();

  interface DataRow {
    id: string;
    name: string;
    url: string;
    value: string;
    valueUsdc: string;
    deposited: boolean;
    approved?: boolean;
  }

  const [myassetsData, nftPrice, requestApproval, deposit, redeem] =
    useUserNftData();

  // console.log("--------> user nft data: ", myassetsData);

  if (!bunkerState.value) {
    return <div>Loading your data...</div>;
  }

  if (myassetsData?.length === 0 && userNftsFromOpensea?.value?.data?.length === 0) {
    return <EmptyAssetsComponent />;
  }

  const punkDepositUnwrapHandler = (id: string) => {
    let nftDataObj = myassetsData?.filter( nft => nft.id === id)
    console.log('SElected nft:', nftDataObj);
    setSelectedNft(nftDataObj?.[0])
    toggleModal();
  }

  return (
    <div className="table-responsive">
      <table className="table table-borderless">
        <thead>
          <tr>
            <th>Asset</th>
            <th>Oracle Price in Eth</th>
            <th style={{ textAlign: "center" }}>Price in $$</th>
            <th style={{ textAlign: "right" }}>Deposit as Collateral</th>
          </tr>
        </thead>
        <tbody>
          {_.map(
            myassetsData,
            ({ id, name, url, value, valueUsdc, deposited, approved }, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={url}
                        className="img-thumb"
                        alt={name + " image "}
                      />
                    </div>
                    <div className="table-headings">
                      <h4>{name}</h4>
                      <h5>ID# {formatId(id)}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <img src={EitImg} /> {value}{" "}
                </td>
                <td style={{ textAlign: "center" }}>{valueUsdc}</td>
                <td style={{ textAlign: "right" }}>
                  <button
                    className="btn-green dashboard-btn"
                    onClick={() => punkDepositUnwrapHandler(id)}
                  >
                    {
                      deposited ? "Unwrap" : "Deposit"
                    }
                  </button>
                </td>
              </tr>
            )
          )}
          {_.map(
            userNftsFromOpensea?.value?.data,
            ({ id, name, url, value, valueUsdc, deposited, approved }, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={url}
                        className="img-thumb"
                        alt={name + " image "}
                      />
                    </div>
                    <div className="table-headings">
                      <h4>{name}</h4>
                      <h5>ID# {formatId(id)}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <img src={EitImg} /> {value}{" "}
                </td>
                <td style={{ textAlign: "center" }}>{valueUsdc}</td>
                <td style={{ textAlign: "right" }}>
                  <button
                    className="btn-green dashboard-btn"
                    onClick={() => punkDepositUnwrapHandler(id)}
                  >
                    {
                      deposited ? "Unwrap" : "Deposit"
                    }
                  </button>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      
      { !!selectedNft && <UnwrapCollateralModal
        isOpen={modalIsOpen}
        handleClose={() => toggleModal(false)}
        nftData={{
          name: selectedNft.name,
          id: selectedNft.id,
          deposited: selectedNft.deposited,
          approved: selectedNft.approved,
        }}
      />}
    </div>
  );
};
