import { BigNumber } from "@ethersproject/bignumber";
import { Decimal } from "decimal.js";
import _ from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { useAsync, useMount, useUpdateEffect } from "react-use";
import { AsyncState } from "react-use/lib/useAsync";
import { useWeb3Context } from "./Web3ContextProvider";

// TODO: Instead of hardcoding ether/usdc, generalize across all markets.
// Can call await contracts.Comptroller.getAllMarkets(); to get all cTokens.
export const BunkerStateContext = React.createContext<
  AsyncState<{
    collateralFactor: BigNumber;
    cEtherBalance: BigNumber;
    cUsdcBalance: BigNumber;
    cNftBalance: BigNumber;
    cNftPrice: BigNumber;
    cNftValue: BigNumber;
    cEtherOwed: BigNumber;
    cUsdcOwed: BigNumber;
    liquidity: BigNumber;
    shortfall: BigNumber;
    cEtherTotalSupply: BigNumber;
    cEtherTotalBorrow: BigNumber;
    cEtherSupplyApy: string;
    cEtherBorrowApr: string;
    cEtherSupplyApyRaw: number;
    cEtherBorrowAprRaw: number;
    cUSDCTotalSupply: BigNumber;
    cUSDCTotalBorrow: BigNumber;
    cUSDCSupplyApy: string;
    cUSDCBorrowApr: string;
    cUSDCSupplyApyRaw: number;
    cUSDCBorrowAprRaw: number;
    cUSDCPrice: BigNumber;
    USDCallowance: BigNumber;
    USDCBalance: BigNumber;
    etherBalance: BigNumber;
    cNFTTotalSupply: BigNumber;
  } | null> & { refresh(): void }
>({ loading: true, refresh: _.noop });

// results of view functions that multiple components may need to referenc
// hoisted into a context / hook for convenience & fewer calls to the blockchain
export const BunkerStateContextProvider: React.FC = ({ children }) => {
  const { provider, contracts, userId, blockNumber } = useWeb3Context();
  const [refreshState, setRefreshState] = useState(0);
  const refresh = useCallback(() => setRefreshState((r) => r + 6), []);
  useUpdateEffect(() => {
    if (refreshState > 0) {
      setRefreshState((r) => r - 1);
    }
  }, [blockNumber]);
  useMount(() => {
    const itvl = setInterval(() => {
      setRefreshState((r) => r);
    }, 60000);
    return () => {
      clearInterval(itvl);
    };
  });

  const bunkerState = useAsync(async () => {
    if (!provider || !contracts) return null;
    const [
      [, collateralFactor],
      [, cEtherBalanceRaw, cEtherOwedBeforeInterest, cEtherMantissa],
      cEtherOwed,
      [, cUsdcBalanceRaw, cUsdcOwed, cUsdcMantissa],
      cNftBalance,
      cNftPrice,
      [, liquidity, shortfall],
      cEtherTotalCash,
      cEtherTotalBorrow,
      cEtherSupplyRatePerBlock,
      cEtherBorrowRatePerBlock,
      cUSDCTotalCash,
      cUSDCTotalBorrow,
      cUSDCSupplyRatePerBlock,
      cUSDCBorrowRatePerBlock,
      cUSDCPriceRaw,
      USDCallowance,
      USDCBalance,
      etherBalance,
      cNFTTotalSupply,
    ] = await Promise.all([
      contracts.Comptroller.markets(contracts.CPunk.address),
      contracts.CEther.getAccountSnapshot(userId),
      contracts.CEther.callStatic.borrowBalanceCurrent(userId, {
        // blockNumber: blockNumber,
      }) as Promise<BigNumber>,
      contracts.CUSDC.getAccountSnapshot(userId),
      contracts.CPunk.totalBalance(userId) as Promise<BigNumber>,
      contracts.NftPriceOracle.getUnderlyingPrice(
        contracts.CPunk.address
      ) as Promise<BigNumber>,
      contracts.Comptroller.getAccountLiquidity(userId),
      contracts.CEther.getCash() as Promise<BigNumber>,
      contracts.CEther.totalBorrows() as Promise<BigNumber>,
      contracts.CEther.supplyRatePerBlock() as Promise<BigNumber>,
      contracts.CEther.borrowRatePerBlock() as Promise<BigNumber>,
      contracts.CUSDC.getCash() as Promise<BigNumber>,
      contracts.CUSDC.totalBorrows() as Promise<BigNumber>,
      contracts.CUSDC.supplyRatePerBlock() as Promise<BigNumber>,
      contracts.CUSDC.borrowRatePerBlock() as Promise<BigNumber>,
      contracts.PriceOracle.getUnderlyingPrice(
        contracts.CUSDC.address
      ) as Promise<BigNumber>,
      contracts.USDC.allowance(
        userId,
        contracts.CUSDC.address
      ) as Promise<BigNumber>,
      contracts.USDC.balanceOf(userId) as Promise<BigNumber>,
      provider.getBalance(userId!) as Promise<BigNumber>,
      contracts.CPunk.totalSupply() as Promise<BigNumber>,
    ]);
    const cNftValue = cNftBalance.mul(cNftPrice);

    // console.log(
    //   "cEtherOwedBeforeInterest",
    //   cEtherOwedBeforeInterest,
    //   cEtherOwed
    // );
    // const cUSDCPrice = cUSDCPriceRaw;
    const cUSDCPrice = cUSDCPriceRaw.div(1e6).div(1e6);
    const getApy = (supplyRatePerBlock: BigNumber) => {
      const ethMantissa = 1e18;
      const blocksPerDay = 6570; // 13.15 seconds per block
      const daysPerYear = 365;

      const dec = new Decimal(supplyRatePerBlock.toString());
      return dec
        .div(ethMantissa)
        .mul(blocksPerDay)
        .add(1)
        .pow(daysPerYear)
        .sub(1)
        .mul(100)
        .toNumber();
    };

    console.log("mantissas", cEtherMantissa, cUsdcMantissa);
    const cEtherSupplyApyRaw = getApy(cEtherSupplyRatePerBlock);
    const cEtherSupplyApy = cEtherSupplyApyRaw.toFixed(2);
    const cEtherBorrowAprRaw = getApy(cEtherBorrowRatePerBlock);
    const cEtherBorrowApr = cEtherBorrowAprRaw.toFixed(2);
    const cUSDCSupplyApyRaw = getApy(cUSDCSupplyRatePerBlock);
    const cUSDCSupplyApy = cUSDCSupplyApyRaw.toFixed(2);
    const cUSDCBorrowAprRaw = getApy(cUSDCBorrowRatePerBlock);
    const cUSDCBorrowApr = cUSDCBorrowAprRaw.toFixed(2);

    const cEtherBalance = cEtherBalanceRaw
      .mul(cEtherMantissa)
      .div(1e9)
      .div(1e9);
    const cUsdcBalance = cUsdcBalanceRaw.mul(cUsdcMantissa).div(1e9).div(1e9);
    return ((window as any).bunkerState = {
      collateralFactor,
      cEtherBalance,
      cUsdcBalance,
      cNftBalance,
      cNftValue,
      cNftPrice,
      cEtherOwed,
      cUsdcOwed,
      liquidity,
      shortfall,
      cEtherTotalSupply: cEtherTotalCash.add(cEtherTotalBorrow),
      cEtherTotalBorrow,
      cEtherSupplyApy,
      cEtherSupplyApyRaw,
      cEtherBorrowApr,
      cEtherBorrowAprRaw,
      cUSDCTotalSupply: cUSDCTotalCash.add(cUSDCTotalBorrow),
      cUSDCTotalBorrow,
      cUSDCSupplyApy,
      cUSDCSupplyApyRaw,
      cUSDCBorrowApr,
      cUSDCBorrowAprRaw,
      cUSDCPrice,
      USDCallowance,
      USDCBalance,
      etherBalance,
      cNFTTotalSupply,
    });
  }, [provider, contracts, userId, refreshState]);

  return (
    <div>
      <BunkerStateContext.Provider value={{ ...bunkerState, refresh }}>
        {children}
      </BunkerStateContext.Provider>
    </div>
  );
};

export const useBunkerState = () => {
  return useContext(BunkerStateContext)!;
};
