import styled from "styled-components";

const RightContainer = styled.div`
  width: calc(100% - 270px);
  margin-left: 20px;
  @media (max-width: 980px) {
    width: 100%;
    margin: 40px 0 0 0;
  }
`;

export default RightContainer;
