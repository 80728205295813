import _ from "lodash";

import EmptyState from "./EmptyState";
import { TableWrapper } from "views/Dashboard/styledComponents";
import UnwrapCollateralModal from "components/modal/UnwrapCollateralModal";
import { useToggle } from "react-use";
import EitImg from "assets/ic-eth.svg";

export const TableView = ({ data, deposit, redeem, requestApproval }) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <TableWrapper>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th>Asset</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_.map(
                data,
                ({ id, name, url, value, valueUsdc, deposited, approved }) => (
                  <tr key={id}>
                    <td>
                      <img
                        src={url}
                        alt={`${name} logo`}
                        style={{ paddingBottom: 6 }}
                      />
                      <span>
                        {name}#{id}
                      </span>
                    </td>
                    <td>
                      <img src={EitImg} alt="ETH Symbol" /> <span>{value}</span>
                    </td>
                    <td>
                      {!deposited && !approved && (
                        <button
                          className="btn btn-unwrap btn-green"
                          onClick={async () => {
                            requestApproval(id);
                          }}
                        >
                          Deposit
                        </button>
                      )}
                      {!deposited && (
                        <button
                          className="btn btn-unwrap btn-green"
                          onClick={async () => {
                            deposit(id);
                          }}
                        >
                          Deposit
                        </button>
                      )}
                      {deposited && (
                        <>
                          <button
                            className="btn btn-unwrap btn-green"
                            onClick={async () => {
                              toggle();
                              // redeem(id); // THIS REDEEM MUST GO INSIDE THE MODAL
                            }}
                          >
                            Unwrap
                          </button>
                          <UnwrapCollateralModal
                            isOpen={isOpen}
                            handleClose={toggle}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </TableWrapper>
      {!data && <div>Loading...</div>}
      {data && data.length === 0 && <EmptyState />}
    </>
  );
};
