import { useState } from "react";
import { BorrowRepayModal } from "components/modal/BorrowRepayModal";

export default function BorrowTableRow({ asset, poolUtilization, apy, supplied, amountSupplied, withdrawLimit, modalData }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  
  return (
    <tr>
      <td>
        {asset}
      </td>
      <td>$ {Number(String(poolUtilization).replace('$', '')).toFixed(2)}</td>
      <td>{apy}</td>
      <td>{supplied}</td>
      <td>{amountSupplied}</td>
      <td>{withdrawLimit}</td>
      <td style={{ textAlign: "right" }}>
        <button className="btn-green" onClick={() => setModalIsOpen(true)}>
          Borrow/Repay
        </button>
        <BorrowRepayModal isOpen={modalIsOpen} handleClose={() => setModalIsOpen(false)} modalData={modalData} />
      </td>
    </tr>
  )
}
