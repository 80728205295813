import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import _ from "lodash";
import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useMount } from "react-use";
import Web3Modal from "web3modal";
import {
  chainIds,
  ContractName,
  contractNames,
  getContract,
} from "../contracts";

interface ContractEvent {
  name: string;
  obj: object;
  block: number;
}
export const Web3Context = React.createContext<
  | {
      connect(): Promise<void>;
      provider?: Web3Provider;
      userId?: string;
      contracts?: Record<ContractName, Contract>;
      events: ContractEvent[];
      blockNumber: number;
    }
  | undefined
>(undefined);

export const Web3ContextProvider: React.FC<{}> = ({ children }) => {
  const [provider, setProvider] = useState<Web3Provider>();
  const [userId, setUserId] = useState<string>();
  const connect = useCallback(async () => {
    const web3Modal = new Web3Modal({
      network: "1338", // doesn't really matter?
      cacheProvider: true,
      providerOptions: {},
    });
    const rawProvider = await web3Modal.connect();
    const load = async () => {
      const nProvider = new Web3Provider(rawProvider);

      await nProvider.ready;
      setProvider(nProvider);
    };
    // Subscribe to accounts change
    rawProvider.on("accountsChanged", load);
    // Subscribe to chainId change
    rawProvider.on("chainChanged", load);
    await load();
  }, []);

  useMount(() => {
    if (localStorage.autoConnect) {
      connect();
    }
  });
  useEffect(() => {
    if (!provider || !provider.network?.chainId) return;
    (async () => {
      if (!chainIds.includes(provider.network.chainId)) {
        throw new Error(
          `Network mismatch: provider has ${
            provider.network.chainId
          } but app requires ${chainIds.join(",")}`
        );
      }

      const accounts = await provider?.listAccounts();
      if (!accounts) setUserId(undefined);
      else setUserId(accounts[0]);
      // else setUserId('0xA1830E8d9F019FEB448478a171Bb37Cc6C4c0482'); //punkId=8740
      // else setUserId('0x8798c5add2c42ce8e773a3104d23ca36b10c8c15'); //punkId-6069
      localStorage.autoConnect = true;
      
      //For testing in local dev chain.
      console.log('=======> current chainId ', provider.network?.chainId);

      if(provider.network?.chainId === 1337){
      //all supported chainIds
      console.log('=======> All supported chainIds: ', chainIds);
      
        localStorage.demo = true;
      } else localStorage.demo = false;
      
    })();
  }, [provider]);
  (window as any).provider = provider;
  const contracts: Record<ContractName, Contract> | undefined = useMemo(() => {
    if (!provider) return undefined;
    try {
      return _.fromPairs(
        contractNames.map((contractName) => [
          contractName,
          getContract(contractName, provider),
        ])
      ) as Record<ContractName, Contract>;
    } catch (e) {
      return {} as any;
    }
  }, [provider]);

  const [events, setEvents] = useState<ContractEvent[]>([]);
  const [blockNumber, setBlockNumber] = useState<number>(-1);
  useEffect(() => {
    const logContractEvent = (event: any) => {
      const obj = _.fromPairs(
        _.toPairs(event.args).filter(([k]) => !Number.isFinite(Number(k)))
      );
      // console.log(event.event, obj, event.args, event);
      setEvents((prev) =>
        _.uniqBy(
          _.sortBy(
            [...prev, { name: event.event, obj, block: event.blockNumber }],
            "block"
          ),
          JSON.stringify
        )
      );
    };
    _.forEach(contracts, async (contract) => {
      // contract.on("*", logContractEvent);
    });
    // _.forEach(contracts, async (contract) => {
    //   const historicEvents = await contract.queryFilter(
    //     {},
    //     provider!.blockNumber - 30
    //   );
    //   // historicEvents.forEach(logContractEvent);
    // });
    function updateBlockNumber(blockNumber: number) {
      setBlockNumber(blockNumber);
      console.log('====> New block number:', blockNumber);
      
    }
    provider?.on("block", updateBlockNumber);
    return () => {
      provider?.off("block", updateBlockNumber);
      _.forEach(contracts, (contract) => {
        // contract.off("*", logContractEvent);
      });
    };
  }, [contracts, provider]);

  return (
    <div>
      <Web3Context.Provider
        value={{ connect, provider, userId, contracts, events, blockNumber }}
      >
        {children}
      </Web3Context.Provider>
    </div>
  );
};

export const useWeb3Context = () => {
  return useContext(Web3Context)!;
};

export const useConnectedWeb3Context = () => {
  const result = useContext(Web3Context)!;
  if (!result.provider)
    throw new Error("useConnectedWeb3Context called, but not connected");
  return {
    ...result,
    provider: result.provider!,
    userId: result.userId!,
    contracts: result.contracts!,
  };
};
