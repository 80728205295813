import styled from "styled-components";

const UniquePool = styled.section`
  background: #fff;
  padding: 100px 0;
  @media (max-width: 767px) {
    padding: 60px 0;
  }
  .left-section {
    width: 50%;
    @media (max-width: 980px) {
      width: 100%;
      margin-bottom: 60px;
    }
    h2 {
      width: 296px;
      max-width: 100%;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #18181b;
      margin: 0 0 32px 0px;
      word-break: break-word;
      @media (max-width: 980px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
    .unique-list {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #52525b;
        margin-bottom: 32px;
        padding-left: 42px;
        position: relative;
        @media (max-width: 767px) {
          line-height: 30px;
        }
        &::before {
          content: "";
          width: 30px;
          height: 4px;
          background: #01c5ba;
          transform: rotate(-45deg);
          position: absolute;
          left: 0;
          margin: 18px 0 0 0px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .right-section {
    width: 50%;
    @media (max-width: 980px) {
      width: 100%;
      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }
`;

export default UniquePool;
