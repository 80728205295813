import { BigNumber } from "ethers";

export const formatter = Intl.NumberFormat("en", { notation: "compact" });


export const formatAddress = (address: string | undefined): string => {
    if (address && address.length > 10) {
      return (
        address.substring(0, 6) + "..." + address.substring(address.length - 4)
      );
    }
    return address || "";
  };

  export const formatId = (id: string | undefined): string => {
    if (id && id.length > 10) {
      return (
        id.substring(0, 10) + "..." + id.substring(id.length - 10)
      );
    }
    return id || "";
  };
// const nftPoolItem : NftPoolItem = nftPoolItems[0];

export const min = (a: BigNumber, b: BigNumber): BigNumber => {
  if (a.lt(b)) return a;
  return b;
};
export const max = (a: BigNumber, b: BigNumber): BigNumber => {
  if (a.gt(b)) return a;
  return b;
};

export const weiToDollars = (wei: BigNumber, cUSDCPrice: BigNumber): number => {
  return wei.mul(100).div(cUSDCPrice).toNumber() / 100;
};

export const getFixedNumber = (value: number | string) => {
  if(!isNaN(Number(value))) {
    return Number(value) === 0? 0 : Number(value).toFixed(2);
  }
  else return 0;
}

export const getFixedNumber4 = (value: number | string) => {

  if (typeof value == 'string') {
    value = value.replace('ETH', '').replace('$', '');
  }
  if(!isNaN(Number(value))) {
    return Number(value) === 0? 0 : Number(value).toFixed(4);
  }
  else return 0;
}