import styled from "styled-components";

const SectionTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0;
  }

  .MuiIconButton-root {
    color: var(--button-view-inactive);
  }

  .MuiIconButton-root.active {
    color: var(--button-view-active);
  }
`;

export default SectionTitleWrapper;
