import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import HeroSection from "./components/HeroSection";
import Protocol from "./components/Protocol";
import LoanTerms from "./components/LoanTerms";
import HowItWorks from "./components/HowItWorks";
import UniquePool from "./components/UniquePool";
import Faq from "./components/Faq";

import Pool from "../../images/pool.png";
import Illustration from "../../images/Illustration.png";
import Dashboard from "../../images/dashboard.png";
import Illus from "../../images/Illus.png";
import ImgConnect from "../../images/Illustration.svg";
import ImgChoosenft from "../../images/choosenft.svg";
import ImgAccess from "../../images/access.svg";
import ImgChoosePool from "../../images/choose-pool.svg";
import ImgDeposit from "../../images/deposit.svg";
import ImgEarn from "../../images/earn.svg";
import Accordion from "../Accordion/Accordion";
import { Link } from 'react-router-dom';

const Content = () => {
  return (
    <>
      <HeroSection />

      {/* PROTOCOL SECTION OPEN */}
      <Protocol>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>An NFT-backed lending protocol</h1>
            </div>
            <div className="col-lg-12">
              <div className="protocol-flex">
                <div className="left-section">
                  <div>
                    <h2>Collection-specific lending pools</h2>
                    <p>
                      Each NFT collection has its own, separate lending market
                      on Bunker.
                    </p>
                  </div>
                </div>
                <div className="right-section">
                  <img src={Pool} alt="pool" />
                </div>
              </div>
            </div>
          </div>
        </div>
        </Protocol>
      {/* PROTOCOL SECTION CLOSE */}
      {/* LOAN TERMS SECTION OPEN */}
      <LoanTerms>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="loan-terms-flex">
                <div className="left-section">
                  <img src={Illustration} alt="illustration" />
                </div>
                <div className="right-section">
                  <h2>Flexible loan terms</h2>
                  <p>Borrow as much as you need and repay whenever you like.</p>
                  <h6>I WANT TO</h6>
                  <div className="btn-flex">
                    <Link to="/dashboard"><button className="primary-btn mr-4">Borrow</button></Link>
                    <Link to="/dashboard"><button className="secondary-btn">Lend</button></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoanTerms>
      {/* LOAN TERMS SECTION CLOSE */}
      {/* EARN SECTION OPEN */}
      <Protocol withPaddingBottom>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="protocol-flex">
                <div className="left-section">
                  <div>
                    <h2>Earn interest in real-time</h2>
                    <p>
                      As a lender, you earn interest every single block, and can
                      withdraw your capital at any time.{" "}
                    </p>
                  </div>
                </div>
                <div className="right-section">
                  <img src={Dashboard} alt="dashboard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Protocol>
      {/* EARN SECTION CLOSE */}
      {/* HOW IT WORKS SECTION OPEN */}
      <HowItWorks>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>How it works?</h1>

              <div className="tabs-wrap">
                <Tabs>
                  <TabList>
                    <Tab>Borrow</Tab>
                    <Tab>Lend</Tab>
                  </TabList>

                  <TabPanel>
                    <ul className="worklist">
                      <li>
                        <img src={ImgConnect} />
                        <h4>Connect Wallet</h4>
                      </li>
                      <li>
                        <img src={ImgChoosenft} />
                        <h4>Deposit NFT Collateral</h4>
                      </li>
                      <li>
                        <img src={ImgAccess} />
                        <h4>Instant Access to Funding</h4>
                      </li>
                    </ul>
                  </TabPanel>

                  <TabPanel>
                    <ul className="worklist">
                      <li>
                        <img src={ImgChoosePool} />
                        <h4>Choose a pool</h4>
                      </li>
                      <li>
                        <img src={ImgDeposit} />
                        <h4>Deposit</h4>
                      </li>
                      <li>
                        <img src={ImgEarn} />
                        <h4>Earn Interest</h4>
                      </li>
                    </ul>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </HowItWorks>
      {/* HOW IT WORKS SECTION CLOSE */}
      {/* UNIQUE POOL SECTION OPEN */}
      <UniquePool>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center">
                <div className="left-section">
                  <h2>The first </h2>
                  <ul className="unique-list">
                    <li>Isolated lending pools help reduce risk</li>
                    <li>No inefficient P2P order matching</li>
                    <li>Instant liquidity</li>
                    <li>Liquidations handled by liquidators, not lenders</li>
                  </ul>
                </div>
                <div className="right-section">
                  <img src={Illus} alt="illus" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UniquePool>
      {/* UNIQUE POOL SECTION CLOSE */}
      {/* FAQ SECTION OPEN */}
      <Faq>
        <h2>FAQS</h2>
        <div className="accordian-wrap">
          <Accordion />
        </div>
      </Faq>
      {/* FAQ SECTION CLOSE */}
    </>
  );
};

export default Content;
