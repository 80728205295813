import { BigNumber } from "ethers";
import leftPad from "left-pad";
import _ from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { useAsync, useToggle, useUpdateEffect } from "react-use";
import { AsyncState } from "react-use/lib/useAsync";
import { useWeb3Context } from "./Web3ContextProvider";

interface NFT {
  id: number;
  url: string;
  owner: string;
  name: string;
  forSale?: boolean;
}

export const NftContext = React.createContext<
  AsyncState<
    | {
        userId: string;
        lastCheckedBlock: number;
        deposited: NFT[];
        owned: NFT[];
      }
    | undefined
  > & {
    refresh(): void;
  }
>({ loading: true, refresh: _.noop });

export const NftContextProvider: React.FC = ({ children }) => {
  const { provider, contracts, userId, blockNumber } = useWeb3Context();
  const loadNFT = async (id: number): Promise<NFT> => {
    if (!contracts) throw new Error("Cannot load NFTs without contracts");

    const [owner, url, [forSale, , from, price, to]] = await Promise.all([
      contracts.CryptoPunksMarket.punkIndexToAddress(id),
      Promise.resolve(
        `https://unpkg.com/cryptopunk-icons@1.1.0/app/assets/punk${leftPad(
          id,
          3,
          "0"
        )}.png`
      ),
      contracts.CryptoPunksMarket.punksOfferedForSale(id) as Promise<
        [boolean, number, string, BigNumber, string]
      >,
    ]);

    // console.log(
    //   "NftContextProvider: punk details of Id:",
    //   userId,
    //   owner,
    //   url,
    //   from,
    //   price,
    //   to
    // );

    return {
      id,
      url,
      owner,
      name: "CryptoPunks",
      forSale:
        forSale &&
        from === userId &&
        price.toNumber() === 0 &&
        to === contracts.CPunk.address,
    };
  };

  const [refreshState, setRefreshState] = useState(0);
  const refresh = useCallback(() => setRefreshState((r) => r + 1), []);
  useUpdateEffect(() => {
    if (refreshState) {
      setRefreshState(0);
    }
  }, [blockNumber]);

  // const punksJsonReqquest = useAsync(async () => {
  //   const fakeUserId = "0xc352b534e8b987e036a93539fd6897f53488e56a";
  //   const punksJson = await fetch(
  //     // `https://api.wrappedpunks.com/api/punks?user=${fakeUserId}&type=punk&pageSize=100`
  //     `https://api.wrappedpunks.com/api/punks?user=${userId}&type=punk&pageSize=10000`
  //   ).then((r) => r.json());
  //   return punksJson;
  // }, [userId]);

  const punksJsonReqquest = useAsync(async () => {
    if (!userId) throw new Error("UserId not available");
    const options = {
      method: "GET",
      headers: { Accept: "application/json" }, //, 'X-API-KEY': '5bec8ae0372044cab1bef0d866c98618'}
    };

    const punksJson = await fetch(
      `https://api.opensea.io/api/v1/assets?owner=${userId}&collection_slug=cryptopunks&order_direction=desc&limit=100&include_orders=false`,
      options
    )
      .then((response) => response.json())
      .catch((err) => console.error(err));
    return punksJson;
  }, [userId]);

  const punksJson = punksJsonReqquest.value;
  console.log("NftContextProviders: punks json from opensea api", punksJsonReqquest.value);

  const NftState = useAsync(async () => {
    if (!provider || !contracts || !userId) return;

    // const ownedIds: number[] = _.sortBy([
    //   ...punksJson.data[0].assets.map((k: any) => k.id).slice(0, 20),
    // ]);

    const ownedIds: number[] = _.sortBy([
      ...punksJson.assets.map((k: any) => k.token_id).slice(0, 20),
    ]);

    console.log("NftContextProviders: ownerIds:", ownedIds);

    if (!ownedIds.length && localStorage.demo) {
      ownedIds.push(..._.range(6529, 6540));
    }

    const depositedIds = _.sortBy(
      (await contracts.CPunk.tokensByAccount(userId)).map((x: any) =>
        x.toNumber()
      )
    );

    console.log("NftContextProviders: tokens by account", depositedIds);
    const owned = (await Promise.map(ownedIds, loadNFT)).filter(
      (nft) =>
        nft.owner.toLowerCase() === userId.toLowerCase() ||
        localStorage.forceOwnPunk
    );
    const deposited = await Promise.map(depositedIds, loadNFT);

    console.log(
      "NftContextProviders: PUnk owned, deposited:",
      owned,
      deposited
    );

    return ((window as any).nftState = {
      userId,
      lastCheckedBlock: provider.blockNumber,
      deposited,
      owned,
    });
  }, [provider, contracts, userId, refreshState, punksJson]);
  return (
    <div>
      <NftContext.Provider value={{ ...NftState, refresh }}>
        {children}
      </NftContext.Provider>
    </div>
  );
};

export const useNftContext = () => {
  return useContext(NftContext)!;
};
