import { Link } from "react-router-dom";

export const Data = [
  {
    question: "How does bunker.finance work?",
    answer:
      "bunker.finance allows you to instantly lend and borrow without needing to wait to match borrowers and lenders. Our protocol currently allows you to borrow against CryptoPunks but more NFT collections will soon become available to use as collateral.",
  },
  {
    question: "How is bunker.finance different from other lending platforms?",
    answer:
      "We allow borrowers to take out loans instantly and enable lenders to immediately start earning interest. Borrowers no longer need to wait to be matched manually to a lender and vice versa.",
  },
  {
    question: "How do I start using Bunker?",
    answer: (
      <>
        Check out <Link to="/docs">our docs here</Link>
      </>
    ),
  },
  {
    question: "How do I join the team?",
    answer: (
      <>
        <Link to="/careers">Check the open positions</Link> or Email us at{" "}
        <a href="mailto:careers@bunker.finance?subject=I%20want%20to%20join%20the%20team">
          careers@bunker.finance
        </a>
      </>
    ),
  },
];
