import UnwrapCollateralModal from "components/modal/UnwrapCollateralModal";
import { useToggle } from "react-use";

interface Props {
  imgSrc: string;
  title: string;
  id: number | string;
  value: number | string | JSX.Element;
  deposited: boolean;
  approved: boolean | undefined;
}

export default function NftListItem({
  imgSrc,
  title,
  id,
  value,
  deposited,
  approved,
}: Props) {
  const [modalIsOpen, toggleModal] = useToggle(false);

  return (
    <li>
      <div className="imgbox">
        <img src={imgSrc} />
      </div>
      <div className="imgtxt">
        <h6>{title}</h6>
        <h1>#{id}</h1>
        <div className="hide-hover">
          <h5>Value</h5>
          {value}
        </div>
        <button className="btn btn-unwrap" onClick={toggleModal}>
          {deposited ? "Unwrap" : "Deposit"}
        </button>
        <UnwrapCollateralModal
          isOpen={modalIsOpen}
          handleClose={toggleModal}
          nftData={{
            name: title,
            id: id,
            deposited: deposited,
            approved: approved,
          }}
        />
      </div>
    </li>
  );
}
