import styled from "styled-components";

import NftPunkOne from "assets/punk-one.jpeg";

import { AppHeader } from "components/AppHeader/AppHeader";
import { useCallback, useEffect, useMemo, useState } from "react";

import { NftPoolItem, UserNftDataRow } from "interfaces/interfaces";
import useNftFetch from "hooks/useNftFetch";

import useBorrowerData from "hooks/useBorrowerData";
import { useBunkerState } from "contexts/BunkerStateContextProvider";
import _ from "lodash";
import { useNftContext } from "contexts/NftContextProvider";
import { formatEther } from "@ethersproject/units";
import leftPad from "left-pad";
import { useWeb3Context } from "contexts/Web3ContextProvider";

import { useOpenseaData } from "hooks/useOpenseaData";

import EitImg from "assets/ic-eth.svg";

import {
  DashboardContainer,
  DetailsList,
} from "components/DashboardStyledComponents";

import LoanWrapper from "components/LoanWrapper";
import Sidebar from "components/Sidebar";
import { DashboardNavMobile } from "components/DashboardNav";
import RightContainer from "components/RightContainer";

import { Assets } from "./Assets";
import { getFixedNumber4 } from "utils/commons";
import { useParams } from "react-router-dom";

export const InventoryDetails = () => {
  const { selectedPool } = useParams();
  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const bunkerState = useBunkerState();
  const nftState = useNftContext();
  console.log("nftState:", nftState);
  console.log("bunkerState:", bunkerState);
  // const [nftPrice, data] : ((string | number)[] | null[] | DataRow[] | null)[]  = useUserNftData();
  // console.log('User nft Values are :', nftPrice, data, typeof data);
  const data: UserNftDataRow[] | null = useMemo(() => {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    if (!bunkerState.value || !nftState.value) return null;
    console.log(nftState);
    const value =
      formatEther(bunkerState.value.cNftPrice).substring(0, 6) + " ETH";
    const valueUsdc =
      "$" +
      bunkerState.value.cNftPrice
        .mul(100)
        .div(bunkerState.value.cUSDCPrice)
        .toNumber() /
        100;
    const deposited: UserNftDataRow[] = nftState.value.deposited.map((nft) => ({
      ...nft,
      id: leftPad(nft.id, 3, "0"),
      value,
      valueUsdc,
      deposited: true,
    }));
    const owned: UserNftDataRow[] = nftState.value.owned.map((nft) => ({
      ...nft,
      id: leftPad(nft.id, 3, "0"),
      value,
      valueUsdc,
      deposited: false,
      approved: nft.forSale, // TODO
    }));
    return [...deposited, ...owned];
  }, [bunkerState.value, nftState]);

  const [
    borrowBalanceDollars,
    borrowBalanceEth,
    collateralBalanceDollars,
    collateralBalanceEth,
    borrowLimitDollars,
    borrowLimitEth,
    liquidationThresholdDollars,
    liquidationThresholdEth,
    apr,
    borrowBalance,
    borrowLimit,
    cNftValue,
  ] = useBorrowerData();

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(1),
    supplyBalance: Number(1),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };
  const { provider, contracts } = useWeb3Context();
  const requestApproval = useCallback(
    async (id: string) => {
      if (!contracts || !provider) return;
      const signer = provider.getSigner();
      await contracts.CryptoPunksMarket.connect(
        signer
      ).offerPunkForSaleToAddress(id, 0, contracts.CPunk.address);
      nftState.refresh();
    },
    [contracts, nftState, provider]
  );

  const deposit = useCallback(
    async (id: string) => {
      if (!contracts || !provider) return;
      const signer = provider.getSigner();
      await contracts.CPunk.connect(signer).mint([id], [1]);
      nftState.refresh();
      bunkerState.refresh();
    },
    [contracts, nftState, provider]
  );

  const redeem = useCallback(
    async (id: string) => {
      if (!contracts || !provider) return;
      const signer = provider.getSigner();
      await contracts.CPunk.connect(signer).redeem([id], [1]);
      nftState.refresh();
      bunkerState.refresh();
    },
    [contracts, nftState, provider]
  );

  const [getOpenseaData] = useOpenseaData();

  if (!data) {
    return <div>Loading your data...</div>;
  }

  return (
    <>
      <AppHeader activeTab={"inventory"} />

      <DashboardNavMobile />

      <DashboardContainer>
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex flex-wrap">
              <Sidebar>
                <div className="nft-details">
                  <div className="imgbox">
                    <img src={cryptoPunkItem.nftImage} />
                  </div>
                  <div className="imgtxt">
                    <h2>{cryptoPunkItem.nftName}</h2>
                    <div className="flex-center-inner">
                      <h6>Total Value Locked</h6>
                      <h4>
                        {/* <img src={EitImg} />  */}
                        {getTVL()}
                      </h4>
                    </div>
                  </div>
                </div>
              </Sidebar>

              <RightContainer>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <DetailsListLg>
                        <li>
                          <h6>Your Collection Value</h6>
                          <h1>
                            <img src={EitImg} /> {getFixedNumber4(cNftValue)}
                          </h1>
                        </li>
                        <li>
                          <h6>Collateral Value</h6>
                          <h1>
                            <img src={EitImg} />{" "}
                            {getFixedNumber4(borrowLimitEth)}
                          </h1>
                        </li>
                      </DetailsListLg>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <LoanWrapper className=" mb-5 pb-0 pl-0 pr-0">
                        <ul className="loan-listing">
                          <li>
                            <h4>Items</h4>
                            <h1>
                              {getOpenseaData.loading ? (
                                <span>Loading...</span>
                              ) : (
                                <span>{getOpenseaData.value.stats.count}</span>
                              )}
                            </h1>
                          </li>
                          <li>
                            <h4>Owners</h4>
                            <h1>
                              {getOpenseaData.loading ? (
                                <span>Loading...</span>
                              ) : (
                                <span>
                                  {getOpenseaData?.value?.stats?.num_owners}
                                </span>
                              )}
                            </h1>
                          </li>
                          <li>
                            <h4>Floor Price</h4>
                            <h1>
                              <img src={EitImg} />
                              {getOpenseaData.loading ? (
                                <span>Loading...</span>
                              ) : (
                                <span>
                                  {getOpenseaData?.value?.stats?.floor_price
                                    ? getOpenseaData?.value?.stats?.floor_price
                                    : "??"}
                                </span>
                              )}
                            </h1>
                          </li>
                          <li>
                            <h4>Volume traded</h4>
                            <h1>
                              <img src={EitImg} />
                              {getOpenseaData.loading ? (
                                <span>Loading...</span>
                              ) : (
                                <span>
                                  {(
                                    Number(
                                      getOpenseaData.value.stats.total_volume
                                    ) / 1000
                                  ).toFixed(0)}{" "}
                                  K
                                </span>
                              )}
                            </h1>
                          </li>
                        </ul>
                        <OpenSeaDataContainer>
                          data by Opensea
                        </OpenSeaDataContainer>
                      </LoanWrapper>
                    </div>
                  </div>

                  <Assets
                    data={data}
                    deposit={deposit}
                    redeem={redeem}
                    requestApproval={requestApproval}
                  />
                </div>
              </RightContainer>
            </div>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};

const OpenSeaDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 32px;
  width: 100%;
  height: 32px;
  background: var(--color-table);
  // font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-tertiary-text);
`;

const DetailsListLg = styled(DetailsList)`
  li {
    width: 49% !important;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
`;
