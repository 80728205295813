import styled from "styled-components";

export const ColoredHeading = styled.h4`
  // font-family: 'DM Sans';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--color-purple);
  margin: 0;
  padding: 0;
`;

export const TableWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 70px;
  color: var(--color-text) !important;
  .table-responsive {
    padding: 12px 6px 0 6px;
    border: var(--border-width) solid var(--color-border);
    background: var(--color-background);
  }
  table {
    color: inherit !important;
    background: var(--color-background);
    width: 100%;
    th {
      white-space: nowrap;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
    }
    tbody {
      tr {
        &:hover {
          background: var(--color-hightlight-cell);
        }
        td {
          vertical-align: middle;
        }
      }
      .heading-sm {
        margin: 0 0 0 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-text);
      }
    }
    .dashboard-btn {
      width: 150px;
    }

    .btn-green {
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      color: var(--color-primary);
      border: 2px solid var(--color-primary);
      background-color: var(--color-background);
      padding: 5px 10px;
      white-space: nowrap;
    }
    .img-thumb {
      width: 60px;
      height: 52px;
      object-fit: cover;
      border-radius: 6px;
      margin-right: 10px;
    }
    .table-headings {
      > h4 {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text);
        white-space: nowrap;
      }
      > h5 {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-id);
      }
    }
  }
`;

export const TabList = styled.ul`
  list-style: none;
  margin: 20px 0 34px 0;
  padding: 0;
  display: flex;
  align-items: center;
  li {
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: var(--color-secondary-text);
      padding: 0 50px;
      text-decoration: none;
    }
    & .activetab {
      color: var(--color-text);
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--color-primary);
        position: absolute;
        bottom: 0;
        top: 40px;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;
