import React from "react";

import { TableWrapper } from "views/Dashboard/styledComponents";
import { GreenLabel } from "components/AppHeader/components";

import EitImg from "../../assets/ic-eth.svg";

export const TableView = ({
  poolSelectHandler,
  nftPoolItems,
  cryptoPunkItem,
}) => {
  return (
    <TableWrapper>
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Pool</th>
              <th>Borrow Balance</th>
              <th>Supply Balance</th>
              <th>Value Locked</th>
              <th>NFTs Deposited</th>
              <th>Supply</th>
              <th>Borrow</th>
            </tr>
          </thead>
          <tbody>
            <TableRow
              item={cryptoPunkItem}
              onClick={() => poolSelectHandler("punk")}
            />

            {nftPoolItems.map((nftPoolItem, key) => (
              <TableRow key={key} item={nftPoolItem} />
            ))}
          </tbody>
        </table>
      </div>
    </TableWrapper>
  );
};

const TableRow = ({ item, style, ...props }) => {
  
  const extraStyle = "onClick" in props ? {cursor: "pointer"} : { pointerEvents: "none"}
  
  return (
  <tr {...props} style={{...style, ...extraStyle}}>
    <td style={{ display: "flex", alignItems: "center" }}>
      <img
        src={item.nftImage}
        alt={item.nftName}
        style={{
          width: 50,
          height: 50,
          objectFit: "cover",
          marginRight: 10,
          display: "inline-block",
        }}
      />
      <h4 style={{ margin: 0 }}>{item.nftName}</h4>
    </td>
    {item.isActive ? (
      <>
        <td>
          <img src={EitImg} /> {item.borrowBalance.toFixed(4)}
        </td>
        <td>
          <img src={EitImg} /> {item.supplyBalance}
        </td>
        <td> {item.valueLocked} ETH</td>
        <td>{item.nftDeposited}</td>
        <td>{item.supply} ETH</td>
        <td>{item.borrow} ETH</td>
      </>
    ) : (
      <>
        <td>
          <GreenLabel>Coming Soon...</GreenLabel>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    )}
  </tr>
);}
