import React from "react";
import { useBunkerState } from "contexts/BunkerStateContextProvider";
import { useWeb3Context } from "contexts/Web3ContextProvider";
import { useToggle } from "react-use";
import { useState } from "react";
import { BigNumber } from "@ethersproject/bignumber";

import { nftPoolItems } from "config/consts";
import NftPunkOne from "assets/punk-one.jpeg";
import { useNavigate } from "react-router-dom";
import { FaList } from "react-icons/fa";
import { RiLayoutGridFill } from "react-icons/ri";

import useNftFetch from "hooks/useNftFetch";
import useBorrowerData from "hooks/useBorrowerData";
import useLenderData from "hooks/useLenderData";
import { NftPoolItem } from "interfaces/interfaces";

import SectionTitleWrapper from "components/SectionTitleWrapper";
import { GridView } from "./GridView";
import { TableView } from "./TableView";
import { IconButton } from "@mui/material";

type Props = {};

export const DashboardPoolComponent = (props: Props) => {
  const bunkerState = useBunkerState();

  // console.log("bunkerState:", bunkerState);

  const [
    getTotalNFTs,
    getTotalSupply,
    getTotalBorrow,
    getTVL,
    getNftPriceInUSDC,
    getNftPriceInEth,
  ] = useNftFetch();

  const [
    borrowBalanceDollars,
    borrowBalanceEth,
    collateralBalanceDollars,
    collateralBalanceEth,
    borrowLimitDollars,
    borrowLimitEth,
    liquidationThresholdDollars,
    liquidationThresholdEth,
    apr,
    borrowBalance,
    borrowLimit,
    cNftValue,
  ] = useBorrowerData();

  const [supplyBalanceEth, supplyBalanceDollars, supplyAPY, dailyInterest] =
    useLenderData();

  // console.log(
  //   "***--------->>>> ",
  //   supplyBalanceEth,
  //   "----",
  //   supplyBalanceDollars,
  //   supplyAPY,
  //   dailyInterest
  // );

  const cryptoPunkItem: NftPoolItem = {
    nftImage: NftPunkOne,
    nftName: "Crypto Punk",
    borrowBalance: Number(borrowBalanceEth),
    supplyBalance: Number(
      Number(supplyBalanceEth.replace(" ETH", "").trim()).toFixed(4)
    ),
    valueLocked: getTVL(),
    nftDeposited: getTotalNFTs(),
    supply: getTotalSupply(),
    borrow: getTotalBorrow(),
    isActive: true,
  };

  const navigate = useNavigate();

  const poolSelectHandler = (poolName: string) => {
    console.log("pool selected..");
    navigate(`/${poolName}/borrow`);
  };

  const [view, setView] = useState<"table" | "grid">("grid");

  const Component = view === "grid" ? GridView : TableView;

  const isTableActive = view === "table";

  return (
    <>
      <SectionTitleWrapper>
        <h4>Pools</h4>
        <div>
          <IconButton
            className={isTableActive ? "active" : ""}
            onClick={() => setView("table")}
          >
            <FaList />
          </IconButton>
          <IconButton
            className={isTableActive ? "" : "active"}
            onClick={() => setView("grid")}
          >
            <RiLayoutGridFill />
          </IconButton>
        </div>
      </SectionTitleWrapper>
      <Component
        poolSelectHandler={poolSelectHandler}
        nftPoolItems={nftPoolItems}
        cryptoPunkItem={cryptoPunkItem}
      />
    </>
  );
};
