import styled from "styled-components";

import bg from "views/LandingPage/images/how-bg.jpg"

const HowItWorks = styled.section`
  background: #07022b url(${bg}) no-repeat;
  background-size: cover;
  padding: 120px 0 150px 0;
  @media (max-width: 980px) {
    padding: 80px 0 80px 0;
  }
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    margin: 0 0 70px 0;
    @media (max-width: 980px) {
      font-size: 30px;
      line-height: 45px;
      margin: 0 0 70px 0;
    }
  }
  .tabs-wrap {
    .react-tabs__tab-list {
      border: 0;
      border-bottom: 2px solid transparent;
      margin-bottom: 24px;
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      @media (max-width: 767px) {
        margin: 0 -15px;
        max-width: calc(100% + 30px);
      }
      .react-tabs__tab {
        width: 50%;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #fff;
        padding-bottom: 10px;
        &::after {
          content: "";
          display: none;
        }
        @media (max-width: 980px) {
          font-size: 20px;
        }
      }
    }
    .react-tabs__tab--selected {
      border: 0;
      border-bottom: 2px solid #01c5ba;
      color: #01c5ba !important;
      background: none;
    }
  }
  .worklist {
    display: flex;
    flex-wrap: wrap;
    margin: 140px auto 0;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    li {
      width: 30%;
      text-align: center;
      @media (max-width: 980px) {
        width: 100%;
        margin: 0 0 120px 0;
      }
      &:last-child {
        @media (max-width: 980px) {
          margin: 0;
        }
      }
      img {
        height: 180px;
        @media (max-width: 767px) {
          height: 120px;
        }
      }
      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #ffffff;
        margin: 80px 0 0 0;
        @media (max-width: 980px) {
          font-size: 20px;
          line-height: 150%;
          margin: 60px 0 0 0;
        }
      }
    }
  }
`;

export default HowItWorks
