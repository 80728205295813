import { FaChevronRight } from "react-icons/fa";
import { FiBookOpen, FiHome } from "react-icons/fi";
import { Link, Outlet, useParams } from "react-router-dom";
import styled from "styled-components";

import Container from "./components/Container";
import SelectRoute from "./components/SelectRoute";
import { useCopy } from "./index";

function Section() {
  const { rawData: data, findNext, baseRoute } = useCopy();

  const { topicSlug: activeTopicSlug, sectionSlug: activeSectionSlug } =
    useParams();

  const nextArticle = findNext(activeSectionSlug, activeTopicSlug);

  return (
    <Container>
      <Header>
        <Link to={`/${baseRoute}`}>
          <HeaderItem>
            <FiHome />
            Home
          </HeaderItem>
        </Link>
        <HeaderItem className="active">
          <FiBookOpen /> Guide
        </HeaderItem>
      </Header>
      <ContentLayout>
        <Sidebar>
          <nav>
            <ul>
              {data.map((section) => (
                <li key={section?.slug}>
                  <h3>{section?.title}</h3>
                  <ul>
                    {section?.topics?.map((topic) => (
                      <li
                        key={topic.slug}
                        className={
                          activeSectionSlug === section?.slug &&
                          activeTopicSlug === topic.slug
                            ? "active"
                            : ""
                        }
                      >
                        <Link to={`/${baseRoute}/${section?.slug}/${topic.slug}`}>
                          {topic?.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </nav>

          <SelectRoute />
        </Sidebar>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <ArticleContainer>
            <Outlet />
          </ArticleContainer>
          {nextArticle && (
            <NextLink to={`/${baseRoute}/${nextArticle.path}`}>
              {nextArticle.title} <RightArrow />
            </NextLink>
          )}
        </div>
      </ContentLayout>
    </Container>
  );
}

const Header = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
  margin-bottom: 20px;

  a {
    color: #3f3f46;
  }

  .active {
    color: rgba(1, 197, 186, 1);
  }
`;

const HeaderItem = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.25fr;
  grid-template-rows: 1fr;
  gap: 40px;
  border-top: 1px solid #e5e7eb;
  color: rgba(24, 24, 27, 1);

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
`;

const Sidebar = styled.aside`
  color: rgba(24, 24, 27, 1);
  padding-right: 10px;
  border-right: 1px solid #e5e7eb;

  h3 {
    font-family: "Epilogue";
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: #18181b;
    margin: 1rem 0 0.5rem 0;
  }

  a {
    font-family: "Epilogue";
    font-size: ${14 / 16}rem;
    line-height: 150%;
    color: inherit;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    color: #18181b;
    padding: 6px 0;
  }

  li.active {
    color: rgba(1, 197, 186, 1);
    background-color: rgba(1, 197, 186, 0.2);
    font-weight: 600;
    margin-left: -10px;

    padding-left: 10px;
  }

  .MuiFormControl-root {
    display: none;
  }

  @media (max-width: 767px) {
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
    padding: 20px 0px;
    display: flex;
    justify-content: center;

    nav {
      display: none;
    }

    .MuiFormControl-root {
      display: revert;

      fieldset > legend {
        display: none;
      }
    }
  }
`;

const ArticleContainer = styled.article`
  padding: 20px 0;
  border-bottom: 1px solid #e5e7eb;
`;

const NextLink = styled(Link)`
  color: #18181b;
  font-family: "Encode Sans Semi Expanded";
  font-weight: 400;
  font-size: 14px;
  align-self: flex-end;
  margin-top: 20px;

  &:hover {
    color: #18181b;
  }
`;

const RightArrow = styled(FaChevronRight)`
  margin-left: 16px;
`;

export default Section;
