import { StyledAppBar, NavLink, Layout } from "views/Docs";
import Logo from "assets/brand-black.svg";
import { useForceLight } from "utils/useDarkMode";

import Container from "views/Docs/components/Container";
import Button from "views/Docs/components/Button";
import { Title, Description } from "views/Docs/LandingContent";
import { ExternalLink } from "components/Socials";
import { FaDiscord, FaEnvelope } from "react-icons/fa";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Support() {
  useForceLight();
  return (
    <Layout>
      <StyledAppBar color="transparent" elevation={0} position="static">
        <NavLink to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
      </StyledAppBar>

      <CustomContainer>
        <Title>Support</Title>
        <Description style={{ width: "revert" }}>
          If you have any questions you can reach out to our support team or
          visit our <Link to="/faq">FAQs Page</Link>
        </Description>

        <CustomGrid container justifyContent="center" gap={20}>
          <ExternalLink href="mailto:hello@bunker.finance">
            <Button variant="outlined" startIcon={<FaEnvelope />}>
              Email
            </Button>
          </ExternalLink>

          <ExternalLink href="https://discord.com/invite/hv2dZFD563">
            <Button variant="outlined" startIcon={<FaDiscord />}>
              Discord
            </Button>
          </ExternalLink>
        </CustomGrid>
      </CustomContainer>
    </Layout>
  );
}

const CustomContainer = styled(Container)`
  width: 100%;
  text-align: center;
  max-width: revert;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  a {
    color: var(--color-text);
  }
`;

const CustomGrid = styled(Grid)`
  gap: 30px;
  margin-top: 40px;
`;
