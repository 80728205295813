import React from "react";
import styled from "styled-components";

export const EmptyAssetsComponent = () => {
  return (
    <Wrapper>
      You don't own any CryptoPunks
      <img
        alt="punk nft"
        title="Poor Punk"
        src="https://unpkg.com/cryptopunk-icons@1.1.0/app/assets/punk3100.png"
      />
      <a
        href="https://opensea.io/collection/cryptopunks"
        target="_blank"
        rel="noreferrer"
      >
        <Button>Buy on opensea</Button>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: var(--color-text);
`;

const Button = styled.button`
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  background-color: var(--color-background);
  padding: 5px 10px;
  white-space: nowrap;
`;
