import { formatEther } from '@ethersproject/units';
import { useBunkerState } from 'contexts/BunkerStateContextProvider';
import React, { useMemo } from 'react'

const useLenderData = () => {

    const bunkerState = useBunkerState();

  const [supplyBalanceEth, supplyBalanceDollars, supplyAPY, dailyInterest]: [
    string,
    string,
    string,
    string,
  ] = useMemo(() => {
    if (!bunkerState.value) return ["...", "...", "...", "..."];
    const {
      cEtherBalance,
      cUsdcBalance,
      cUSDCPrice,
      cEtherSupplyApyRaw,
      cUSDCSupplyApyRaw,
    } = bunkerState.value;
    const etherBalanceDollars =
      cEtherBalance.mul(100).div(cUSDCPrice).toNumber() / 100;
    const usdcBalanceDollars = cUsdcBalance.toNumber() / 1e6;
    const supplyBalanceDollars = `$${(
      etherBalanceDollars + usdcBalanceDollars
    ).toFixed(2)}`;
    console.log(cUSDCPrice.div(1e6).toNumber());
    const supplyBalanceEthUnits = cUSDCPrice
        .mul(Math.round(100 * (etherBalanceDollars + usdcBalanceDollars)))
        .div(100);
    
    const supplyBalanceEth = formatEther(supplyBalanceEthUnits) + " ETH";

    const apyValue =
      etherBalanceDollars + usdcBalanceDollars > 0
        ? (cEtherSupplyApyRaw * etherBalanceDollars +
            cUSDCSupplyApyRaw * usdcBalanceDollars) /
          (etherBalanceDollars + usdcBalanceDollars)
        : Math.max(cEtherSupplyApyRaw, cUSDCSupplyApyRaw);
      
    const apy = apyValue.toFixed(2) + "%";

    const dailyInterest = Number(formatEther(supplyBalanceEthUnits
                          .mul(Math.round(apyValue))
                          .div(100)
                          .div(365))).toFixed(4);

    return [supplyBalanceEth, supplyBalanceDollars, apy, dailyInterest.toString()];
  }, [bunkerState.value]);

  return  [supplyBalanceEth, supplyBalanceDollars, supplyAPY, dailyInterest];
}

export default useLenderData