import "bluebird-global";
import { Routes, Route, Navigate } from "react-router-dom";
import { Web3ContextProvider } from "contexts/Web3ContextProvider";
import { BunkerStateContextProvider } from "contexts/BunkerStateContextProvider";
import { ThemeProvider } from "styled-components";
import { createTheme } from "@mui/material";
import { LandingPage } from "views/LandingPage/landingPage";
import { Dashboard } from "views/Dashboard/dashboard";
import { Lend } from "views/Dashboard/lend";
import { Borrow } from "views/Dashboard/borrow/index";
import { Inventory } from "views/Inventory/inventory";
import { InventoryDetails } from "views/Inventory/inventory-details/index";

import Docs from "views/Docs";
import { copy as docsCopy } from "views/Docs/data";
import DocsLandingContent from "views/Docs/LandingContent";
import Section from "views/Docs/Section";
import Topic from "views/Docs/Topic";
import { NftContextProvider } from "contexts/NftContextProvider";
import TermsOfService from "views/Docs/Terms";
import PrivacyPolicy from "views/Docs/PrivacyPolicy";
import Support from "views/Support";
import FAQs from "views/Docs/Faq";

import { copy as careersCopy } from "views/Docs/Careers/data";

const theme = createTheme({
  palette: {},
});
function App() {
  return (
    <Web3ContextProvider>
      <ThemeProvider theme={theme}>
        <BunkerStateContextProvider>
          <NftContextProvider>
            <Routes>
              {/* <Route path="/sandbox/:contract_name" element={<SandboxView />} />
            <Route path="/sandbox" element={<SandboxView />} />
            <Route path="/profile/:userId" element={<SandboxView />} />
            <Route path="/profile" element={<SandboxView />} /> */}
              {/* <Route path="/pools" element={<PoolsView />} />
            <Route path="/pool/:poolId" element={<PoolView />} />
            <Route path="/pools/:poolId" element={<UpdatedPoolView />} />*/}
              <Route path="/" element={<LandingPage />} />
              <Route path="/docs" element={<Docs copy={docsCopy} />}>
                <Route path=":sectionSlug" element={<Section />}>
                  <Route path=":topicSlug" element={<Topic />} />
                </Route>

                <Route path="" element={<DocsLandingContent />} />
              </Route>

              <Route path="/careers" element={<Docs copy={careersCopy} />}>
                <Route path=":sectionSlug" element={<Section />}>
                  <Route path=":topicSlug" element={<Topic />} />
                </Route>

                <Route path="" element={<DocsLandingContent />} />
              </Route>

              {/* <Route path="/dashboard" element={<Dashboard />} />  */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/:selectedPool/lend" element={<Lend />} />
              <Route path="/:selectedPool/borrow" element={<Borrow />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route
                path="/:selectedPool/inventory-details"
                element={<InventoryDetails />}
              />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/support" element={<Support />} />
              <Route path="/faq" element={<FAQs />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </NftContextProvider>
        </BunkerStateContextProvider>
      </ThemeProvider>
    </Web3ContextProvider>
  );
}

export default App;
