import styled from "styled-components";

import HeroBg from "views/LandingPage/images/hero-bg.svg";

export const HeroSection = styled.section`
  background: url(${HeroBg}) repeat-y;
  background-position-x: right;
  background-size: 70%;
  width: 100%;
  height: 80vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 980px) {
    height: auto;
    background-size: 100%;
  }
  @media (max-width: 767px) {
    background-size: 240%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const HeroLeftSide = styled.div`
    width: 65%;
    margin-left: -100px;
    @media (max-width: 980px) {
      width: 50%;
      margin-left: -100px;
      margin-left: 0;
    }
    @media (max-width: 767px) {
      background-size: 240%;
      width: 100%;
    }
    img {
      width: 100%;
    }
    .more {
      position: absolute;
      left: 100px;
      bottom: 0;
      width: 32px !important;
      @media (max-width: 1450px) {
        left: 50px;
      }
      @media (max-width: 1380px) {
        left: 30px;
      }
      @media (max-width: 1300px) {
        display: none;
      }
    }
`;

export const HeroRightSide = styled.div`
    width: 40%;
    @media (max-width: 980px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding: 120px 20px 20px 20px;
      margin-bottom: 50px;
    }
    h1 {
      font-weight: 700;
      font-size: 64px;
      line-height: 72px;
      color: #ffffff;
      span {
        color: #ae39ff;
      }
      @media (max-width: 767px) {
        width: 100%;
        font-size: 40px;
        line-height: 48px;
      }
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin: 0 0 40px 0;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
`;

export const HeroButton = styled.button`
  background: #01c5ba;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  border: 0;
  min-width: 190px;
  min-height: 64px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
`;
