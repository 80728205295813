import styled from "styled-components";

export default function Banner() {
  return (
    <Container>
      <a
        href="https://code4rena.com/contests/2022-05-bunkerfinance-contest"
        target="_blank"
        rel="noreferrer"
      >
        Audit contest with C4 in progress 🚀🚀🚀
      </a>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: var(--color-ghost-white);
  width: 100%;
  line-height: 1.5;
  height: 25px;
  font-size: 0.8rem;
  font-weight: 600;

  a {
    color: inherit;
  }
`;
